import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button, FormControl, InputLabel, Select, MenuItem,
  Typography, Box, Chip, Divider, FormGroup, FormControlLabel,
  Checkbox
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import config from '../config';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.shape.borderRadius * 2
  }
}));

const StyledSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2)
}));

const safeJsonParse = (value, fallback) => {
    if (!value) return fallback;
    
    // If value is already an array, return it
    if (Array.isArray(value)) return value;
    
    // If value is a string that looks like an array, parse it
    if (typeof value === 'string' && value.startsWith('[') && value.endsWith(']')) {
        try {
            return JSON.parse(value);
        } catch (error) {
            console.error('JSON parsing error:', error);
            return fallback;
        }
    }
    
    // If value is a single string value, wrap it in an array
    if (typeof value === 'string') {
        return [value];
    }
    
    return fallback;
};

const DashboardSettings = ({ open, onClose, userId, onSettingsChange }) => {
  const { user: currentUser } = useAuth();
  const [settings, setSettings] = useState({
    showTasks: true,
    showVacations: currentUser?.isAdmin ? true : false,
    showInventory: true,
    taskTypes: [],
    departments: []
  });

  const [availableTaskTypes] = useState([
    { value: 'maintenance', label: 'Vedligeholdelse' },
    { value: 'cleaning', label: 'Rengøring' }
  ]);
  const [availableDepartments, setAvailableDepartments] = useState([]);

  useEffect(() => {
    fetchDepartments();
    fetchUserSettings();
  }, [userId]);

  const fetchDepartments = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/departments.php`, { withCredentials: true });
      if (response.data?.success) {
        setAvailableDepartments(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching departments:', error);
    }
  };

  const fetchUserSettings = async () => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/dashboard-settings.php?userId=${userId}`,
        { withCredentials: true }
      );
      console.log('Fetched settings:', response.data);
      
      if (response.data?.success && response.data.data) {
        const data = response.data.data;
        setSettings({
          showTasks: Boolean(data.showTasks ?? true),
          showVacations: Boolean(data.showVacations ?? true),
          showInventory: Boolean(data.showInventory ?? true),
          taskTypes: Array.isArray(data.taskTypes) ? data.taskTypes : safeJsonParse(data.taskTypes, []),
          departments: Array.isArray(data.departments) ? data.departments : safeJsonParse(data.departments, [])
        });
      }
    } catch (error) {
      console.error('Error fetching user settings:', error);
      // Set default values if fetching fails
      setSettings({
        showTasks: true,
        showVacations: true,
        showInventory: true,
        taskTypes: ['maintenance', 'cleaning'],
        departments: []
      });
    }
  };

  const handleSave = async () => {
    try {
      console.log('Saving settings:', settings);
      // Convert arrays to JSON strings before sending
      const settingsToSave = {
        userId: userId,  // Add userId explicitly
        showTasks: Number(settings.showTasks),
        showVacations: Number(settings.showVacations),
        showInventory: Number(settings.showInventory),
        taskTypes: JSON.stringify(settings.taskTypes || []),
        departments: JSON.stringify(settings.departments || [])
      };

      console.log('Sending settings to server:', settingsToSave);

      const response = await axios.post(
        `${config.apiUrl}/dashboard-settings.php`,
        settingsToSave,
        { 
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      
      console.log('Server response:', response.data);

      if (response.data?.success) {
        if (onSettingsChange) {
          onSettingsChange();
        }
        onClose();
      } else {
        throw new Error(response.data?.error || 'Failed to save settings');
      }
    } catch (error) {
      console.error('Error saving settings:', error);
      console.error('Error details:', error.response?.data);
      throw new Error('Failed to save settings');
    }
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography component="div" variant="h5">
          Dashboard Indstillinger
        </Typography>
      </DialogTitle>
      <DialogContent>
        <StyledSection>
          <Typography variant="subtitle1" gutterBottom>Generelle Indstillinger</Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(settings.showTasks)}
                  onChange={(e) => setSettings(prev => ({ ...prev, showTasks: e.target.checked }))}
                />
              }
              label="Vis opgaver"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={currentUser?.isAdmin ? Boolean(settings.showVacations) : false}
                  onChange={(e) => setSettings(prev => ({ ...prev, showVacations: e.target.checked }))}
                  disabled={!currentUser?.isAdmin}
                />
              }
              label="Vis ferieanmodninger"
              sx={{
                color: !currentUser?.isAdmin ? 'text.disabled' : 'text.primary'
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(settings.showInventory)}
                  onChange={(e) => setSettings(prev => ({ ...prev, showInventory: e.target.checked }))}
                />
              }
              label="Vis inventar oversigt"
            />
          </FormGroup>
        </StyledSection>

        <StyledSection>
          <Typography variant="subtitle1" gutterBottom>Opgavetyper</Typography>
          <FormControl fullWidth>
            <InputLabel>Vælg opgavetyper</InputLabel>
            <Select
              multiple
              value={settings.taskTypes}
              onChange={(e) => setSettings(prev => ({ ...prev, taskTypes: e.target.value }))}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip 
                      key={value} 
                      label={availableTaskTypes.find(t => t.value === value)?.label} 
                    />
                  ))}
                </Box>
              )}
            >
              {availableTaskTypes.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </StyledSection>

        <StyledSection>
          <Typography variant="subtitle1" gutterBottom>Afdelinger</Typography>
          <FormControl fullWidth>
            <InputLabel>Vælg afdelinger</InputLabel>
            <Select
              multiple
              value={settings.departments}
              onChange={(e) => setSettings(prev => ({ ...prev, departments: e.target.value }))}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip 
                      key={value} 
                      label={availableDepartments.find(d => d.id === value)?.name} 
                    />
                  ))}
                </Box>
              )}
            >
              {availableDepartments.map((dept) => (
                <MenuItem key={dept.id} value={dept.id}>
                  {dept.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </StyledSection>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annuller</Button>
        <Button onClick={handleSave} variant="contained">Gem</Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default DashboardSettings;