import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Grid, TextField, MenuItem, Checkbox, FormControlLabel, 
  Card, CardContent, CircularProgress, IconButton, Tooltip,
  Dialog, DialogTitle, DialogContent, DialogActions, Button,
  FormControl, InputLabel, Select, Alert, Snackbar,
  Tabs, Tab, Paper, ListItemText, Chip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Add as AddIcon, EventBusy as AbsenceIcon, Delete as DeleteIcon } from '@mui/icons-material';
import dayjs from 'dayjs';
import moment from 'moment';
import 'dayjs/locale/da';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isoWeek from 'dayjs/plugin/isoWeek';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import updateLocale from 'dayjs/plugin/updateLocale';
import api from '../../utils/apiUtils';
import SickIcon from '@mui/icons-material/Sick';
import EventBusyIcon from '@mui/icons-material/EventBusy';

// Configure dayjs
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);
dayjs.extend(isSameOrBefore);
dayjs.extend(updateLocale);
dayjs.locale('da');

// Now we can update the locale
dayjs.updateLocale('da', {
  weekStart: 0
});

// Styled Components
const WeekDayHeader = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  color: theme.palette.text.primary,
  fontWeight: 'bold',
}));

const ShiftCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'shiftType' && prop !== 'isIrregular' && prop !== 'bgcolor' && prop !== 'hasAbsence' && prop !== 'absenceType'
})(({ theme, shiftType, isIrregular, bgcolor, hasAbsence, absenceType }) => ({
  margin: '2px 0',
  backgroundColor: hasAbsence ? getAbsenceColor(absenceType) : (bgcolor || '#f5f5f5'),
  opacity: hasAbsence ? 0.9 : 1,
  transition: 'background-color 0.2s ease',
  '& .MuiCardContent-root': {
    padding: '8px',
    '&:last-child': {
      paddingBottom: '8px'
    }
  },
  '&:hover': {
    backgroundColor: hasAbsence ? getAbsenceColor(absenceType) : (bgcolor || '#e0e0e0'),
    opacity: 1
  }
}));

// Add function to get color based on absence type
const getAbsenceColor = (type) => {
  switch (type) {
    case 'sick':
      return '#ffcdd2'; // Light red for sick
    case 'barn_syg':
      return '#fff9c4'; // Light yellow for sick child
    case 'vacation':
      return '#c8e6c9'; // Light green for vacation
    case 'vacation_unpaid':
      return '#b2dfdb'; // Light teal for unpaid vacation
    case 'vacation_free':
      return '#bbdefb'; // Light blue for free vacation
    case 'afspadsering':
      return '#e1bee7'; // Light purple for time off
    case 'indisponibel':
      return '#cfd8dc'; // Light grey for unavailable
    case 'fri_helligdag':
      return '#ffccbc'; // Light orange for holiday
    default:
      return '#f5f5f5'; // Default grey
  }
};

// Add getVacationTypeDisplay function
const getVacationTypeDisplay = (type) => {
  switch (type) {
    case 'sick':
      return 'Syg';
    case 'barn_syg':
      return 'Barn syg';
    case 'vacation':
      return 'Ferie';
    case 'vacation_unpaid':
      return 'Ferie uden løn';
    case 'vacation_free':
      return 'Ferie Fri';
    case 'afspadsering':
      return 'Afspadsering';
    case 'indisponibel':
      return 'Indisponibel';
    case 'fri_helligdag':
      return 'Fri helligdag';
    default:
      return type;
  }
};

// Add StyledTabs and StyledTab components at the top with other styled components
const StyledTabs = styled(Tabs)(({ theme }) => ({
  minHeight: 48,
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  '& .MuiTabs-indicator': {
    display: 'none'
  }
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 500,
  fontSize: '0.9rem',
  minHeight: 48,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  borderRight: `1px solid ${theme.palette.divider}`,
  '&.Mui-selected': {
    backgroundColor: theme.palette.action.selected,
    color: theme.palette.text.primary,
    fontWeight: 600
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover
  },
  '&:last-child': {
    borderRight: 'none'
  }
}));

// Add this helper function at the top level
const formatTime = (time) => {
  if (!time) return '';
  return time.substring(0, 5); // Take only HH:mm part
};

const getWeekDates = (week, year) => {
  const weekStart = dayjs().year(year).week(week).startOf('week');
  const dates = [];
  
  for (let i = 0; i < 7; i++) {
    dates.push(weekStart.add(i, 'day'));
  }
  
  return dates;
};

const generateTeamShifts = (teams, week, year, isRange, startWeek, endWeek) => {
  const shifts = [];
  const startDate = isRange 
    ? dayjs().year(year).week(startWeek).startOf('week')
    : dayjs().year(year).week(week).startOf('week');
  const endDate = isRange
    ? dayjs().year(year).week(endWeek).endOf('week')
    : dayjs().year(year).week(week).endOf('week');

  teams.forEach(team => {
    if (!team.members || !team.schedule) return;

    team.members.forEach(member => {
      let currentDate = startDate.clone();
      
      // Keep generating shifts until we reach the end date
      while (currentDate.isSameOrBefore(endDate, 'day')) {
        const dayOfWeek = currentDate.day();
        const daySchedule = team.schedule[dayOfWeek];

        if (daySchedule && daySchedule.meetingTime && daySchedule.endTime) {
          shifts.push({
            id: `team_${team.id}_${member.id}_${currentDate.format('YYYY-MM-DD')}`,
            contact_id: member.id,
            contact_name: member.name,
            teamId: team.id,
            team_name: team.name,
            team_color: team.color,
            shiftType: team.shiftType,
            date: currentDate.format('YYYY-MM-DD'),
            startTime: daySchedule.meetingTime,
            endTime: daySchedule.endTime,
            isTeamShift: true
          });
        }
        currentDate = currentDate.add(1, 'day');
      }
    });
  });

  console.log('Generated team shifts:', shifts);
  return shifts;
};

const ShiftPlanning = () => {
  const [contacts, setContacts] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [teams, setTeams] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [absences, setAbsences] = useState([]);
  const [vacationRequests, setVacationRequests] = useState([]);
  const [selectedYear, setSelectedYear] = useState(dayjs().year());
  const [selectedWeek, setSelectedWeek] = useState(dayjs().week());
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedStartWeek, setSelectedStartWeek] = useState(dayjs().week());
  const [selectedEndWeek, setSelectedEndWeek] = useState(dayjs().week());
  const [isRangeMode, setIsRangeMode] = useState(false);
  const [availableYears] = useState([
    dayjs().year() - 1,
    dayjs().year(),
    dayjs().year() + 1,
    dayjs().year() + 2,
    dayjs().year() + 3
  ]);
  const [selectedDepartment, setSelectedDepartment] = useState('all');
  const [openShiftDialog, setOpenShiftDialog] = useState(false);
  const [openAbsenceDialog, setOpenAbsenceDialog] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedContact, setSelectedContact] = useState('');
  const [shiftStartTime, setShiftStartTime] = useState('');
  const [shiftEndTime, setShiftEndTime] = useState('');
  const [absenceReason, setAbsenceReason] = useState('');
  const [selectedAbsenceType, setSelectedAbsenceType] = useState('');
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [absenceTypes] = useState([
    { id: 'sick', label: 'Syg' },
    { id: 'barn_syg', label: 'Barn syg' },
    { id: 'vacation', label: 'Ferie' },
    { id: 'vacation_unpaid', label: 'Ferie uden løn' },
    { id: 'vacation_free', label: 'Ferie Fri' },
    { id: 'afspadsering', label: 'Afspadsering' },
    { id: 'indisponibel', label: 'Indisponibel' },
    { id: 'fri_helligdag', label: 'Fri helligdag' }
  ]);

  useEffect(() => {
    const fetchInitialData = async () => {
        setLoading(true);
        try {
            // Fetch all initial data in parallel
            const [contactsResponse, teamsResponse, departmentsResponse] = await Promise.all([
                api.get('/contacts.php'),
                api.get('/teams.php'),
                api.get('/departments.php')
            ]);

            // Process contacts
            if (contactsResponse.data?.success) {
                const schedulableContacts = contactsResponse.data.data.filter(contact => contact.can_be_scheduled === 1);
                setContacts(schedulableContacts);
            }

            // Process teams
            if (teamsResponse.data?.success) {
                setTeams(teamsResponse.data.data);
            }

            // Process departments
            if (departmentsResponse.data?.success) {
                setDepartments(departmentsResponse.data.data);
            }

            // Calculate current week's date range
            const startDate = dayjs().year(selectedYear).week(selectedWeek).startOf('week').format('YYYY-MM-DD');
            const endDate = dayjs().year(selectedYear).week(selectedWeek).endOf('week').format('YYYY-MM-DD');

            // Fetch shifts and absences immediately after initial data is loaded
            await Promise.all([
                fetchShifts(),
                fetchAbsences()
            ]);

        } catch (error) {
            console.error('Error fetching initial data:', error);
            setError('Der opstod en fejl ved indlæsning af data');
        } finally {
            setLoading(false);
        }
    };

    fetchInitialData();
  }, [selectedYear, selectedWeek]);

  const fetchShifts = async () => {
    try {
      const startWeek = isRangeMode ? selectedStartWeek : selectedWeek;
      const endWeek = isRangeMode ? selectedEndWeek : selectedWeek;
      
      const startDate = dayjs().year(selectedYear).week(startWeek).startOf('week').format('YYYY-MM-DD');
      const endDate = dayjs().year(selectedYear).week(endWeek).endOf('week').format('YYYY-MM-DD');
      
      console.log('Fetching shifts for range:', {
        startWeek,
        endWeek,
        startDate,
        endDate
      });

      // First fetch teams to get their schedules
      const teamsResponse = await api.get('/teams.php');
      if (teamsResponse.data?.success) {
        const teamsData = teamsResponse.data.data;
        setTeams(teamsData);

        // Then fetch manual shifts
        const shiftsResponse = await api.get('/shifts.php', {
          params: { 
            startDate,
            endDate
          }
        });

        // Get manual shifts
        const manualShifts = shiftsResponse.data?.success ? shiftsResponse.data.data : [];
        
        // Generate team shifts for the entire date range
        const generatedShifts = generateTeamShifts(
          teamsData, 
          startWeek,
          selectedYear, 
          isRangeMode,
          startWeek,
          endWeek
        );

        // Combine all shifts
        const allShifts = [...manualShifts, ...generatedShifts];
        
        console.log('All shifts:', allShifts);
        setShifts(allShifts);
      }
    } catch (error) {
      console.error('Error fetching shifts:', error);
      setError('Der opstod en fejl ved hentning af vagter');
    }
  };

  const fetchDepartments = async () => {
    try {
      const response = await api.get('/departments.php');
      if (response.data?.success) {
        // Only get departments that are visible in shift planning
        const visibleDepartments = response.data.data.filter(dept => dept.isVisibleInShiftPlanning === 1);
        setDepartments(visibleDepartments);
      }
    } catch (error) {
      console.error('Error fetching departments:', error);
    }
  };

  const fetchContacts = async () => {
    try {
      const response = await api.get('/contacts.php');
      if (response.data?.success) {
        const schedulableContacts = response.data.data.filter(contact => contact.can_be_scheduled === 1);
        
        const processedContacts = schedulableContacts.map(contact => ({
          id: contact.id,
          name: contact.name,
          department: contact.department,
          type: 'contact'
        }));

        const filteredContacts = selectedDepartment === 'all' 
          ? processedContacts 
          : processedContacts.filter(contact => contact.department === selectedDepartment);

        console.log('Processed contacts:', filteredContacts);
        setContacts(filteredContacts);
      }
    } catch (error) {
      console.error('Error fetching contacts:', error);
    }
  };

  const fetchVacationRequests = async () => {
    try {
      const response = await api.get('/vacations.php');
      if (response.data?.success) {
        const approvedVacations = response.data.data.filter(v => v.status === 'Approved');
        return approvedVacations.map(vacation => ({
          contact_id: vacation.contact_id,
          startDate: vacation.startDate,
          endDate: vacation.endDate,
          type: vacation.type,
          id: `vacation-${vacation.id}`
        }));
      }
      return [];
    } catch (error) {
      console.error('Error fetching vacation requests:', error);
      return [];
    }
  };

  const fetchAbsences = async () => {
    try {
      const startWeek = isRangeMode ? selectedStartWeek : selectedWeek;
      const endWeek = isRangeMode ? selectedEndWeek : selectedWeek;
      
      const startDate = dayjs().year(selectedYear).week(startWeek).startOf('week');
      const endDate = dayjs().year(selectedYear).week(endWeek).endOf('week');

      console.log('Fetching absences for date range:', {
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD')
      });

      const [absencesResponse, vacationAbsences] = await Promise.all([
        api.get('/absences.php', {
          params: {
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD')
          }
        }),
        fetchVacationRequests()
      ]);

      if (absencesResponse.data?.success) {
        const regularAbsences = absencesResponse.data.data.map(absence => ({
          ...absence,
          contact_name: contacts.find(c => c.id === parseInt(absence.contact_id))?.name
        }));

        const combinedAbsences = [
          ...regularAbsences,
          ...vacationAbsences.map(absence => ({
            ...absence,
            contact_name: contacts.find(c => c.id === parseInt(absence.contact_id))?.name
          }))
        ];

        console.log('Combined absences:', combinedAbsences);
        setAbsences(combinedAbsences);
      }
    } catch (error) {
      console.error('Error fetching absences:', error);
    }
  };

  const fetchTeams = async () => {
    try {
      const response = await api.get('/teams.php');
      if (response.data?.success) {
        setTeams(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching teams:', error);
    }
  };

  const renderShiftPlanning = () => {
    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">{error}</Typography>;

    // Fix the weekRange calculation
    const weekRange = isRangeMode 
      ? Array.from(
          { length: selectedEndWeek - selectedStartWeek + 1 }, 
          (_, i) => parseInt(selectedStartWeek) + i
        )
      : [selectedWeek];

    console.log('Week range:', weekRange); // Debug log

    // Filter shifts based on selected department
    const filteredShifts = shifts.filter(shift => {
      const contact = contacts.find(c => c.id === parseInt(shift.contact_id));
      return selectedDepartment === 'all' || contact?.department === selectedDepartment;
    });

    // Filter absences based on selected department
    const filteredAbsences = absences.filter(absence => {
      const contact = contacts.find(c => c.id === parseInt(absence.contact_id));
      return selectedDepartment === 'all' || contact?.department === selectedDepartment;
    });

    return (
      <>
        {weekRange.map((week) => (
          <Box key={`week-${week}`} mb={4}>
            <Typography variant="h6" gutterBottom>
              Uge {week} ({dayjs().year(selectedYear).week(week).startOf('week').format('DD/MM')} 
              - {dayjs().year(selectedYear).week(week).endOf('week').format('DD/MM/YYYY')})
            </Typography>
            <Grid container spacing={1}>
              {getWeekDates(week, selectedYear).map((date, index) => {
                const dateString = date.format('YYYY-MM-DD');
                
                // Use filteredAbsences instead of absences
                const dateAbsences = filteredAbsences.filter(a => {
                  const absenceStart = dayjs(a.startDate);
                  const absenceEnd = dayjs(a.endDate);
                  const currentDate = dayjs(dateString);
                  return currentDate.isBetween(absenceStart, absenceEnd, 'day', '[]');
                });

                // Get absent contact IDs for this date
                const absentContactIds = dateAbsences.map(a => parseInt(a.contact_id));

                // Get shifts for this date, excluding absent people
                const dateShifts = filteredShifts
                  .filter(s => s.date === dateString)
                  .filter(s => !absentContactIds.includes(parseInt(s.contact_id)))
                  .sort((a, b) => {
                    // First sort by shift type (day shifts first)
                    if (a.shiftType === 'day' && b.shiftType !== 'day') return -1;
                    if (a.shiftType !== 'day' && b.shiftType === 'day') return 1;
                    
                    // Then sort by team vs manual (team shifts first)
                    if (a.teamId && !b.teamId) return -1;
                    if (!a.teamId && b.teamId) return 1;
                    
                    // Then sort by start time
                    const timeA = a.startTime?.replace(':', '') || '0000';
                    const timeB = b.startTime?.replace(':', '') || '0000';
                    return timeA.localeCompare(timeB);
                  });

                return (
                  <Grid item xs key={dateString}>
                    <WeekDayHeader>
                      {['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'][date.day()]}
                      <br />
                      {date.format('DD/MM')}
                    </WeekDayHeader>

                    {/* Shifts */}
                    {dateShifts.map(shift => (
                      <ShiftCard 
                        key={`shift-${shift.id}`}
                        bgcolor={shift.team_color || '#f5f5f5'}
                        hasAbsence={false}
                      >
                        <CardContent>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box>
                              <Typography variant="body2">{shift.contact_name}</Typography>
                              {shift.team_name && (
                                <Typography variant="caption" color="textSecondary">
                                  {shift.team_name}
                                </Typography>
                              )}
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                              <Typography variant="caption">
                                {formatTime(shift.startTime)} - {formatTime(shift.endTime)}
                              </Typography>
                              {!shift.id?.toString().startsWith('team_') && (
                                <Tooltip title="Slet vagt">
                                  <IconButton 
                                    size="small" 
                                    onClick={() => handleDeleteShift(shift.id)}
                                    sx={{ 
                                      padding: '2px',
                                      '&:hover': {
                                        color: 'error.main'
                                      }
                                    }}
                                  >
                                    <DeleteIcon sx={{ fontSize: '1rem' }} />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Box>
                          </Box>
                        </CardContent>
                      </ShiftCard>
                    ))}

                    {/* Absences */}
                    {dateAbsences.length > 0 && (
                      <Box sx={{ mt: 1 }}>
                        {dateAbsences.map(absence => (
                          <ShiftCard 
                            key={`absence-${absence.id}`}
                            hasAbsence={true}
                            absenceType={absence.type}
                          >
                            <CardContent>
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Box>
                                  <Typography variant="body2" sx={{ 
                                    fontWeight: 500,
                                    fontSize: '0.875rem'
                                  }}>
                                    {absence.contact_name || 'Unknown'}
                                  </Typography>
                                  <Typography variant="caption" color="error">
                                    {getVacationTypeDisplay(absence.type)}
                                  </Typography>
                                </Box>
                              </Box>
                            </CardContent>
                          </ShiftCard>
                        ))}
                      </Box>
                    )}
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        ))}
      </>
    );
  };

  const handleAddCustomShift = () => {
    setOpenShiftDialog(true);
  };

  const handleAddAbsence = () => {
    setOpenAbsenceDialog(true);
    setSelectedDate(null);
    setSelectedEndDate(null);
    setSelectedContacts([]);
    setSelectedAbsenceType('');
    setAbsenceReason('');
  };

  const handleCloseAbsenceDialog = () => {
    setOpenAbsenceDialog(false);
    setSelectedDate(null);
    setSelectedEndDate(null);
    setSelectedContacts([]);
    setSelectedAbsenceType('');
    setAbsenceReason('');
  };

  const handleSaveAbsence = async () => {
    try {
      if (!selectedDate || !selectedEndDate || selectedContacts.length === 0 || !selectedAbsenceType) {
        setErrorMessage('Alle påkrævede felter skal udfyldes');
        return;
      }

      const startDate = dayjs(selectedDate).format('YYYY-MM-DD');
      const endDate = dayjs(selectedEndDate).format('YYYY-MM-DD');

      // Create absences for each selected contact
      await Promise.all(selectedContacts.map(contactId => 
        api.post('/absences.php', {
          contact_id: contactId,
          startDate: startDate,
          endDate: endDate,
          type: selectedAbsenceType,
          note: absenceReason
        })
      ));

      await fetchAbsences();
      setOpenAbsenceDialog(false);
      setSuccessMessage('Fravær blev registreret');

      // Reset form
      setSelectedDate(null);
      setSelectedEndDate(null);
      setSelectedContacts([]);
      setSelectedAbsenceType('');
      setAbsenceReason('');
    } catch (error) {
      console.error('Error saving absence:', error);
      setErrorMessage('Der opstod en fejl ved registrering af fravær');
    }
  };

  const handleRangeModeToggle = (e) => {
    const checked = e.target.checked;
    setIsRangeMode(checked);
    if (checked) {
      setSelectedStartWeek(parseInt(selectedWeek));
      setSelectedEndWeek(parseInt(selectedWeek));
    }
  };

  const handleWeekChange = (e) => {
    const value = parseInt(e.target.value);
    if (value >= 1 && value <= 53) {
      if (isRangeMode) {
        setSelectedStartWeek(value);
        if (value > selectedEndWeek) {
          setSelectedEndWeek(value);
        }
      } else {
        setSelectedWeek(value);
      }
    }
  };

  const handleEndWeekChange = (e) => {
    const value = parseInt(e.target.value);
    if (value >= parseInt(selectedStartWeek) && value <= 53) {
      setSelectedEndWeek(value);
    }
  };

  const handleDeleteShift = async (shiftId) => {
    try {
      // Check if it's a team shift by the ID format
      if (typeof shiftId === 'string' && shiftId.startsWith('team_')) {
        // Team shifts can't be deleted
        setErrorMessage('Team vagter kan ikke slettes manuelt');
        return;
      }

      // Confirm deletion
      if (window.confirm('Er du sikker på, at du vil slette denne vagt?')) {
        const response = await api.delete(`/shifts.php/${shiftId}`);
        
        if (response.data?.success) {
          await fetchShifts();
          setSuccessMessage('Vagten blev slettet');
        } else {
          throw new Error('Failed to delete shift');
        }
      }
    } catch (error) {
      console.error('Error deleting shift:', error);
      setErrorMessage('Der opstod en fejl ved sletning af vagten');
    }
  };

  const handleDeleteAbsence = async (absenceId) => {
    try {
      // Check if it's a vacation absence by the ID format
      if (typeof absenceId === 'string' && absenceId.startsWith('vacation-')) {
        // Extract the vacation ID from the string
        const vacationId = absenceId.replace('vacation-', '');
        await api.delete(`/vacations.php`, {
          data: { id: vacationId }
        });
      } else {
        // Regular absence
        await api.delete(`/absences.php/${absenceId}`);
      }
      
      await fetchAbsences();
      setSuccessMessage('Fravær blev slettet');
    } catch (error) {
      console.error('Error deleting absence:', error);
      setErrorMessage('Der opstod en fejl ved sletning af fraværet');
    }
  };

  // Add team-related functions
  const getTeamScheduleForDay = (teamId, date) => {
    if (!date || !teamId) return null;
    const team = teams.find(t => t.id === teamId);
    if (!team || !team.schedule) return null;
    
    const dayOfWeek = dayjs(date).day();
    return team.schedule[dayOfWeek];
  };

  const handleTeamSelect = (teamId) => {
    setSelectedTeam(teamId);
    if (teamId && selectedDate) {
      const schedule = getTeamScheduleForDay(teamId, selectedDate);
      if (schedule) {
        setShiftStartTime(schedule.meetingTime);
        setShiftEndTime(schedule.endTime);
      }
    }
  };

  const handleSaveShift = async () => {
    try {
      const shiftData = {
        contact_id: selectedContact,
        date: dayjs(selectedDate).format('YYYY-MM-DD'),
        startTime: shiftStartTime,
        endTime: shiftEndTime,
        teamId: selectedTeam || null
      };

      await api.post('/shifts.php', shiftData);
      await fetchShifts();
      setOpenShiftDialog(false);
      setSuccessMessage('Vagt oprettet');
      
      // Reset form
      setSelectedDate(null);
      setSelectedContact('');
      setShiftStartTime('');
      setShiftEndTime('');
      setSelectedTeam('');
    } catch (error) {
      console.error('Error saving shift:', error);
      setErrorMessage('Der opstod en fejl ved oprettelse af vagten');
    }
  };

  const handleCloseShiftDialog = () => {
    setOpenShiftDialog(false);
    setSelectedDate(null);
    setSelectedContact('');
    setShiftStartTime('');
    setShiftEndTime('');
    setSelectedTeam('');
  };

  // Add team dialog JSX
  const renderTeamDialog = () => (
    <Dialog open={openShiftDialog} onClose={handleCloseShiftDialog}>
      <DialogTitle>Tilføj vagt</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Dato"
              value={selectedDate}
              onChange={(newValue) => {
                setSelectedDate(newValue);
                if (selectedTeam) {
                  const schedule = getTeamScheduleForDay(selectedTeam, newValue);
                  if (schedule) {
                    setShiftStartTime(schedule.meetingTime);
                    setShiftEndTime(schedule.endTime);
                  }
                }
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          
          <FormControl fullWidth>
            <InputLabel>Medarbejder</InputLabel>
            <Select
              value={selectedContact}
              onChange={(e) => setSelectedContact(e.target.value)}
              label="Medarbejder"
            >
              {contacts.map((contact) => (
                <MenuItem key={contact.id} value={contact.id}>
                  {contact.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>Vælg team tidsplan</InputLabel>
            <Select
              value={selectedTeam}
              onChange={(e) => handleTeamSelect(e.target.value)}
              label="Vælg team tidsplan"
            >
              <MenuItem value="">
                <em>Manuel indtastning</em>
              </MenuItem>
              {teams.map((team) => (
                <MenuItem key={team.id} value={team.id}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Box 
                      sx={{ 
                        width: 16, 
                        height: 16, 
                        backgroundColor: team.color,
                        borderRadius: '50%'
                      }} 
                    />
                    {team.name}
                    {selectedDate && getTeamScheduleForDay(team.id, selectedDate) && (
                      <Typography variant="caption" sx={{ ml: 1 }}>
                        ({formatTime(getTeamScheduleForDay(team.id, selectedDate).meetingTime)} - 
                        {formatTime(getTeamScheduleForDay(team.id, selectedDate).endTime)})
                      </Typography>
                    )}
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {!selectedTeam && (
            <>
              <TextField
                label="Start tid"
                type="time"
                value={shiftStartTime}
                onChange={(e) => setShiftStartTime(e.target.value)}
                InputLabelProps={{ shrink: true }}
                inputProps={{ step: 300 }}
              />
              <TextField
                label="Slut tid"
                type="time"
                value={shiftEndTime}
                onChange={(e) => setShiftEndTime(e.target.value)}
                InputLabelProps={{ shrink: true }}
                inputProps={{ step: 300 }}
              />
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseShiftDialog}>Annuller</Button>
        <Button 
          onClick={handleSaveShift} 
          variant="contained" 
          color="primary"
          disabled={!selectedDate || !selectedContact || (!selectedTeam && (!shiftStartTime || !shiftEndTime))}
        >
          Gem
        </Button>
      </DialogActions>
    </Dialog>
  );

  // Add wheel event handlers
  const handleWeekWheel = (e, isEndWeek = false) => {
    e.preventDefault(); // Prevent page scroll
    const delta = Math.sign(e.deltaY) * -1; // -1 for scroll down, 1 for scroll up
    
    if (isEndWeek) {
      const newValue = selectedEndWeek + delta;
      if (newValue >= selectedStartWeek && newValue <= 53) {
        setSelectedEndWeek(newValue);
      }
    } else {
      const newValue = isRangeMode ? selectedStartWeek + delta : selectedWeek + delta;
      if (newValue >= 1 && newValue <= 53) {
        if (isRangeMode) {
          setSelectedStartWeek(newValue);
          if (newValue > selectedEndWeek) {
            setSelectedEndWeek(newValue);
          }
        } else {
          setSelectedWeek(newValue);
        }
      }
    }
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">Vagtplanlægning</Typography>
        <Box display="flex" gap={2}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setOpenShiftDialog(true)}
          >
            Tilføj vagt
          </Button>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<EventBusyIcon />}
            onClick={() => setOpenAbsenceDialog(true)}
          >
            Registrer fravær
          </Button>
        </Box>
      </Box>

      <Box display="flex" alignItems="center" gap={2} mb={3}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isRangeMode}
              onChange={handleRangeModeToggle}
            />
          }
          label="Vis flere uger"
        />

        {isRangeMode ? (
          <>
            <TextField
              label="Start uge"
              type="number"
              value={selectedStartWeek}
              onChange={handleWeekChange}
              onWheel={(e) => handleWeekWheel(e)}
              sx={{ width: 100 }}
              inputProps={{ 
                min: 1, 
                max: 53,
                step: 1
              }}
            />
            <TextField
              label="Slut uge"
              type="number"
              value={selectedEndWeek}
              onChange={handleEndWeekChange}
              onWheel={(e) => handleWeekWheel(e, true)}
              sx={{ width: 100 }}
              inputProps={{ 
                min: selectedStartWeek, 
                max: 53,
                step: 1
              }}
            />
          </>
        ) : (
          <TextField
            label="Uge"
            type="number"
            value={selectedWeek}
            onChange={handleWeekChange}
            onWheel={(e) => handleWeekWheel(e)}
            sx={{ width: 100 }}
            inputProps={{ 
              min: 1, 
              max: 53,
              step: 1
            }}
          />
        )}

        <TextField
          select
          label="År"
          value={selectedYear}
          onChange={(e) => setSelectedYear(parseInt(e.target.value))}
          sx={{ width: 100 }}
        >
          {availableYears.map((year) => (
            <MenuItem key={year} value={year}>{year}</MenuItem>
          ))}
        </TextField>
      </Box>

      <Paper elevation={0} sx={{ mb: 2, borderRadius: 1, backgroundColor: 'background.paper' }}>
        <StyledTabs
          value={selectedDepartment}
          onChange={(e, newValue) => setSelectedDepartment(newValue)}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          <StyledTab label="Alle afdelinger" value="all" />
          {departments
            .filter(dept => dept.isVisibleInShiftPlanning === 1)  // Only show visible departments
            .map((dept) => (
              <StyledTab 
                key={dept.id}
                label={dept.name}
                value={dept.name}
              />
            ))}
        </StyledTabs>
      </Paper>

      {loading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : error ? (
        <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
      ) : (
        <Paper elevation={1} sx={{ p: 2 }}>
          {renderShiftPlanning()}
        </Paper>
      )}

      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage(null)}
      >
        <Alert severity="success" onClose={() => setSuccessMessage(null)}>
          {successMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage(null)}
      >
        <Alert severity="error" onClose={() => setErrorMessage(null)}>
          {errorMessage}
        </Alert>
      </Snackbar>

      {/* Dialogs */}
      {/* ... Add your dialogs here ... */}
      
      {/* Add the team dialog */}
      {renderTeamDialog()}

      {/* Absence Dialog */}
      <Dialog 
        open={openAbsenceDialog} 
        onClose={handleCloseAbsenceDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Registrer fravær</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start dato"
                value={selectedDate}
                onChange={(newValue) => {
                  setSelectedDate(newValue);
                  if (!selectedEndDate || dayjs(selectedEndDate).isBefore(newValue)) {
                    setSelectedEndDate(newValue);
                  }
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
              <DatePicker
                label="Slut dato"
                value={selectedEndDate}
                onChange={(newValue) => setSelectedEndDate(newValue)}
                minDate={selectedDate}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>

            <FormControl fullWidth>
              <InputLabel>Medarbejdere</InputLabel>
              <Select
                multiple
                value={selectedContacts}
                onChange={(e) => setSelectedContacts(e.target.value)}
                label="Medarbejdere"
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={contacts.find(c => c.id === value)?.name}
                        size="small"
                      />
                    ))}
                  </Box>
                )}
              >
                {contacts.map((contact) => (
                  <MenuItem key={contact.id} value={contact.id}>
                    <Checkbox checked={selectedContacts.indexOf(contact.id) > -1} />
                    <ListItemText primary={contact.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel>Type af fravær</InputLabel>
              <Select
                value={selectedAbsenceType}
                onChange={(e) => setSelectedAbsenceType(e.target.value)}
                label="Type af fravær"
              >
                {absenceTypes.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Bemærkning"
              multiline
              rows={4}
              value={absenceReason}
              onChange={(e) => setAbsenceReason(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAbsenceDialog}>Annuller</Button>
          <Button 
            onClick={handleSaveAbsence} 
            variant="contained" 
            color="primary"
            disabled={!selectedDate || !selectedEndDate || selectedContacts.length === 0 || !selectedAbsenceType}
          >
            Gem
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ShiftPlanning;
