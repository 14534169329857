import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  TextField,
  Box,
  Paper,
  List,
  ListItem,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Alert,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const productFullNames = {
  'KRD': { name: 'Kristeligt Dagblad', color: '' },
  'FAA': { name: 'Frederiksborg Amts Avis', color: '' },
  'INF': { name: 'Information', color: '' },
  'SLA': { name: 'Sjællandske Slagelse', color: '' },
  'NÆS': { name: 'Sjællandske Næstved', color: '' },
  'NVNH': { name: 'Nordvestnyt Holbæk', color: 'rød' },
  'NVNK': { name: 'Nordvestnyt Kalundborg', color: 'rød' },
  'DBRI': { name: 'Dagbladet Ringsted', color: 'gul' },
  'DBRO': { name: 'Dagbladet Roskilde', color: 'blå' },
  'DBKOE': { name: 'Dagbladet Køge', color: 'grøn' },
  'FRE': { name: 'Frederiksborg Amts Avis', color: '' },
  'FAA2': { name: 'Frederiksborg Amts Avis', color: '' },
  'NVNHP': { name: 'Nordvestnyt Holbæk Post', color: '' },
  'NVNKP': { name: 'Nordvestnyt Kalundborg Post', color: '' },
  'SJJNÆ': { name: 'Sjællandske Næstved Post', color: '' },
  'SJJSL': { name: 'Sjællandske Slagelse Post', color: '' },
  'FOLKE': { name: 'Lolland-Falster Folketidende', color: ''}
};

const læsNrInfo = {
  '138': { city: 'Holbæk', ignore: ['NVNH', 'NVNK'] },
  '139': { city: 'Vordingborg', ignore: ['NÆS'] },
  '140': { city: 'Slagelse', ignore: ['SLA'] },
  '141': { city: 'Næstved', ignore: ['NÆS'] },
  '142': { city: 'Haslev', ignore: [] },
  '143': { city: 'Herfølge', ignore: ['DBKOE'] },
  '144': { city: 'Ringsted', ignore: ['DBRI'] },
  '902': { city: 'ISV', ignore: ['DBRO', 'KRD', 'FAA', 'INF', 'FRE', 'FAA2']},
  '903': { city: 'ISV', ignore: ['DBRO', 'KRD', 'FAA', 'INF', 'FRE', 'FAA2']}
};

const productsWithExtraPages = ['SLA', 'DBRI', 'DBKOE', 'DBRO', 'NÆS', 'NVNK', 'NVNH'];
const predefinedGroups = [['FRE', 'FAA', 'FAA2']];
const postProducts = ['NVNHP', 'NVNKP', 'SJJNÆ', 'SJJSL', 'FOLKE'];

const PdfSplitterComponent = () => {
  const [productNames, setProductNames] = useState("KRD, FAA, INF, SLA, NÆS, NVNH, NVNK, DBRI, DBRO, DBKOE, FRE, FAA2, NVNHP, NVNKP, SJJNÆ, SJJSL, FOLKE");
  const [productGroups, setProductGroups] = useState("");
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [downloadLinks, setDownloadLinks] = useState([]);
  const [dateWarning, setDateWarning] = useState(false);
  const [fileName, setFileName] = useState('');

  useEffect(() => {
    // Load pdf.js and pdf-lib
    const loadScripts = async () => {
      await Promise.all([
        loadScript('https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.min.js'),
        loadScript('https://cdnjs.cloudflare.com/ajax/libs/pdf-lib/1.16.0/pdf-lib.min.js')
      ]);
      window.pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js';
    };
    loadScripts();
  }, []);

  const loadScript = (src) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  };

  const handleProductNamesChange = (event) => {
    setProductNames(event.target.value);
  };

  const handleProductGroupsChange = (event) => {
    setProductGroups(event.target.value);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile ? selectedFile.name : '');
  };

  const processFile = async () => {
    if (!file) return;

    setIsLoading(true);
    setDateWarning(false);
    setDownloadLinks([]);

    try {
      const arrayBuffer = await file.arrayBuffer();
      const pdfDoc = await window.PDFLib.PDFDocument.load(arrayBuffer);
      const pdf = await window.pdfjsLib.getDocument({ data: arrayBuffer }).promise;

      const productPages = {};
      const postPages = [];

      // Check date on first page
      const firstPage = await pdf.getPage(1);
      const textContent = await firstPage.getTextContent();
      let textItems = textContent.items.map(item => item.str).join(' ').replace(/INF820/g, '');

      const today = new Date();
      const todayDateStr = today.toLocaleDateString('da-DK', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\./g, '-');
      const dateRegex = /\b\d{2}-\d{2}-\d{4}\b/;
      const dateMatch = textItems.match(dateRegex);
      setDateWarning(!dateMatch || dateMatch[0] !== todayDateStr);

      const productNamesArray = productNames.split(',').map(name => name.trim());
      const additionalProductGroups = productGroups.split(';').map(group => group.split(',').map(name => name.trim()));

      // Process all pages
      for (let i = 0; i < pdf.numPages; i++) {
        const page = await pdf.getPage(i + 1);
        const textContent = await page.getTextContent();
        let textItems = textContent.items.map(item => item.str).join(' ').replace(/INF820/g, '');

        if (textItems.includes('SJJNÆ')) {
          postPages.push(i);
        }

        for (const productName of [...productNamesArray, ...postProducts]) {
          const regex = new RegExp(`\\b${productName}\\b`, 'g');
          if (regex.test(textItems)) {
            if (!productPages[productName]) {
              productPages[productName] = [];
            }
            productPages[productName].push(i);
            if (postProducts.includes(productName)) {
              postPages.push(i);
            }
            break;
          }
        }
      }

      const generateLink = async (groupName, pages) => {
        const newPdfDoc = await window.PDFLib.PDFDocument.create();
        const helveticaFont = await newPdfDoc.embedFont(window.PDFLib.StandardFonts.Helvetica);

        for (const pageNum of pages) {
          const [copiedPage] = await newPdfDoc.copyPages(pdfDoc, [pageNum]);
          newPdfDoc.addPage(copiedPage);

          const pageText = await getPageText(pdf, pageNum + 1);
          const læsNrMatch = pageText.match(/(\d+)\s+Læs Nr/);
          const bundtLøseMatch = pageText.match(/(\d+)\s+(\d+)\s+Bundt\s+Løse/);

          if (læsNrMatch && bundtLøseMatch) {
            const læsNr = læsNrMatch[1];
            const bundtCount = parseInt(bundtLøseMatch[1]);
            const løseCount = parseInt(bundtLøseMatch[2]);

            if (læsNrInfo[læsNr] && !læsNrInfo[læsNr].ignore.includes(groupName) && 
                productsWithExtraPages.includes(groupName) && 
                (bundtCount > 1 || (bundtCount === 1 && løseCount > 0))) {
              
              const customPageCount = løseCount > 0 ? bundtCount : bundtCount - 1;
              
              for (let i = 0; i < customPageCount; i++) {
                const extraPage = newPdfDoc.addPage([842, 595]);
                const { width, height } = extraPage.getSize();
                
                const productName = productFullNames[groupName].name;
                const fontSize = 72;
                const textWidth = helveticaFont.widthOfTextAtSize(productName, fontSize);
                extraPage.drawText(productName, {
                  x: (width - textWidth) / 2,
                  y: height / 2 + fontSize / 4,
                  size: fontSize,
                  font: helveticaFont,
                  color: window.PDFLib.rgb(0, 0, 0),
                });

                const bottomY = 50;
                const leftX = 50;
                const rightX = width - 250;
                const infoFontSize = 24;

                extraPage.drawText(`Læs nr: ${læsNr}`, {
                  x: leftX,
                  y: bottomY + infoFontSize * 2,
                  size: infoFontSize,
                  font: helveticaFont,
                  color: window.PDFLib.rgb(0, 0, 0),
                });
                extraPage.drawText(`By: ${læsNrInfo[læsNr].city}`, {
                  x: rightX,
                  y: bottomY + infoFontSize * 2,
                  size: infoFontSize,
                  font: helveticaFont,
                  color: window.PDFLib.rgb(0, 0, 0),
                });
                
                const displayedBundtCount = løseCount > 0 ? bundtCount : customPageCount;
                extraPage.drawText(`Bundt: ${i + 1} af ${displayedBundtCount}`, {
                  x: (width - helveticaFont.widthOfTextAtSize(`Bundt: ${i + 1} af ${displayedBundtCount}`, infoFontSize)) / 2,
                  y: bottomY,
                  size: infoFontSize,
                  font: helveticaFont,
                  color: window.PDFLib.rgb(0, 0, 0),
                });
              }
            }
          }
        }

        const pdfBytes = await newPdfDoc.save();
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);

        let filename = `${groupName}.pdf`;
        const colorInfo = groupName.split('-').map(code => productFullNames[code] ? productFullNames[code].color : '').filter(color => color).join('_');
        if (colorInfo) {
          filename = `${groupName}_${colorInfo}.pdf`;
        }

        return { url, filename };
      };

      const links = [];

      // Generate PDFs for predefined groups
      for (const group of predefinedGroups) {
        const groupName = group.join('-');
        const groupPages = group.flatMap(product => productPages[product] || []);
        if (groupPages.length > 0) {
          links.push(await generateLink(groupName, groupPages));
        }
      }

      // Generate PDFs for additional groups
      for (const group of additionalProductGroups) {
        const groupName = group.join('-');
        const groupPages = group.flatMap(product => productPages[product] || []);
        if (groupPages.length > 0) {
          links.push(await generateLink(groupName, groupPages));
        }
      }

      // Generate PDF for post products
      if (postPages.length > 0) {
        links.push(await generateLink('POST', postPages));
      }

      // Generate PDFs for individual products
      for (const [product, pages] of Object.entries(productPages)) {
        if (!predefinedGroups.some(group => group.includes(product)) &&
            !additionalProductGroups.some(group => group.includes(product)) &&
            !postProducts.includes(product)) {
          links.push(await generateLink(product, pages));
        }
      }

      setDownloadLinks(links);
    } catch (error) {
      console.error("Error processing PDF:", error);
      alert("Der opstod en fejl under behandlingen af PDF'en. Prøv venligst igen.");
    } finally {
      setIsLoading(false);
    }
  };

  const getPageText = async (pdf, pageNum) => {
    const page = await pdf.getPage(pageNum);
    const textContent = await page.getTextContent();
    return textContent.items.map(item => item.str).join(' ');
  };

  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>PDF Splitter</Typography>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Produktnavne og produktgrupper</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              fullWidth
              label="Indtast produktnavne (komma adskilt)"
              value={productNames}
              onChange={handleProductNamesChange}
            />
            <TextField
              fullWidth
              label="Indtast yderligere produktgrupper"
              placeholder="Eksempel: KRD,FAA,INF; SLA,NÆS,NVNH"
              value={productGroups}
              onChange={handleProductGroupsChange}
            />
          </Box>
        </AccordionDetails>
      </Accordion>

      <Box my={3}>
        <Button
          variant="contained"
          component="label"
        >
          Vælg overliggere PDF-fil
          <input
            type="file"
            hidden
            accept="application/pdf"
            onChange={handleFileChange}
          />
        </Button>
      </Box>

      {fileName && (
        <Box my={2}>
          <Typography>
            Valgt fil: <strong>{fileName}</strong>
          </Typography>
        </Box>
      )}

      <Box my={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={processFile}
          disabled={!file || isLoading}
        >
          Behandl PDF
        </Button>
      </Box>

      {isLoading && <CircularProgress />}
      
      {dateWarning && (
        <Alert severity="warning" sx={{ my: 2 }}>
          Advarsel: Datoen på PDF-filen er ikke dagens dato!
        </Alert>
      )}

      {downloadLinks.length > 0 && (
        <List>
          {downloadLinks.map((link, index) => (
            <ListItem key={index}>
              <ListItemText>
                <a href={link.url} download={link.filename}>{link.filename}</a>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      )}
    </Paper>
  );
};

export default PdfSplitterComponent;