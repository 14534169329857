import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  TextField, Select, MenuItem, FormControl, InputLabel,
  Button, Box, FormHelperText, Typography, LinearProgress, IconButton, Alert, Tooltip, Autocomplete, FormControlLabel, Checkbox
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import config from '../../config';
import { styled } from '@mui/material/styles';
import { 
  PlayArrow as StartIcon, 
  Stop as StopIcon, 
  CheckCircle as CompleteIcon 
} from '@mui/icons-material';
import api from '../../utils/apiUtils';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// Styled components for file upload
const UploadInput = styled('input')({
  display: 'none',
});

const UploadBox = styled(Box)(({ theme }) => ({
  border: `2px dashed ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  textAlign: 'center',
  marginTop: theme.spacing(2),
  cursor: 'pointer',
  '&:hover': {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.action.hover,
  },
}));

// Add status options constant
const STATUS_OPTIONS = [
  { value: 'Afventer', label: 'Afventer' },
  { value: 'I gang', label: 'I gang' },
  { value: 'Afsluttet', label: 'Afsluttet' }
];

// Add status actions component
const StatusActions = ({ status, onStatusChange }) => {
  switch (status) {
    case 'Afventer':
      return (
        <Tooltip title="Start opgave">
          <IconButton
            onClick={() => onStatusChange('I gang')}
            color="primary"
            size="small"
          >
            <StartIcon />
          </IconButton>
        </Tooltip>
      );
    case 'I gang':
      return (
        <>
          <Tooltip title="Sæt på pause">
            <IconButton
              onClick={() => onStatusChange('Afventer')}
              color="warning"
              size="small"
            >
              <StopIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Afslut opgave">
            <IconButton
              onClick={() => onStatusChange('Afsluttet')}
              color="success"
              size="small"
            >
              <CompleteIcon />
            </IconButton>
          </Tooltip>
        </>
      );
    case 'Afsluttet':
      return (
        <Tooltip title="Genåbn opgave">
          <IconButton
            onClick={() => onStatusChange('Afventer')}
            color="primary"
            size="small"
          >
            <StartIcon />
          </IconButton>
        </Tooltip>
      );
    default:
      return null;
  }
};

const TaskDialog = ({
  open,
  onClose,
  onSubmit,
  departments,
  machines,
  filteredMachines = [],
  users,
  task,
  isEditing,
  onDepartmentChange,
  isAdmin
}) => {
  const [formData, setFormData] = useState({
    afdeling: '',
    maskine: '',
    title: '',
    prioritet: 'Medium',
    status: 'Afventer',
    forfaldsdato: null,
    beskrivelse: '',
    tildeltTil: null,
    taskType: 'maintenance',
    repetitionInterval: 'none',
    nextDue: null,
    is_today_task: isAdmin ? 0 : 0,
    team: isAdmin ? 'both' : null
  });

  const [errors, setErrors] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [error, setError] = useState(null);
  const [availableParts, setAvailableParts] = useState([]);
  const [selectedParts, setSelectedParts] = useState([]);
  const [partsLoading, setPartsLoading] = useState(false);

  // Update the useEffect for dialog open/close
  useEffect(() => {
    if (!open) {
      setFormData({
        afdeling: '',
        maskine: '',
        title: '',
        prioritet: 'Medium',
        status: 'Afventer',
        forfaldsdato: null,
        beskrivelse: '',
        tildeltTil: null,
        taskType: 'maintenance',
        repetitionInterval: 'none',
        nextDue: null,
        is_today_task: 0,
        team: null
      });
      setSelectedParts([]); // Clear selected parts
      setSelectedFiles([]);
      setUploadProgress(0);
      setErrors({});
      setError(null);
    } else if (task && isEditing) {
      console.log('Setting task data:', task);
      try {
        setFormData({
          afdeling: task.afdeling || '',
          maskine: task.maskine || '',
          title: task.title || '',
          prioritet: task.prioritet || 'Medium',
          status: task.status || 'Afventer',
          forfaldsdato: task.forfaldsdato ? dayjs(task.forfaldsdato) : null,
          beskrivelse: task.beskrivelse || '',
          tildeltTil: task.tildeltTil || null,
          taskType: task.taskType || 'maintenance',
          repetitionInterval: task.repetitionInterval || 'none',
          nextDue: task.nextDue ? dayjs(task.nextDue) : null,
          is_today_task: isAdmin ? (task.is_today_task ? 1 : 0) : 0,
          team: isAdmin ? (task.team || 'both') : null
        });
        setSelectedParts([]); // Start with no parts selected
        setError(null);
      } catch (err) {
        console.error('Error setting form data:', err);
        setError('Der opstod en fejl ved indlæsning af opgaven');
      }
    }
  }, [open, task, isEditing, isAdmin]);

  useEffect(() => {
    const fetchPartsForMachine = async () => {
      if (!formData.maskine) {
        setAvailableParts([]);
        return;
      }

      try {
        setPartsLoading(true);
        const response = await api.get('/inventory', { withCredentials: true });
        if (response.data?.success) {
          // Filter parts for selected machine
          const machineParts = response.data.data.filter(part => 
            part.machines?.some(machine => machine.name === formData.maskine)
          );
          setAvailableParts(machineParts);
        }
      } catch (error) {
        console.error('Error fetching parts:', error);
      } finally {
        setPartsLoading(false);
      }
    };

    fetchPartsForMachine();
  }, [formData.maskine]);

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.title?.trim()) {
      newErrors.title = 'Titel er påkrævet';
    }
    if (!formData.afdeling) {
      newErrors.afdeling = 'Afdeling er påkrævet';
    }
    if (!formData.maskine) {
      newErrors.maskine = 'Maskine er påkrævet';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (isSubmitting || !validateForm()) return;
    
    try {
      setIsSubmitting(true);
      console.log('Submitting form data:', formData);

      // Create the data object
      let submitData = {
        afdeling: formData.afdeling,
        maskine: formData.maskine,
        title: formData.title,
        prioritet: formData.prioritet,
        status: formData.status,
        forfaldsdato: formData.forfaldsdato ? formData.forfaldsdato.format('YYYY-MM-DD') : null,
        beskrivelse: formData.beskrivelse,
        tildeltTil: formData.tildeltTil,
        taskType: formData.taskType,
        repetitionInterval: formData.repetitionInterval,
        nextDue: formData.nextDue ? formData.nextDue.format('YYYY-MM-DD') : null,
        is_today_task: formData.is_today_task,
        team: formData.team,
        // Only include usedParts if there are any selected
        usedParts: selectedParts.length > 0 ? selectedParts.map(part => ({
          id: part.id,
          quantity_used: part.quantity_used || 1
        })) : []
      };

      // Convert to FormData if there are files
      if (selectedFiles.length > 0) {
        const formDataObj = new FormData();
        selectedFiles.forEach(file => {
          formDataObj.append('files[]', file);
        });
        
        // Add all other data to FormData
        Object.entries(submitData).forEach(([key, value]) => {
          if (value !== null && value !== undefined) {
            if (key === 'usedParts') {
              formDataObj.append(key, JSON.stringify(value));
            } else {
              formDataObj.append(key, value);
            }
          }
        });
        submitData = formDataObj;
      }

      console.log('Final submit data:', submitData);

      const response = await api({
        method: isEditing ? 'PUT' : 'POST',
        url: isEditing ? `/maintenance/${task.id}` : '/maintenance',
        data: submitData,
        headers: selectedFiles.length > 0 ? {
          'Content-Type': 'multipart/form-data'
        } : {
          'Content-Type': 'application/json'
        }
      });

      console.log('Server response:', response);

      if (response.data?.success) {
        onSubmit(response.data.data);
        onClose();
      } else {
        throw new Error(response.data?.error || 'Failed to save task');
      }
    } catch (error) {
      console.error('Error saving task:', error);
      setError(error.message || 'Der opstod en fejl ved gemning af opgaven');
    } finally {
      setIsSubmitting(false);
      setUploadProgress(0);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    // Clear error when field is filled
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: undefined
      }));
    }

    if (name === 'afdeling' && onDepartmentChange) {
      onDepartmentChange(value);
    }
  };

  const handleDateChange = (name, value) => {
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
  const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/gif', 'video/mp4', 'video/quicktime'];

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    const validFiles = [];
    const errorMessages = [];

    files.forEach(file => {
      if (!ALLOWED_FILE_TYPES.includes(file.type)) {
        errorMessages.push(`${file.name}: Ikke understøttet filtype`);
      } else if (file.size > MAX_FILE_SIZE) {
        errorMessages.push(`${file.name}: Filen er for stor (max 10MB)`);
      } else {
        validFiles.push(file);
      }
    });

    if (errorMessages.length > 0) {
      setError(errorMessages.join('\n'));
    } else {
      setError(null);
      console.log('Valid files selected:', validFiles);
      setSelectedFiles(prev => [...prev, ...validFiles]);
    }
  };

  const handleFileRemove = (index) => {
    setSelectedFiles(prev => prev.filter((_, i) => i !== index));
  };

  // Add status change handler
  const handleStatusChange = (newStatus) => {
    setFormData(prev => ({
      ...prev,
      status: newStatus
    }));
  };

  const handlePartSelect = (part) => {
    setSelectedParts(prev => {
      const exists = prev.find(p => p.id === part.id);
      if (exists) {
        return prev.map(p => 
          p.id === part.id 
            ? { ...p, quantity_used: Math.min(p.quantity_used + 1, p.quantity) }
            : p
        );
      }
      return [...prev, { ...part, quantity_used: 1 }];
    });
  };

  const handlePartRemove = (partId) => {
    setSelectedParts(prev => {
      const updatedParts = prev.filter(p => p.id !== partId);
      // Update formData with the new parts array
      setFormData(prevForm => ({
        ...prevForm,
        usedParts: updatedParts
      }));
      return updatedParts;
    });
  };

  const handleQuantityChange = (partId, newQuantity) => {
    const updatedParts = selectedParts.map(p => 
      p.id === partId 
        ? { ...p, quantity_used: Math.min(Math.max(1, newQuantity), p.quantity) }
        : p
    );
    
    setSelectedParts(updatedParts);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">
            {isEditing ? 'Rediger opgave' : 'Tilføj ny vedligeholdelsesopgave'}
          </Typography>
          {isEditing && (
            <Box display="flex" gap={1}>
              <StatusActions 
                status={formData.status} 
                onStatusChange={handleStatusChange}
              />
            </Box>
          )}
        </Box>
      </DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
          <TextField
            fullWidth
            margin="normal"
            name="title"
            label="Titel"
            value={formData.title || ''}
            onChange={handleChange}
            required
            error={!!errors.title}
            helperText={errors.title}
          />

          <TextField
            fullWidth
            margin="normal"
            name="beskrivelse"
            label="Beskrivelse"
            multiline
            rows={4}
            value={formData.beskrivelse || ''}
            onChange={handleChange}
            sx={{ mb: 2 }}
          />

          <FormControl fullWidth margin="normal" error={!!errors.afdeling}>
            <InputLabel required>Afdeling</InputLabel>
            <Select
              name="afdeling"
              value={formData.afdeling || ''}
              onChange={handleChange}
              label="Afdeling *"
            >
              {departments
                .filter(dept => dept.isVisible)
                .map((dept) => (
                  <MenuItem key={dept.id} value={dept.name}>
                    {dept.name}
                  </MenuItem>
                ))}
            </Select>
            {errors.afdeling && (
              <FormHelperText>{errors.afdeling}</FormHelperText>
            )}
          </FormControl>

          <FormControl 
            fullWidth 
            margin="normal" 
            error={!!errors.maskine}
          >
            <InputLabel>Maskine</InputLabel>
            <Select
              name="maskine"
              value={formData.maskine}
              onChange={handleChange}
              label="Maskine"
              disabled={!formData.afdeling} // Disable if no department is selected
            >
              {machines
                .filter(machine => !formData.afdeling || machine.afdeling === formData.afdeling)
                .map((machine) => (
                  <MenuItem key={machine.id} value={machine.name}>
                    {machine.name}
                  </MenuItem>
                ))}
            </Select>
            {!formData.afdeling && (
              <FormHelperText>Vælg først en afdeling</FormHelperText>
            )}
            {errors.maskine && (
              <FormHelperText error>{errors.maskine}</FormHelperText>
            )}
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Opgavetype</InputLabel>
            <Select
              name="taskType"
              value={formData.taskType || 'maintenance'}
              onChange={handleChange}
              label="Opgavetype"
            >
              <MenuItem value="maintenance">Vedligeholdelse</MenuItem>
              <MenuItem value="cleaning">Rengøring</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Prioritet</InputLabel>
            <Select
              name="prioritet"
              value={formData.prioritet || 'Medium'}
              onChange={handleChange}
              label="Prioritet"
            >
              <MenuItem value="Høj">Høj</MenuItem>
              <MenuItem value="Medium">Medium</MenuItem>
              <MenuItem value="Lav">Lav</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Status</InputLabel>
            <Select
              name="status"
              value={formData.status}
              onChange={handleChange}
              label="Status"
            >
              {STATUS_OPTIONS.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Gentagelse</InputLabel>
            <Select
              name="repetitionInterval"
              value={formData.repetitionInterval}
              onChange={handleChange}
              label="Gentagelse"
            >
              <MenuItem value="none">Ingen gentagelse</MenuItem>
              <MenuItem value="daily">Dagligt</MenuItem>
              <MenuItem value="weekly">Ugentligt</MenuItem>
              <MenuItem value="monthly">Månedligt</MenuItem>
              <MenuItem value="quarterly">Hver tredje måned</MenuItem>
              <MenuItem value="yearly">Årligt</MenuItem>
            </Select>
          </FormControl>

          {formData.repetitionInterval !== 'none' && (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Næste forfaldsdato"
                value={formData.nextDue}
                onChange={(newValue) => handleDateChange('nextDue', newValue)}
                renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
              />
            </LocalizationProvider>
          )}

          <DatePicker
            label="Forfaldsdato"
            value={formData.forfaldsdato ? dayjs(formData.forfaldsdato) : null}
            onChange={(newValue) => handleDateChange('forfaldsdato', newValue)}
            slotProps={{ textField: { fullWidth: true, margin: 'normal' } }}
          />

          <FormControl fullWidth margin="normal">
            <InputLabel>Tildelt til</InputLabel>
            <Select
              name="tildeltTil"
              value={formData.tildeltTil || ''}
              onChange={handleChange}
              label="Tildelt til"
            >
              <MenuItem value="">Ikke tildelt</MenuItem>
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {isAdmin && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(formData.is_today_task)}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    is_today_task: e.target.checked ? 1 : 0,
                    team: e.target.checked ? prev.team : null
                  }))}
                />
              }
              label="Tilføj til dagens opgaver"
              sx={{ mt: 2, mb: 1 }}
            />
          )}

          {isAdmin && formData.is_today_task === 1 && (
            <FormControl fullWidth margin="normal">
              <InputLabel>Hold</InputLabel>
              <Select
                name="team"
                value={formData.team || 'both'}
                onChange={handleChange}
                label="Hold"
              >
                <MenuItem value="both">Begge hold</MenuItem>
                <MenuItem value="day">Daghold</MenuItem>
                <MenuItem value="night">Nathold</MenuItem>
              </Select>
            </FormControl>
          )}
        </Box>

        <Box sx={{ mt: 3 }}>
          <Typography variant="subtitle1" gutterBottom>
            Vedhæft filer
          </Typography>
          <UploadBox>
            <label htmlFor="file-upload">
              <UploadInput
                id="file-upload"
                type="file"
                multiple
                onChange={handleFileSelect}
                accept="image/*,video/*"
              />
              <Box display="flex" flexDirection="column" alignItems="center">
                <CloudUploadIcon sx={{ fontSize: 40, mb: 1 }} />
                <Typography>
                  Klik eller træk filer hertil for at uploade
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  Understøtter billeder og videoer
                </Typography>
              </Box>
            </label>
          </UploadBox>

          {selectedFiles.length > 0 && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2" gutterBottom>
                Valgte filer:
              </Typography>
              {selectedFiles.map((file, index) => (
                <Box
                  key={index}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ mb: 1 }}
                >
                  <Typography variant="body2">{file.name}</Typography>
                  <IconButton 
                    size="small" 
                    onClick={() => handleFileRemove(index)}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
            </Box>
          )}

          {uploadProgress > 0 && uploadProgress < 100 && (
            <Box sx={{ mt: 2 }}>
              <LinearProgress variant="determinate" value={uploadProgress} />
            </Box>
          )}
        </Box>

        <Box sx={{ mt: 3 }}>
          <Typography variant="subtitle1" gutterBottom>
            Anvendte reservedele
          </Typography>
          
          {formData.maskine ? (
            <FormControl fullWidth>
              <Autocomplete
                options={availableParts}
                getOptionLabel={(option) => `${option.name} (Lager: ${option.quantity})`}
                loading={partsLoading}
                onChange={(_, newValue) => {
                  if (newValue) {
                    handlePartSelect(newValue);
                  }
                }}
                value={null} // Always keep the input empty
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Vælg reservedel"
                    placeholder="Søg efter reservedele"
                  />
                )}
                renderOption={(props, option) => {
                  const { key, ...otherProps } = props;
                  return (
                    <li {...otherProps} key={option.id}>
                      <Box>
                        <Typography variant="body1">
                          {option.name}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          Lager: {option.quantity} {option.partNumber && `- ${option.partNumber}`}
                        </Typography>
                      </Box>
                    </li>
                  );
                }}
                noOptionsText="Ingen reservedele tilgængelige"
              />
            </FormControl>
          ) : (
            <Typography color="textSecondary">
              Vælg først en maskine for at se tilgængelige reservedele
            </Typography>
          )}

          {selectedParts.length > 0 && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2" gutterBottom>
                Valgte dele:
              </Typography>
              {selectedParts.map((part) => (
                <Box 
                  key={`selected-${part.id}`}
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 2, 
                    mb: 1,
                    p: 1,
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: 1
                  }}
                >
                  <Typography variant="body2" sx={{ flex: 1 }}>
                    {part.name}
                  </Typography>
                  <TextField
                    type="number"
                    size="small"
                    value={part.quantity_used}
                    onChange={(e) => handleQuantityChange(part.id, parseInt(e.target.value))}
                    InputProps={{
                      inputProps: { 
                        min: 1,
                        max: part.quantity
                      }
                    }}
                    sx={{ width: 100 }}
                  />
                  <IconButton 
                    size="small" 
                    color="error"
                    onClick={() => handlePartRemove(part.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annuller</Button>
        <Button 
          onClick={handleSubmit} 
          variant="contained"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Sender...' : (isEditing ? 'Opdater' : 'Tilføj')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TaskDialog; 