import React, { useState, useEffect } from 'react';
import {
  Paper, Typography, TextField, Button, List, ListItem, ListItemText, ListItemSecondaryAction,
  IconButton, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem,
  Box, CircularProgress, Alert
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import api from '../../utils/apiUtils';

const MachineManagement = () => {
  const [machines, setMachines] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentMachine, setCurrentMachine] = useState({ name: '', afdeling: '' });
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedDepartment, setSelectedDepartment] = useState('all');

  useEffect(() => {
    fetchMachines();
    fetchDepartments();
  }, []);

  const fetchDepartments = async () => {
    try {
      const response = await api.get('/departments.php');
      if (response.data?.success) {
        setDepartments(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching departments:', error);
    }
  };

  const fetchMachines = async () => {
    try {
      setLoading(true);
      setError('');
      console.log('Fetching machines...');
      const response = await api.get('/machines.php');
      console.log('Machines response:', response);
      
      if (response.data?.success) {
        setMachines(response.data.data);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Error fetching machines:', error);
      setError('Der opstod en fejl ved hentning af maskiner');
      setMachines([]);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDialog = (machine = null) => {
    if (machine) {
      setCurrentMachine(machine);
      setIsEditing(true);
    } else {
      setCurrentMachine({ name: '', afdeling: '' });
      setIsEditing(false);
    }
    setOpenDialog(true);
    setError('');
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentMachine({ name: '', afdeling: '' });
    setIsEditing(false);
    setError('');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentMachine(prev => ({ ...prev, [name]: value }));
    setError('');
  };

  const handleSaveMachine = async () => {
    if (!currentMachine.name.trim()) {
      setError('Maskinens navn må ikke være tomt');
      return;
    }
    if (!currentMachine.afdeling) {
      setError('Vælg venligst en afdeling');
      return;
    }

    try {
      if (isEditing) {
        await api.put(`/machines.php/${currentMachine.id}`, currentMachine);
      } else {
        await api.post('/machines.php', currentMachine);
      }
      await fetchMachines();
      handleCloseDialog();
    } catch (error) {
      console.error('Error saving machine:', error);
      setError('Der opstod en fejl ved gemning af maskinen');
    }
  };

  const handleDeleteMachine = async (id) => {
    if (window.confirm('Er du sikker på, at du vil slette denne maskine?')) {
      try {
        await api.delete(`/machines.php/${id}`);
        await fetchMachines();
      } catch (error) {
        console.error('Error deleting machine:', error);
        setError('Der opstod en fejl ved sletning af maskinen');
      }
    }
  };

  const filteredMachines = machines.filter(machine => 
    selectedDepartment === 'all' || machine.afdeling === selectedDepartment
  );

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h5" gutterBottom>Maskinstyring</Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Filter efter afdeling</InputLabel>
          <Select
            value={selectedDepartment}
            onChange={(e) => setSelectedDepartment(e.target.value)}
            label="Filter efter afdeling"
          >
            <MenuItem value="all">Alle afdelinger</MenuItem>
            {departments.map((dept) => (
              <MenuItem key={dept.id} value={dept.name}>
                {dept.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button 
          startIcon={<AddIcon />} 
          variant="contained" 
          onClick={() => handleOpenDialog()} 
        >
          Tilføj Maskine
        </Button>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <List>
        {filteredMachines.map((machine) => (
          <ListItem key={machine.id}>
            <ListItemText 
              primary={machine.name} 
              secondary={`Afdeling: ${machine.afdeling}`} 
            />
            <ListItemSecondaryAction>
              <IconButton 
                edge="end" 
                aria-label="edit" 
                onClick={() => handleOpenDialog(machine)}
                sx={{ mr: 1 }}
              >
                <EditIcon />
              </IconButton>
              <IconButton 
                edge="end" 
                aria-label="delete" 
                onClick={() => handleDeleteMachine(machine.id)}
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {isEditing ? 'Rediger Maskine' : 'Tilføj Maskine'}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Maskinnavn"
            type="text"
            fullWidth
            value={currentMachine.name}
            onChange={handleInputChange}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Afdeling</InputLabel>
            <Select
              name="afdeling"
              value={currentMachine.afdeling}
              onChange={handleInputChange}
              label="Afdeling"
            >
              {departments.map((dept) => (
                <MenuItem key={dept.id} value={dept.name}>
                  {dept.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {error && (
            <Typography color="error" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annuller</Button>
          <Button onClick={handleSaveMachine} variant="contained">
            {isEditing ? 'Opdater' : 'Tilføj'}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default MachineManagement;
