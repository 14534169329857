import React, { useState, useEffect } from 'react';
import {
  Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Select, MenuItem,
  FormControl, InputLabel, Box, CircularProgress, IconButton, Tooltip
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import api from '../utils/apiUtils';
import dayjs from 'dayjs';

const initialPalletTypes = [
  { id: 1, name: 'EUR-palle' },
  { id: 2, name: 'Halvpalle' },
];

const companies = ['DAO/BK', 'FK', 'Intern', 'Andet'];

const commonButtonStyle = {
  textTransform: 'none',
  fontWeight: 500,
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none'
  }
};

const PalletAccountingComponent = ({ isAdmin }) => {
  const [palletTypes, setPalletTypes] = useState(initialPalletTypes);
  const [palletEntries, setPalletEntries] = useState([]);
  const [totals, setTotals] = useState({});
  const [companyBalances, setCompanyBalances] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [newEntry, setNewEntry] = useState({
    palletTypeId: '',
    date: dayjs().format('YYYY-MM-DD'),
    incoming: 0,
    outgoing: 0,
    note: '',
    company: '',
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [entryToDelete, setEntryToDelete] = useState(null);

  useEffect(() => {
    fetchPalletEntries();
  }, []);

  const fetchPalletEntries = async () => {
    setLoading(true);
    try {
      const response = await api.get('/pallets.php');
      console.log('Pallet data response:', response.data);
      
      if (response.data?.success) {
        const { entries = [], types = [], totals = {}, companyBalances = {} } = response.data.data;
        
        // Set pallet types from response or use initial types as fallback
        setPalletTypes(types.length > 0 ? types : initialPalletTypes);

        // Process entries
        const processedEntries = entries.map(entry => ({
          ...entry,
          palletTypeId: entry.palletTypeId,
          company: entry.company,
          note: entry.note
        }));

        setPalletEntries(processedEntries);
        setTotals(totals);
        setCompanyBalances(companyBalances);

        console.log('Received totals:', totals);
        console.log('Received balances:', companyBalances);
      }
    } catch (error) {
      console.error('Error fetching pallet entries:', error);
      setError('Der opstod en fejl ved hentning af palledata. Prøv venligst igen senere.');
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewEntry(prev => ({ ...prev, [name]: value }));
  };

  const handleAddEntry = async () => {
    if (!newEntry.palletTypeId || !newEntry.date) {
      setError('Palletype og dato er påkrævet');
      return;
    }

    try {
      const entryData = {
        palletTypeId: parseInt(newEntry.palletTypeId),
        date: dayjs(newEntry.date).format('YYYY-MM-DD'),
        incoming: parseInt(newEntry.incoming) || 0,
        outgoing: parseInt(newEntry.outgoing) || 0,
        company: newEntry.company.trim(),
        note: newEntry.note.trim()
      };

      console.log('Sending entry data:', entryData);

      const response = await api.post('/pallets.php', entryData);

      if (response.data?.success) {
        await fetchPalletEntries();
        handleCloseDialog();
        setNewEntry({
          palletTypeId: '',
          date: dayjs(),
          incoming: 0,
          outgoing: 0,
          note: '',
          company: ''
        });
      } else {
        throw new Error(response.data?.error || 'Failed to add pallet entry');
      }
    } catch (error) {
      console.error('Error adding pallet entry:', error);
      setError(error.response?.data?.error || 'Der opstod en fejl ved tilføjelse af palledata');
    }
  };

  const handleDeleteEntry = async () => {
    try {
      await api.delete(`/pallets.php/${entryToDelete.id}`, { withCredentials: true });
      fetchPalletEntries();
      setDeleteDialogOpen(false);
      setEntryToDelete(null);
    } catch (error) {
      console.error('Error deleting pallet entry:', error);
      setError('Der opstod en fejl ved sletning af palledata. Prøv venligst igen.');
    }
  };

  const openDeleteDialog = (entry) => {
    setEntryToDelete(entry);
    setDeleteDialogOpen(true);
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h5" gutterBottom>
        Palleregnskab
      </Typography>

      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handleOpenDialog}
        sx={{ ...commonButtonStyle, mb: 2 }}
      >
        Tilføj ny indtastning
      </Button>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Palletype</TableCell>
              <TableCell align="right">Indgående (Modtaget)</TableCell>
              <TableCell align="right">Udgående (Sendt)</TableCell>
              <TableCell align="right">Balance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {palletTypes.map((palletType) => (
              <TableRow key={palletType.id}>
                <TableCell component="th" scope="row">
                  {palletType.name}
                </TableCell>
                <TableCell align="right">
                  {totals[palletType.id]?.total_incoming || 0}
                </TableCell>
                <TableCell align="right">
                  {totals[palletType.id]?.total_outgoing || 0}
                </TableCell>
                <TableCell align="right">
                  {totals[palletType.id]?.balance || 0}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
        Pallesaldo med DAO/BK og FK
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Firma</TableCell>
              <TableCell align="right">EUR-paller Saldo</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(companyBalances).map(([company, balance]) => (
              <TableRow key={company}>
                <TableCell>{company}</TableCell>
                <TableCell align="right">{balance}</TableCell>
                <TableCell>
                  {balance > 0 ? (
                    <Typography color="success.main">{company} skylder os {balance} paller</Typography>
                  ) : balance < 0 ? (
                    <Typography color="error.main">Vi skylder {company} {Math.abs(balance)} paller</Typography>
                  ) : (
                    <Typography color="info.main">Ingen udestående</Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
        Seneste indtastninger
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Dato</TableCell>
              <TableCell>Palletype</TableCell>
              <TableCell align="right">Indgående (Modtaget)</TableCell>
              <TableCell align="right">Udgående (Sendt)</TableCell>
              <TableCell>Firma</TableCell>
              <TableCell>Note</TableCell>
              {isAdmin && <TableCell>Handlinger</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {palletEntries.map((entry) => (
              <TableRow key={entry.id}>
                <TableCell>{dayjs(entry.date).format('DD/MM/YYYY')}</TableCell>
                <TableCell>{palletTypes.find(pt => pt.id === entry.palletTypeId)?.name}</TableCell>
                <TableCell align="right">{entry.incoming}</TableCell>
                <TableCell align="right">{entry.outgoing}</TableCell>
                <TableCell>{entry.company}</TableCell>
                <TableCell>{entry.note}</TableCell>
                {isAdmin && (
                  <TableCell>
                    <Tooltip title="Slet indtastning">
                      <IconButton onClick={() => openDeleteDialog(entry)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Tilføj ny indtastning</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel>Palletype</InputLabel>
            <Select
              name="palletTypeId"
              value={newEntry.palletTypeId}
              onChange={handleInputChange}
              label="Palletype"
            >
              {palletTypes.map((palletType) => (
                <MenuItem key={palletType.id} value={palletType.id}>{palletType.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            name="date"
            label="Dato"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={newEntry.date}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="incoming"
            label="Indgående (Modtaget)"
            type="number"
            fullWidth
            value={newEntry.incoming}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="outgoing"
            label="Udgående (Sendt)"
            type="number"
            fullWidth
            value={newEntry.outgoing}
            onChange={handleInputChange}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Firma</InputLabel>
            <Select
              name="company"
              value={newEntry.company}
              onChange={handleInputChange}
              label="Firma"
            >
              {companies.map((company) => (
                <MenuItem key={company} value={company}>{company}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            name="note"
            label="Note"
            fullWidth
            value={newEntry.note}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annuller</Button>
          <Button onClick={handleAddEntry} variant="contained">Tilføj</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Bekræft sletning</DialogTitle>
        <DialogContent>
          <Typography>Er du sikker på, at du vil slette denne indtastning?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Annuller</Button>
          <Button onClick={handleDeleteEntry} color="error">Slet</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default PalletAccountingComponent;
