import React, { useState, useCallback } from 'react';
import { 
  Box, Typography, Select, MenuItem, TextField, Button, 
  Paper, Grid, FormControl, InputLabel, IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const NEWSPAPER_FORMATS = {
  broadsheet: { width: 390, height: 560 },
  tabloid: { width: 280, height: 400 },
  trimmetProdukt: { width: 260, height: 360 }
};

const NewspaperWeightCalculator = () => {
  const [mainPaper, setMainPaper] = useState({
    format: 'broadsheet',
    pages: 32,
    paperWeight: 45,
    customWidth: 390,
    customHeight: 560,
    customWeightValue: 45
  });
  const [inserts, setInserts] = useState([]);
  const [results, setResults] = useState(null);
  const [pagesInput, setPagesInput] = useState({
    main: '32',
    inserts: {}
  });

  const addInsert = useCallback(() => {
    setInserts(prev => [...prev, {
      id: Date.now(),
      format: 'broadsheet',
      pages: 4,
      paperWeight: 45,
      customWidth: 390,
      customHeight: 560,
      customWeightValue: 45
    }]);
  }, []);

  const removeInsert = useCallback((id) => {
    setInserts(prev => prev.filter(insert => insert.id !== id));
  }, []);

  const updateMainPaper = useCallback((_, field, value) => {
    setMainPaper(prev => {
      const updatedPaper = { ...prev };
      if (field === 'pages') {
        // Only update if the value is a valid number
        const numValue = parseInt(value, 10);
        if (!isNaN(numValue) && numValue > 0) {
          updatedPaper[field] = numValue;
        }
      } else {
        updatedPaper[field] = value;
      }
      if (field === 'format' && value === 'custom') {
        updatedPaper.customWidth = NEWSPAPER_FORMATS.broadsheet.width;
        updatedPaper.customHeight = NEWSPAPER_FORMATS.broadsheet.height;
      }
      if (field === 'paperWeight' && value === 'custom') {
        updatedPaper.customWeightValue = 45;
      }
      return updatedPaper;
    });

    // Update pagesInput state
    if (field === 'pages') {
      setPagesInput(prev => ({ ...prev, main: value }));
    }
  }, []);

  const updateInsert = useCallback((id, field, value) => {
    setInserts(prev => prev.map(insert => {
      if (insert.id === id) {
        const updatedInsert = { ...insert };
        if (field === 'pages') {
          // Only update if the value is a valid number
          const numValue = parseInt(value, 10);
          if (!isNaN(numValue) && numValue > 0) {
            updatedInsert[field] = numValue;
          }
        } else {
          updatedInsert[field] = value;
        }
        return updatedInsert;
      }
      return insert;
    }));

    // Update pagesInput state for inserts
    if (field === 'pages') {
      setPagesInput(prev => ({
        ...prev,
        inserts: { ...prev.inserts, [id]: value }
      }));
    }
  }, []);

  const calculatePaperWeight = useCallback((data) => {
    let width, height;
    
    if (data.format === 'custom') {
      width = data.customWidth;
      height = data.customHeight;
    } else {
      width = NEWSPAPER_FORMATS[data.format].width;
      height = NEWSPAPER_FORMATS[data.format].height;
    }

    const widthM = width / 1000;
    const heightM = height / 1000;
    const areaPerSheet = widthM * heightM;
    const totalArea = areaPerSheet * (data.pages / 2);
    const weight = data.paperWeight === 'custom' ? data.customWeightValue : data.paperWeight;
    
    return totalArea * weight;
  }, []);

  const calculateWeight = useCallback(() => {
    const mainPaperWeight = calculatePaperWeight(mainPaper);

    let totalInsertWeight = 0;
    inserts.forEach(insert => {
      totalInsertWeight += calculatePaperWeight(insert);
    });

    const totalWeight = mainPaperWeight + totalInsertWeight;

    setResults({
      mainPaperGrams: mainPaperWeight.toFixed(2),
      mainPaperKg: (mainPaperWeight / 1000).toFixed(3),
      insertsGrams: totalInsertWeight.toFixed(2),
      insertsKg: (totalInsertWeight / 1000).toFixed(3),
      totalGrams: totalWeight.toFixed(2),
      totalKg: (totalWeight / 1000).toFixed(3)
    });
  }, [mainPaper, inserts, calculatePaperWeight]);

  const renderPaperForm = (data, updateFunction, isInsert = false, insertId = null) => (
    <>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Papirformat</InputLabel>
        <Select 
          value={data.format} 
          onChange={(e) => updateFunction(isInsert ? insertId : null, 'format', e.target.value)} 
          label="Papirformat"
        >
          <MenuItem value="broadsheet">Broadsheet (390 × 560 mm)</MenuItem>
          <MenuItem value="tabloid">Tabloid (280 × 400 mm)</MenuItem>
          <MenuItem value="trimmetProdukt">Trimmet produkt (260 × 360 mm)</MenuItem>
          <MenuItem value="custom">Brugerdefineret størrelse</MenuItem>
        </Select>
      </FormControl>

      {data.format === 'custom' && (
        <Box sx={{ mb: 2 }}>
          <TextField
            label="Bredde (mm)"
            type="number"
            value={data.customWidth}
            onChange={(e) => updateFunction(isInsert ? insertId : null, 'customWidth', Number(e.target.value))}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Højde (mm)"
            type="number"
            value={data.customHeight}
            onChange={(e) => updateFunction(isInsert ? insertId : null, 'customHeight', Number(e.target.value))}
            fullWidth
          />
        </Box>
      )}

      <TextField
        label="Antal sider"
        type="number"
        value={isInsert ? pagesInput.inserts[insertId] || '' : pagesInput.main}
        onChange={(e) => {
          const value = e.target.value;
          updateFunction(isInsert ? insertId : null, 'pages', value);
        }}
        fullWidth
        sx={{ mb: 2 }}
        inputProps={{ min: 1 }}
      />

      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Papirvægt</InputLabel>
        <Select 
          value={data.paperWeight} 
          onChange={(e) => updateFunction(isInsert ? insertId : null, 'paperWeight', e.target.value)} 
          label="Papirvægt"
        >
          <MenuItem value={40}>40 g</MenuItem>
          <MenuItem value={45}>45 g</MenuItem>
          <MenuItem value={52}>52 g</MenuItem>
          <MenuItem value={60}>60 g</MenuItem>
          <MenuItem value={70}>70 g</MenuItem>
          <MenuItem value={80}>80 g</MenuItem>
          <MenuItem value="custom">Brugerdefineret vægt</MenuItem>
        </Select>
      </FormControl>

      {data.paperWeight === 'custom' && (
        <TextField
          label="Brugerdefineret vægt (g)"
          type="number"
          value={data.customWeightValue}
          onChange={(e) => updateFunction(isInsert ? insertId : null, 'customWeightValue', Number(e.target.value))}
          fullWidth
          sx={{ mb: 2 }}
        />
      )}
    </>
  );

  return (
    <Box sx={{ maxWidth: 800, margin: '0 auto', padding: 3 }}>
      <Typography variant="h4" gutterBottom>Avis Vægtberegner</Typography>
      
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="h5" gutterBottom>Hovedavis</Typography>
        {renderPaperForm(mainPaper, updateMainPaper)}
      </Paper>

      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="h5" gutterBottom>Indstik</Typography>
        <Button onClick={addInsert} variant="contained" sx={{ mb: 2 }}>Tilføj Indstik</Button>
        
        {inserts.map((insert) => (
          <Box key={insert.id} sx={{ mb: 2, p: 2, border: '1px solid #ddd', borderRadius: 1 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography variant="h6">Indstik {inserts.indexOf(insert) + 1}</Typography>
              <IconButton onClick={() => removeInsert(insert.id)} color="error">
                <DeleteIcon />
              </IconButton>
            </Box>
            {renderPaperForm(insert, updateInsert, true, insert.id)}
          </Box>
        ))}
      </Paper>

      <Button onClick={calculateWeight} variant="contained" color="primary" fullWidth>Beregn samlet vægt</Button>

      {results && (
        <Paper sx={{ p: 3, mt: 3 }}>
          <Typography variant="h5" gutterBottom>Resultater</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography>Hovedavis vægt: {results.mainPaperGrams} g / {results.mainPaperKg} kg</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>Indstik samlet vægt: {results.insertsGrams} g / {results.insertsKg} kg</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Total vægt: {results.totalGrams} g / {results.totalKg} kg</Typography>
            </Grid>
          </Grid>
          <Box mt={2}>
            <Typography><strong>Detaljer:</strong></Typography>
            <Typography>Hovedavis: {mainPaper.pages} sider, {mainPaper.paperWeight === 'custom' ? mainPaper.customWeightValue : mainPaper.paperWeight} g</Typography>
            {inserts.length > 0 && <Typography>Antal indstik: {inserts.length}</Typography>}
          </Box>
        </Paper>
      )}
    </Box>
  );
};

export default NewspaperWeightCalculator;
