import React, { useState, useContext, useEffect } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { 
  Box, CssBaseline, Drawer as MuiDrawer, AppBar, Toolbar, List, Typography, 
  IconButton, ListItem, ListItemIcon, ListItemText, Collapse,
  Divider, Badge, Menu, MenuItem, Switch, FormControlLabel,
  Tabs, Tab, Paper, CircularProgress, Button
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LogoutIcon from '@mui/icons-material/Logout';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { useAuth } from '../contexts/AuthContext';
import DashboardHome from './DashboardHome';
import ContactOverviewComponent from './contacts/ContactOverviewComponent';
import MaintenanceComponent from './maintenance/MaintenanceComponent';
import PalletAccountingComponent from './PalletAccountingComponent';
import AdminComponent from './AdminComponent';
import ShiftPlanningComponent from './ShiftPlanningComponent';
import PdfSplitterComponent from './PdfSplitterComponent';
import NewspaperWeightCalculator from './NewspaperWeightCalculator';
import DistributionDataInputComponent from './DistributionDataInputComponent';
import { useTheme } from '@mui/material/styles';
import { ColorModeContext } from '../contexts/ColorModeContext';
import NotificationBell from './NotificationBell';
import { styled } from '@mui/material/styles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ContactsIcon from '@mui/icons-material/Contacts';
import BuildIcon from '@mui/icons-material/Build';
import CalculateIcon from '@mui/icons-material/Calculate';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ViewListIcon from '@mui/icons-material/ViewList';
import EventIcon from '@mui/icons-material/Event';
import HandymanIcon from '@mui/icons-material/Handyman';

const Dashboard = () => {
  const { user, logout } = useAuth();
  const isAdmin = Boolean(user?.isAdmin);
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [toolsOpen, setToolsOpen] = useState(false);
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        if (!user) {
          navigate('/login');
          return;
        }

        // Add a small delay to prevent flash
        await new Promise(resolve => setTimeout(resolve, 100));
        setIsAuthenticated(true);
        setIsLoading(false);
      } catch (error) {
        console.error('Auth check failed:', error);
        navigate('/login');
      }
    };

    checkAuth();
  }, [user, navigate]);

  if (isLoading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh' 
      }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!isAuthenticated) {
    return null;
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleToolsClick = () => {
    setToolsOpen(!toolsOpen);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login', { replace: true });
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const menuItems = [
    { 
      id: 'dashboard', 
      text: 'Dashboard', 
      icon: <DashboardIcon />, 
      path: '/' 
    },
    { 
      id: 'shifts', 
      text: 'Vagtplan',
      icon: <EventIcon />, 
      path: '/vagtplanlaegning' 
    },
    { 
      id: 'maintenance', 
      text: 'Vedligeholdelse', 
      icon: <BuildIcon />, 
      path: '/vedligeholdelse' 
    },
    { 
      id: 'tools', 
      text: 'Værktøj', 
      icon: <HandymanIcon />,
      subItems: [
        { 
          id: 'calculator', 
          text: 'Avis Vægtberegner', 
          icon: <CalculateIcon />, 
          path: '/avis-vaegtberegner' 
        },
        { 
          id: 'distribution', 
          text: 'Distributions Data', 
          icon: <DataUsageIcon />, 
          path: '/distributions-data' 
        },
        { 
          id: 'pdf-splitter', 
          text: 'PDF Splitter', 
          icon: <PictureAsPdfIcon />, 
          path: '/pdf-splitter' 
        }
      ]
    },
    { 
      id: 'pallets', 
      text: 'Palleregnskab', 
      icon: <ViewListIcon />, 
      path: '/palleregnskab' 
    },
    { 
      id: 'contacts', 
      text: 'Kontakter', 
      icon: <ContactsIcon />, 
      path: '/kontakter' 
    }
  ];

  const handleMenuClick = (path) => {
    navigate(path);
    setMobileOpen(false);
  };

  const StyledTabs = styled(Tabs)(({ theme }) => ({
    minHeight: 48,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    '& .MuiTabs-indicator': {
      display: 'none'
    }
  }));

  const StyledTab = styled(Tab)(({ theme }) => ({
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '0.9rem',
    minHeight: 48,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    borderRight: `1px solid ${theme.palette.divider}`,
    '&.Mui-selected': {
      backgroundColor: theme.palette.action.selected,
      color: theme.palette.text.primary,
      fontWeight: 600
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    },
    '&:last-child': {
      borderRight: 'none'
    }
  }));

  const Drawer = styled(MuiDrawer)(({ theme }) => ({
    width: 240,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: 240,
      boxSizing: 'border-box',
      backgroundColor: theme.palette.background.paper,
      borderRight: `1px solid ${theme.palette.divider}`
    }
  }));

  const StyledListItem = styled(ListItem)(({ theme }) => ({
    padding: theme.spacing(1, 2),
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      borderRadius: theme.shape.borderRadius
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.action.selected,
      borderRadius: theme.shape.borderRadius,
      '&:hover': {
        backgroundColor: theme.palette.action.selected
      }
    }
  }));

  const commonButtonStyle = {
    textTransform: 'none',
    fontWeight: 500,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none'
    }
  };

  const drawer = (
    <div>
      <Toolbar />
      <List>
        {menuItems.map((item) => (
          item.subItems ? (
            <React.Fragment key={item.id}>
              <StyledListItem onClick={handleToolsClick} sx={{ cursor: 'pointer' }}>
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} />
                {toolsOpen ? <ExpandLess /> : <ExpandMore />}
              </StyledListItem>
              <Collapse in={toolsOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.subItems.map((subItem) => (
                    <StyledListItem 
                      key={subItem.id}
                      onClick={() => handleMenuClick(subItem.path)}
                      sx={{ pl: 4, cursor: 'pointer' }}
                    >
                      <ListItemIcon>
                        {subItem.icon}
                      </ListItemIcon>
                      <ListItemText primary={subItem.text} />
                    </StyledListItem>
                  ))}
                </List>
              </Collapse>
            </React.Fragment>
          ) : (
            <StyledListItem 
              key={item.id}
              onClick={() => handleMenuClick(item.path)}
              sx={{ cursor: 'pointer' }}
            >
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </StyledListItem>
          )
        ))}
      </List>
      <Divider />
      <List>
        {isAdmin && (
          <StyledListItem onClick={() => handleMenuClick('/admin')} sx={{ cursor: 'pointer' }}>
            <ListItemIcon>
              <AdminPanelSettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Admin" />
          </StyledListItem>
        )}
        <StyledListItem onClick={handleLogout} sx={{ cursor: 'pointer' }}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Log ud" />
        </StyledListItem>
      </List>
    </div>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            CMMS Dashboard
          </Typography>
          
          <FormControlLabel
            control={
              <Switch
                checked={theme.palette.mode === 'dark'}
                onChange={colorMode.toggleColorMode}
                icon={<DarkModeIcon />}
                checkedIcon={<DarkModeIcon />}
              />
            }
            label=""
            sx={{ mr: 2, color: 'white' }}
          />

          <NotificationBell userId={user?.id} />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: 240,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: 240, boxSizing: 'border-box' },
        }}
      >
        {drawer}
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Routes>
          <Route path="/" element={<DashboardHome />} />
          <Route path="/dashboard" element={<DashboardHome />} />
          <Route path="/kontakter" element={<ContactOverviewComponent />} />
          <Route path="/vedligeholdelse/*" element={<MaintenanceComponent />} />
          <Route path="/avis-vaegtberegner" element={<NewspaperWeightCalculator />} />
          <Route path="/distributions-data" element={<DistributionDataInputComponent />} />
          <Route path="/pdf-splitter" element={<PdfSplitterComponent />} />
          <Route path="/palleregnskab" element={<PalletAccountingComponent />} />
          <Route path="/vagtplanlaegning" element={<ShiftPlanningComponent />} />
          <Route path="/admin" element={<AdminComponent />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default Dashboard;
