import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button, TextField, Box, Typography, IconButton,
  Divider, Paper, Chip, Tooltip
} from '@mui/material';
import { 
  Delete as DeleteIcon,
  Edit as EditIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Image as ImageIcon,
  VideoFile as VideoFileIcon,
  PlayArrow as StartIcon, 
  Stop as StopIcon, 
  CheckCircle as CompleteIcon
} from '@mui/icons-material';
import config from '../../config';
import api from '../../utils/apiUtils';

const getFileUrl = (filePath) => {
  if (!filePath) return '';
  // Remove 'api/' from the path if it exists
  const cleanPath = filePath.replace(/^api\//, '').replace(/^\/+/, '');
  // Combine with base URL
  return `${config.apiUrl.replace('/api', '')}/${cleanPath}`;
};

const TaskDetailsDialog = ({
  open,
  onClose,
  task,
  onUpdate,
  onEdit,
  users
}) => {
  const [taskDetails, setTaskDetails] = useState(null);
  const [completionDialog, setCompletionDialog] = useState(false);
  const [completionComment, setCompletionComment] = useState('');
  const [error, setError] = useState('');

  const fetchTaskDetails = async () => {
    if (task?.id) {
      try {
        const response = await api.get(`/maintenance.php/${task.id}`);
        if (response.data?.success) {
          setTaskDetails(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching task details:', error);
      }
    }
  };

  useEffect(() => {
    if (open && task?.id) {
      fetchTaskDetails();
    }
  }, [open, task?.id]);

  const translateTaskType = (type) => {
    switch (type) {
      case 'cleaning':
        return 'Rengøring';
      case 'maintenance':
        return 'Vedligeholdelse';
      default:
        return type;
    }
  };

  const translateRepetitionInterval = (interval) => {
    switch (interval) {
      case 'none':
        return 'Ingen';
      case 'daily':
        return 'Dagligt';
      case 'weekly':
        return 'Ugentligt';
      case 'monthly':
        return 'Månedligt';
      case 'quarterly':
        return 'Kvartalsvis';
      case 'yearly':
        return 'Årligt';
      default:
        return interval;
    }
  };

  const handleStatusChange = async (newStatus) => {
    try {
      const response = await api({
        method: 'PUT',
        url: `/maintenance/${task.id}`,
        data: {
          ...taskDetails,
          status: newStatus,
          // Keep existing parts for status changes
          usedParts: taskDetails.usedParts || [],
          // Add flag to indicate this is not a completion
          isCompletion: false
        }
      });

      if (response.data?.success) {
        onUpdate(response.data.data);
      }
    } catch (error) {
      console.error('Error updating task status:', error);
      setError('Der opstod en fejl ved opdatering af status');
    }
  };

  const handleTaskComplete = async () => {
    try {
      const response = await api({
        method: 'PUT',
        url: `/maintenance/${task.id}`,
        data: {
          ...taskDetails,
          status: 'Afsluttet',
          completionNote: completionComment,
          // Explicitly set usedParts to empty array for the new task
          usedParts: taskDetails.usedParts || [],
          // Add a flag to indicate this is a completion action
          isCompletion: true
        }
      });

      if (response.data?.success) {
        onUpdate(response.data.data);
        setCompletionComment('');
        setCompletionDialog(false);
      }
    } catch (error) {
      console.error('Error completing task:', error);
      setError('Der opstod en fejl ved afslutning af opgaven');
    }
  };

  const StatusActions = () => {
    switch (taskDetails?.status || task.status) {
      case 'Afventer':
        return (
          <Tooltip title="Start opgave">
            <IconButton
              onClick={() => handleStatusChange('I gang')}
              color="primary"
              size="small"
            >
              <StartIcon />
            </IconButton>
          </Tooltip>
        );
      case 'I gang':
        return (
          <>
            <Tooltip title="Sæt på pause">
              <IconButton
                onClick={() => handleStatusChange('Afventer')}
                color="warning"
                size="small"
              >
                <StopIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Afslut opgave">
              <IconButton
                onClick={() => setCompletionDialog(true)}
                color="success"
                size="small"
              >
                <CompleteIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      case 'Afsluttet':
        return (
          <Tooltip title="Genåbn opgave">
            <IconButton
              onClick={() => handleStatusChange('Afventer')}
              color="primary"
              size="small"
            >
              <StartIcon />
            </IconButton>
          </Tooltip>
        );
      default:
        return null;
    }
  };

  const handleFileDelete = async (fileId) => {
    if (window.confirm('Er du sikker på, at du vil slette denne fil?')) {
      try {
        console.log('Attempting to delete file with ID:', fileId);
        
        const response = await api.delete(`/maintenance.php/file/${fileId}`);
        
        console.log('Raw response:', response);
        
        if (response.data?.success) {
          setTaskDetails(prevDetails => ({
            ...prevDetails,
            files: prevDetails.files.filter(file => file.id !== fileId)
          }));

          alert('Filen blev slettet');

          const refreshResponse = await api.get(`/maintenance.php/${task.id}`);

          if (refreshResponse.data?.success) {
            setTaskDetails(refreshResponse.data.data);
          }
        } else {
          console.error('Unexpected response:', response.data);
          throw new Error('Failed to delete file');
        }
      } catch (error) {
        console.error('Error deleting file:', error);
        console.error('Error details:', error.response?.data);
        alert('Der opstod en fejl ved sletning af filen');
      }
    }
  };

  if (!open || !task) return null;

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography variant="h6">{taskDetails?.title || task.title}</Typography>
              <Box display="flex" gap={1} mt={1}>
                <StatusActions />
              </Box>
            </Box>
            <IconButton onClick={() => onEdit(taskDetails || task)} color="primary">
              <EditIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 3 }}>
            <Paper elevation={0} sx={{ p: 2, backgroundColor: 'background.default' }}>
              <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                Detaljer
              </Typography>
              <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
                <Box>
                  <Typography variant="body2" color="textSecondary">Afdeling</Typography>
                  <Typography variant="body1">{taskDetails?.afdeling || task.afdeling}</Typography>
                </Box>
                <Box>
                  <Typography variant="body2" color="textSecondary">Maskine</Typography>
                  <Typography variant="body1">{taskDetails?.maskine || task.maskine}</Typography>
                </Box>
                <Box>
                  <Typography variant="body2" color="textSecondary">Status</Typography>
                  <Chip 
                    label={taskDetails?.status || task.status}
                    size="small"
                    color={
                      (taskDetails?.status || task.status) === 'Afsluttet' ? 'success' :
                      (taskDetails?.status || task.status) === 'I gang' ? 'primary' :
                      'warning'
                    }
                    sx={{ mt: 0.5 }}
                  />
                </Box>
                <Box>
                  <Typography variant="body2" color="textSecondary">Prioritet</Typography>
                  <Typography variant="body1">{taskDetails?.prioritet || task.prioritet}</Typography>
                </Box>
                <Box>
                  <Typography variant="body2" color="textSecondary">Opgavetype</Typography>
                  <Typography variant="body1">
                    {translateTaskType(taskDetails?.taskType || task.taskType)}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2" color="textSecondary">Tildelt til</Typography>
                  <Typography variant="body1">
                    {taskDetails?.assigned_user_name || task.assigned_user_name || 'Ikke tildelt'}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2" color="textSecondary">Forfaldsdato</Typography>
                  <Typography variant="body1">
                    {taskDetails?.forfaldsdato ? new Date(taskDetails.forfaldsdato).toLocaleDateString('da-DK') : task.forfaldsdato ? new Date(task.forfaldsdato).toLocaleDateString('da-DK') : '-'}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2" color="textSecondary">Gentagelse</Typography>
                  <Typography variant="body1">
                    {translateRepetitionInterval(taskDetails?.repetitionInterval || task.repetitionInterval)}
                  </Typography>
                </Box>
              </Box>
              {(taskDetails?.beskrivelse || task.beskrivelse) && (
                <Box mt={2}>
                  <Typography variant="body2" color="textSecondary">Beskrivelse</Typography>
                  <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
                    {taskDetails?.beskrivelse || task.beskrivelse}
                  </Typography>
                </Box>
              )}
              
              {(taskDetails?.status === 'Afsluttet' || task.status === 'Afsluttet') && (
                <Box mt={2}>
                  <Typography variant="body2" color="textSecondary">
                    Afslutningsnotat
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 0.5, whiteSpace: 'pre-wrap' }}>
                    {taskDetails?.completionNote || task.completionNote}
                  </Typography>
                </Box>
              )}
            </Paper>
          </Box>

          <Box mb={3}>
            <Typography variant="h6" gutterBottom>
              Anvendte reservedele
            </Typography>
            {taskDetails?.usedParts && taskDetails.usedParts.length > 0 ? (
              <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gap: 2 }}>
                {taskDetails.usedParts.map((part) => (
                  <Paper key={part.id} sx={{ p: 2 }}>
                    <Typography variant="subtitle2">{part.name}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      Antal brugt: {part.quantity_used}
                    </Typography>
                    {part.partNumber && (
                      <Typography variant="caption" display="block" color="textSecondary">
                        Varenummer: {part.partNumber}
                      </Typography>
                    )}
                  </Paper>
                ))}
              </Box>
            ) : (
              <Typography color="textSecondary">
                Ingen reservedele anvendt
              </Typography>
            )}
          </Box>

          <Divider sx={{ my: 2 }} />

          <Typography variant="h6" gutterBottom>
            Vedhæftede filer
          </Typography>
          {taskDetails?.files && taskDetails.files.length > 0 ? (
            <Box display="grid" gridTemplateColumns="repeat(auto-fill, minmax(200px, 1fr))" gap={2}>
              {taskDetails.files.map((file) => (
                <Paper key={file.id} sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  {file.file_type && file.file_type.startsWith('image/') ? (
                    // Image preview
                    <Box sx={{ position: 'relative' }}>
                      <img 
                        src={getFileUrl(file.file_path)}
                        alt={file.file_name}
                        style={{ 
                          width: '100%', 
                          height: '150px', 
                          objectFit: 'cover',
                          borderRadius: '4px'
                        }}
                      />
                      <Box 
                        sx={{ 
                          mt: 1,
                          display: 'flex', 
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <Typography variant="caption" noWrap>
                          {file.file_name}
                        </Typography>
                        <Box>
                          <IconButton 
                            size="small"
                            onClick={() => window.open(getFileUrl(file.file_path), '_blank')}
                          >
                            <ImageIcon />
                          </IconButton>
                          <IconButton 
                            size="small"
                            color="error"
                            onClick={() => handleFileDelete(file.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  ) : file.file_type && file.file_type.startsWith('video/') ? (
                    // Video preview
                    <Box sx={{ position: 'relative' }}>
                      <video
                        controls
                        style={{ 
                          width: '100%', 
                          maxHeight: '150px',
                          borderRadius: '4px'
                        }}
                      >
                        <source src={getFileUrl(file.file_path)} type={file.file_type} />
                        Your browser does not support the video tag.
                      </video>
                      <Box 
                        sx={{ 
                          mt: 1,
                          display: 'flex', 
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <Typography variant="caption" noWrap>
                          {file.file_name}
                        </Typography>
                        <Box>
                          <IconButton 
                            size="small"
                            onClick={() => window.open(getFileUrl(file.file_path), '_blank')}
                          >
                            <VideoFileIcon />
                          </IconButton>
                          <IconButton 
                            size="small"
                            color="error"
                            onClick={() => handleFileDelete(file.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    // Generic file display
                    <Box 
                      sx={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        height: '100%'
                      }}
                    >
                      <Box 
                        sx={{ 
                          display: 'flex', 
                          alignItems: 'center', 
                          justifyContent: 'center',
                          height: '100px',
                          backgroundColor: 'action.hover',
                          borderRadius: 1,
                          mb: 1
                        }}
                      >
                        <InsertDriveFileIcon sx={{ fontSize: 40 }} />
                      </Box>
                      <Box 
                        sx={{ 
                          display: 'flex', 
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <Typography variant="caption" noWrap>
                          {file.file_name}
                        </Typography>
                        <Box>
                          <IconButton 
                            size="small"
                            onClick={() => window.open(getFileUrl(file.file_path), '_blank')}
                          >
                            <InsertDriveFileIcon />
                          </IconButton>
                          <IconButton 
                            size="small"
                            color="error"
                            onClick={() => handleFileDelete(file.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Paper>
              ))}
            </Box>
          ) : (
            <Typography color="textSecondary">
              Ingen filer vedhæftet
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Luk</Button>
        </DialogActions>
      </Dialog>

      {/* Completion Dialog */}
      <Dialog 
        open={completionDialog} 
        onClose={() => {
          setCompletionDialog(false);
          setCompletionComment('');
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Afslut opgave</DialogTitle>
        <DialogContent sx={{ minHeight: '300px' }}>
          <TextField
            autoFocus
            margin="dense"
            label="Hvad er der blevet lavet?"
            fullWidth
            multiline
            rows={8}
            value={completionComment}
            onChange={(e) => setCompletionComment(e.target.value)}
            error={completionComment.trim() === ''}
            helperText={completionComment.trim() === '' ? 'Kommentar er påkrævet' : ''}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => {
              setCompletionDialog(false);
              setCompletionComment('');
            }}
            sx={{ 
              textTransform: 'none',
              fontWeight: 500,
              boxShadow: 'none',
              '&:hover': {
                boxShadow: 'none'
              }
            }}
          >
            Annuller
          </Button>
          <Button 
            onClick={handleTaskComplete}
            variant="contained"
            disabled={!completionComment.trim()}
            sx={{ 
              textTransform: 'none',
              fontWeight: 500,
              boxShadow: 'none',
              '&:hover': {
                boxShadow: 'none'
              }
            }}
          >
            Afslut opgave
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TaskDetailsDialog; 