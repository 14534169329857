import React, { useState, useEffect } from 'react';
import {
  Badge, IconButton, Menu, MenuItem, Typography,
  ListItemText, Divider, Box, Button
} from '@mui/material';
import { Notifications as NotificationsIcon } from '@mui/icons-material';
import { format } from 'date-fns';
import { da } from 'date-fns/locale';
import { Link } from 'react-router-dom';
import api from '../utils/apiUtils';  // Import the API utility

const NotificationBell = ({ userId }) => {
  const [notifications, setNotifications] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const [error, setError] = useState(null);

  // Function to fetch notifications
  const fetchNotifications = async () => {
    try {
      console.log('Fetching notifications...');
      const response = await api.get('/notifications.php');  // Add .php extension
      
      if (response.data?.success) {
        // Only update if we have new notifications
        const newNotifications = response.data.data;
        const hasNewNotifications = newNotifications.some(newNotif => {
          return !notifications.find(oldNotif => 
            oldNotif.id === newNotif.id && oldNotif.is_read === newNotif.is_read
          );
        });

        if (hasNewNotifications) {
          setNotifications(newNotifications);
          setUnreadCount(newNotifications.filter(n => !n.is_read).length);
        }
        setError(null);
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
      setError('Failed to fetch notifications');
    }
  };

  // Initial fetch and setup polling
  useEffect(() => {
    // Initial fetch
    fetchNotifications();

    // Set up polling every 10 seconds
    const interval = setInterval(fetchNotifications, 10000);

    // Cleanup
    return () => clearInterval(interval);
  }, []);

  const handleMarkAsRead = async (notificationId) => {
    try {
      const response = await api.put(`/notifications/${notificationId}.php`, {});  // Add .php extension
      
      if (response.data?.success) {
        setNotifications(prev => prev.map(notification => 
          notification.id === notificationId 
            ? { ...notification, is_read: true }
            : notification
        ));
        setUnreadCount(prev => Math.max(0, prev - 1));
      }
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  return (
    <>
      <IconButton color="inherit" onClick={(e) => setAnchorEl(e.currentTarget)}>
        <Badge badgeContent={unreadCount} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            maxHeight: 400,
            width: 360,
          },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography variant="h6">Notifikationer</Typography>
          {error && (
            <Typography color="error" variant="caption">
              {error}
            </Typography>
          )}
        </Box>
        <Divider />
        {notifications.length === 0 ? (
          <MenuItem>
            <ListItemText primary="Ingen notifikationer" />
          </MenuItem>
        ) : (
          notifications.map((notification) => (
            <MenuItem
              key={notification.id}
              onClick={() => handleMarkAsRead(notification.id)}
              sx={{
                backgroundColor: notification.is_read ? 'inherit' : 'action.hover',
                whiteSpace: 'normal',
                py: 1.5
              }}
            >
              <Box sx={{ width: '100%' }}>
                <Typography variant="body1" sx={{ mb: 0.5 }}>
                  {notification.message}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {format(new Date(notification.created_at), 'dd/MM/yyyy HH:mm', { locale: da })}
                </Typography>
                {notification.type === 'maintenance' && notification.reference_id && (
                  <Button
                    size="small"
                    component={Link}
                    to={`/vedligeholdelse?taskId=${notification.reference_id}`}
                    sx={{ mt: 1, display: 'block' }}
                  >
                    Se opgave
                  </Button>
                )}
              </Box>
            </MenuItem>
          ))
        )}
      </Menu>
    </>
  );
};

export default NotificationBell;