const config = {
    apiUrl: process.env.NODE_ENV === 'development' 
        ? 'http://localhost/tryklog/backend/api'
        : '/backend/api'
};

export default config;



