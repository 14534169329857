import axios from 'axios';
import config from '../config';

const api = axios.create({
    baseURL: config.apiUrl,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
    }
});

// Add request interceptor to handle endpoints
api.interceptors.request.use(config => {
    let url = config.url.replace('.php', '');
    const urlParts = url.split('/').filter(Boolean);
    
    if (urlParts[0] === 'auth') {
        if (urlParts.length > 1) {
            const action = urlParts[1];
            config.url = `/auth/${action}.php`;
        } else {
            config.url = `/auth/auth.php`;
        }
    } else {
        const mainEndpoint = urlParts[0];
        if (urlParts.length > 1) {
            urlParts.shift();
            config.url = `/${mainEndpoint}.php/${urlParts.join('/')}`;
        } else {
            config.url = `${url}.php`;
        }
    }
    
    return config;
}, error => {
    return Promise.reject(error);
});

export default api; 