import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { 
  Box, Typography, Button, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper, IconButton,
  Alert, Snackbar, CircularProgress, FormControl, 
  InputLabel, Select, MenuItem, FormControlLabel, Switch,
  Tabs, Tab, TextField, InputAdornment
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import TaskDialog from './TaskDialog';
import TaskDetailsDialog from './TaskDetailsDialog';
import config from '../../config';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import TaskList from './TaskList';
import { useAuth } from '../../contexts/AuthContext';
import api from '../../utils/apiUtils';

// Update the styled components
const StyledTabs = styled(Tabs)(({ theme }) => ({
  minHeight: 48,
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  '& .MuiTabs-indicator': {
    display: 'none' // Remove the indicator line
  }
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 500,
  fontSize: '0.9rem',
  minHeight: 48,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  borderRight: `1px solid ${theme.palette.divider}`,
  '&.Mui-selected': {
    backgroundColor: theme.palette.action.selected,
    color: theme.palette.text.primary,
    fontWeight: 600
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover
  },
  '&:last-child': {
    borderRight: 'none'
  }
}));

const MaintenanceComponent = () => {
  const { user } = useAuth();
  const isAdmin = Boolean(user?.isAdmin);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [machines, setMachines] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState('all');
  const [selectedTaskType, setSelectedTaskType] = useState('all');
  const [activeTab, setActiveTab] = useState('all');
  const [hideCompleted, setHideCompleted] = useState(false);
  const [viewMode, setViewMode] = useState('list');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchTasks();
    fetchDepartments();
    fetchMachines();
    fetchUsers();
  }, []);

  const fetchTasks = async () => {
    setLoading(true);
    try {
      const response = await api.get('/maintenance.php');
      if (response.data?.success) {
        setTasks(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching tasks:', error);
      setError('Der opstod en fejl ved hentning af opgaver');
    } finally {
      setLoading(false);
    }
  };

  const fetchDepartments = async () => {
    try {
      const response = await api.get('/departments.php');
      if (response.data?.success) {
        setDepartments(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching departments:', error);
      setError('Der opstod en fejl ved hentning af afdelinger');
    }
  };

  const fetchMachines = async () => {
    try {
      const response = await api.get('/machines.php');
      if (response.data?.success) {
        setMachines(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching machines:', error);
      setError('Der opstod en fejl ved hentning af maskiner');
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await api.get('/users.php');
      if (response.data?.success) {
        setUsers(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Der opstod en fejl ved hentning af brugere');
    }
  };

  const getFilteredTasks = () => {
    return tasks.filter(task => {
      const department = departments.find(d => d.name === task.afdeling);
      const isDepartmentVisible = department?.isVisible;
      const matchesDepartment = activeTab === 'all' || task.afdeling === activeTab;
      const matchesCompletion = !hideCompleted || task.status !== 'Afsluttet';
      const matchesTaskType = selectedTaskType === 'all' || task.taskType === selectedTaskType;
      return isDepartmentVisible && matchesDepartment && matchesCompletion && matchesTaskType;
    });
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Afventer':
        return '#FFF59D'; // Light yellow
      case 'I gang':
        return '#90CAF9'; // Light blue
      case 'Afsluttet':
        return '#A5D6A7'; // Light green
      default:
        return 'inherit';
    }
  };

  const getTaskTypeColor = (taskType) => {
    switch (taskType) {
      case 'cleaning':
        return '#FFCC80'; // Light orange
      case 'maintenance':
        return '#EF9A9A'; // Light red
      default:
        return 'inherit';
    }
  };

  const translateTaskType = (taskType) => {
    switch (taskType) {
      case 'cleaning':
        return 'Rengøring';
      case 'maintenance':
        return 'Vedligeholdelse';
      default:
        return taskType;
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setOpenDetailsDialog(false);
    setSelectedTask(null);
  };

  const handleTaskUpdate = async (updatedTask) => {
    try {
      const taskToUpdate = {
        id: updatedTask.id,
        afdeling: updatedTask.afdeling,
        maskine: updatedTask.maskine,
        title: updatedTask.title,
        prioritet: updatedTask.prioritet,
        status: updatedTask.status,
        forfaldsdato: updatedTask.forfaldsdato ? 
          moment(updatedTask.forfaldsdato).format('YYYY-MM-DD') : null,
        beskrivelse: updatedTask.beskrivelse || '',
        tildeltTil: updatedTask.tildeltTil || null,
        taskType: updatedTask.taskType || 'maintenance',
        repetitionInterval: updatedTask.repetitionInterval || 'none',
        nextDue: updatedTask.nextDue ? 
          moment(updatedTask.nextDue).format('YYYY-MM-DD') : null
      };

      const response = await api.put(
        `/maintenance.php/${taskToUpdate.id}`,
        taskToUpdate
      );

      if (response.data?.success) {
        setSuccessMessage('Opgave opdateret');
        await fetchTasks();
        handleCloseDialog();
      } else {
        setError(response.data?.error || 'Der opstod en fejl ved opdatering af opgaven');
      }
    } catch (error) {
      console.error('Error updating task:', error);
      setError(error.response?.data?.error || 'Der opstod en fejl ved opdatering af opgaven');
    }
  };

  const handleAddTask = async (taskData) => {
    try {
      console.log('Creating new task with data:', taskData);

      setSuccessMessage('Opgave oprettet');
      await fetchTasks();
      handleCloseDialog();
    } catch (error) {
      console.error('Error creating task:', error);
      setError(error.response?.data?.error || 'Der opstod en fejl ved oprettelse af opgaven');
    }
  };

  const handleOpenDetailsDialog = async (task) => {
    try {
      if (!task?.id) {
        console.error('Invalid task ID:', task);
        setError('Ugyldig opgave ID');
        return;
      }

      // Set initial task data immediately to show something
      setSelectedTask(task);
      setOpenDetailsDialog(true);

      // Fetch fresh task data with comments in a single request
      const response = await api.get(`/maintenance.php/${task.id}`);

      console.log('Task details response:', response.data);

      if (response.data?.success && response.data?.data) {
        // Find the specific task in the response data if it's an array
        const taskData = Array.isArray(response.data.data) 
          ? response.data.data.find(t => t.id === task.id)
          : response.data.data;
        
        if (taskData) {
          try {
            // Also fetch inventory data if needed
            const inventoryResponse = await api.get('/inventory.php', {
              params: { 
                taskId: task.id,
                // Add timestamp to prevent caching
                _t: new Date().getTime()
              }
            });
            
            if (inventoryResponse.data?.success) {
              taskData.usedParts = inventoryResponse.data.data;
            }
          } catch (inventoryError) {
            console.error('Error fetching inventory:', inventoryError);
            // Don't fail the whole operation if inventory fetch fails
            taskData.usedParts = [];
          }
          
          console.log('Setting task data:', taskData);
          setSelectedTask(taskData);
        } else {
          console.error('Task data is empty');
          setError('Opgaven blev ikke fundet');
        }
      } else {
        console.error('Invalid response format:', response.data);
        setError('Der opstod en fejl ved hentning af opgavedetaljer');
      }
    } catch (error) {
      console.error('Error fetching task details:', error);
      setError(error.response?.data?.error || 'Der opstod en fejl ved hentning af opgavedetaljer');
    }
  };

  const handleAddComment = async (taskId, commentText) => {
    try {
      if (!taskId || !commentText?.trim()) {
        console.error('Invalid comment data');
        return;
      }

      setLoading(true);
      console.log('Adding comment:', { taskId, comment: commentText.trim() });

      const response = await api.post(
        `/maintenance.php/${taskId}/comments.php`,
        { comment: commentText.trim() }
      );

      if (response.data?.success) {
        setSuccessMessage('Kommentar tilføjet');
        // Update task with new comment
        setSelectedTask(prevTask => ({
          ...prevTask,
          comments: [response.data.data, ...(prevTask.comments || [])]
        }));
      }
    } catch (error) {
      console.error('Error adding comment:', error);
      console.error('Error response:', error.response?.data);
      setError(error.response?.data?.error || 'Der opstod en fejl ved tilføjelse af kommentar');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      if (!selectedTask?.id || !commentId) {
        console.error('Missing task ID or comment ID');
        return;
      }

      const response = await api.delete(
        `/maintenance.php/${selectedTask.id}/comments.php/${commentId}`
      );

      if (response.data?.success) {
        setSuccessMessage('Kommentar slettet');
        // Update the comments in state directly
        setSelectedTask(prevTask => ({
          ...prevTask,
          comments: prevTask.comments.filter(comment => comment.id !== commentId)
        }));
      }
    } catch (error) {
      console.error('Error deleting comment:', error);
      setError(error.response?.data?.error || 'Der opstod en fejl ved sletning af kommentar');
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleDeleteTask = async (taskId) => {
    if (window.confirm('Er du sikker på, at du vil slette denne opgave?')) {
      try {
        const response = await api.delete(`/maintenance.php/${taskId}`);

        if (response.data?.success) {
          setSuccessMessage('Opgaven blev slettet');
          // Update the tasks state directly instead of calling fetchTasks
          setTasks(prevTasks => prevTasks.filter(task => task.id !== taskId));
        }
      } catch (error) {
        console.error('Error deleting task:', error);
        setError('Der opstod en fejl ved sletning af opgaven');
      }
    }
  };

  const filteredTasks = useMemo(() => {
    return tasks.filter(task => 
      task.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      task.beskrivelse?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      task.afdeling.toLowerCase().includes(searchTerm.toLowerCase()) ||
      task.maskine.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [tasks, searchTerm]);

  // Add the common button style
  const commonButtonStyle = {
    textTransform: 'none',
    fontWeight: 500,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none'
    }
  };

  return (
    <Paper sx={{ p: 2, backgroundColor: 'background.paper' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">Vedligeholdelse</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpenDialog}
          sx={commonButtonStyle}  // Apply the common style
        >
          Tilføj opgave
        </Button>
      </Box>

      <Box display="flex" alignItems="center" gap={2} mb={3}>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Opgavetype</InputLabel>
          <Select
            value={selectedTaskType}
            onChange={(e) => setSelectedTaskType(e.target.value)}
            label="Opgavetype"
          >
            <MenuItem value="all">Alle typer</MenuItem>
            <MenuItem value="maintenance">Vedligeholdelse</MenuItem>
            <MenuItem value="cleaning">Rengøring</MenuItem>
          </Select>
        </FormControl>

        <FormControlLabel
          control={
            <Switch
              checked={hideCompleted}
              onChange={(e) => setHideCompleted(e.target.checked)}
            />
          }
          label="Skjul afsluttede opgaver"
        />

        <TextField
          placeholder="Søg efter opgaver..."
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ 
            ml: 'auto',  // This will push the search field to the right
            width: 250   // Fixed width for the search field
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
      </Box>

      <Paper 
        elevation={0} 
        sx={{ 
          mb: 2,
          borderRadius: 1,
          backgroundColor: 'background.paper'
        }}
      >
        <StyledTabs
          value={activeTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          <StyledTab 
            label="Alle afdelinger" 
            value="all"
          />
          {departments
            .filter(dept => dept.isVisible)
            .map((dept) => (
              <StyledTab 
                key={dept.id} 
                label={dept.name} 
                value={dept.name}
              />
            ))}
        </StyledTabs>
      </Paper>

      {loading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Titel</TableCell>
                <TableCell>Afdeling</TableCell>
                <TableCell>Maskine</TableCell>
                <TableCell>Prioritet</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Opgavetype</TableCell>
                <TableCell>Tildelt til</TableCell>
                <TableCell>Forfaldsdato</TableCell>
                <TableCell>Handlinger</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getFilteredTasks().map((task) => (
                <TableRow key={task.id}>
                  <TableCell>{task.title}</TableCell>
                  <TableCell>{task.afdeling}</TableCell>
                  <TableCell>{task.maskine}</TableCell>
                  <TableCell>{task.prioritet}</TableCell>
                  <TableCell sx={{ backgroundColor: getStatusColor(task.status) }}>
                    {task.status}
                  </TableCell>
                  <TableCell sx={{ backgroundColor: getTaskTypeColor(task.taskType) }}>
                    {translateTaskType(task.taskType)}
                  </TableCell>
                  <TableCell>
                    {users.find(u => u.id === task.tildeltTil)?.name || 'Ikke tildelt'}
                  </TableCell>
                  <TableCell>
                    {task.forfaldsdato ? new Date(task.forfaldsdato).toLocaleDateString('da-DK') : '-'}
                  </TableCell>
                  <TableCell>
                    <IconButton 
                      onClick={() => handleOpenDetailsDialog(task)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton 
                      onClick={() => handleDeleteTask(task.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <TaskDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onSubmit={handleAddTask}
        departments={departments}
        machines={machines}
        filteredMachines={machines}
        users={users}
        task={selectedTask || {}}
        isEditing={Boolean(selectedTask)}
        onDepartmentChange={(dept) => setSelectedDepartment(dept)}
        isAdmin={isAdmin}
      />

      <TaskDetailsDialog
        open={openDetailsDialog}
        onClose={handleCloseDialog}
        task={selectedTask}
        onUpdate={handleTaskUpdate}
        onEdit={handleOpenDialog}
        users={users}
        onAddComment={handleAddComment}
        onDeleteComment={handleDeleteComment}
      />

      <Snackbar
        open={Boolean(successMessage)}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setSuccessMessage(null)} 
          severity="success"
          sx={{ width: '100%' }}
        >
          {successMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={Boolean(error)}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setError(null)} 
          severity="error"
          sx={{ width: '100%' }}
        >
          {error}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default MaintenanceComponent; 