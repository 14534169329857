import React, { createContext, useContext, useState, useEffect } from 'react';
import api from '../utils/apiUtils';

export const NotificationsContext = createContext();

export const NotificationsProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);

    const fetchNotifications = async () => {
        try {
            const response = await api.get('/notifications.php');
            if (response.data?.success) {
                setNotifications(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching notifications:', error);
        }
    };

    const markAsRead = async (notificationId) => {
        try {
            console.log('Marking notification as read:', notificationId);
            const response = await api.put(`/notifications.php/${notificationId}`);
            
            if (response.data?.success) {
                // Refresh notifications after marking as read
                await fetchNotifications();
            } else {
                throw new Error('Failed to mark notification as read');
            }
        } catch (error) {
            console.error('Error marking notification as read:', error);
        }
    };

    useEffect(() => {
        fetchNotifications();
    }, []);

    return (
        <NotificationsContext.Provider value={{ 
            notifications, 
            fetchNotifications,
            markAsRead 
        }}>
            {children}
        </NotificationsContext.Provider>
    );
};

export const useNotifications = () => useContext(NotificationsContext); 