import React, { useState, useEffect } from 'react';
import api from '../../utils/apiUtils';
import config from '../../config';
import {
  Paper, Typography, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  IconButton, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem,
  Box, CircularProgress, Alert, FormControlLabel, Switch
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';

const ContactDialog = ({ open, onClose, onSave, contact, departments }) => {
  const [formData, setFormData] = useState({
    name: '',
    type: '',
    role: '',
    department: '',
    phone: '',
    email: '',
    can_be_scheduled: false
  });

  useEffect(() => {
    setFormData(contact ? {
      ...contact,
      can_be_scheduled: Boolean(contact.can_be_scheduled)
    } : {
      name: '',
      type: '',
      role: '',
      department: '',
      phone: '',
      email: '',
      can_be_scheduled: false
    });
  }, [contact]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const dataToSave = {
      ...formData,
      can_be_scheduled: formData.can_be_scheduled ? 1 : 0
    };
    onSave(dataToSave);
  };

  const contactTypes = [
    { value: 'Employee', label: 'Medarbejder' },
    { value: 'Technician', label: 'Tekniker' },
    { value: 'Customer', label: 'Kunde' },
    { value: 'Leader', label: 'Leder' },
    { value: 'Other', label: 'Andet' }
  ];

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{contact ? 'Rediger Kontakt' : 'Tilføj Ny Kontakt'}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            label="Navn"
            name="name"
            value={formData.name || ''}
            onChange={handleChange}
            required
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Type</InputLabel>
            <Select
              name="type"
              value={formData.type || ''}
              onChange={handleChange}
              required
            >
              {contactTypes.map((type) => (
                <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            margin="normal"
            label="Rolle"
            name="role"
            value={formData.role || ''}
            onChange={handleChange}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            label="Afdeling"
            name="department"
            select
            value={formData.department || ''}
            onChange={handleChange}
            required
          >
            {departments.map((dept) => (
              <MenuItem key={dept.id} value={dept.name}>
                {dept.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            margin="normal"
            label="Telefon"
            name="phone"
            value={formData.phone || ''}
            onChange={handleChange}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            label="Email"
            name="email"
            type="email"
            value={formData.email || ''}
            onChange={handleChange}
          />
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(formData.can_be_scheduled)}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  can_be_scheduled: e.target.checked
                }))}
                name="can_be_scheduled"
              />
            }
            label="Kan tilføjes til vagtplan"
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annuller</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Gem
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ContactManagement = () => {
  const [contacts, setContacts] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentContact, setCurrentContact] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchContacts();
    fetchDepartments();
  }, []);

  const fetchDepartments = async () => {
    try {
      const response = await api.get('/departments.php');
      if (response.data && response.data.success && Array.isArray(response.data.data)) {
        setDepartments(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching departments:', error);
    }
  };

  const fetchContacts = async () => {
    try {
      setLoading(true);
      const response = await api.get('/contacts.php');
      
      if (response.data && response.data.success && Array.isArray(response.data.data)) {
        setContacts(response.data.data);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Error fetching contacts:', error);
      setError(error.message || 'Error fetching contacts');
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDialog = (contact = null) => {
    setCurrentContact(contact);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setCurrentContact(null);
    setOpenDialog(false);
  };

  const handleSaveContact = async (updatedContact) => {
    try {
      console.log('Saving contact:', updatedContact);
      
      const contactData = {
        ...updatedContact,
        can_be_scheduled: updatedContact.can_be_scheduled ? 1 : 0
      };

      if (contactData.id) {
        // Update existing contact
        await api.put(`/contacts.php/${contactData.id}`, contactData);
      } else {
        // Create new contact
        await api.post('/contacts.php', contactData);
      }
      
      fetchContacts();
      handleCloseDialog();
    } catch (error) {
      console.error('Error saving contact:', error);
      setError(error.message || 'Error saving contact');
    }
  };

  const handleDeleteContact = async (id) => {
    if (window.confirm('Er du sikker på, at du vil slette denne kontakt?')) {
      try {
        await api.delete(`/contacts.php/${id}`);
        fetchContacts();
      } catch (error) {
        console.error('Error deleting contact:', error);
        setError(error.message || 'Error deleting contact');
      }
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ mb: 2 }}>
        {error}
      </Alert>
    );
  }

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>Kontaktstyring</Typography>
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => handleOpenDialog()}
        sx={{ mb: 2 }}
      >
        Tilføj Kontakt
      </Button>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Navn</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Rolle</TableCell>
              <TableCell>Afdeling</TableCell>
              <TableCell>Telefon</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Handlinger</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(contacts) && contacts.map((contact) => (
              <TableRow key={contact.id}>
                <TableCell>{contact.name}</TableCell>
                <TableCell>{contact.type}</TableCell>
                <TableCell>{contact.role}</TableCell>
                <TableCell>{contact.department}</TableCell>
                <TableCell>{contact.phone}</TableCell>
                <TableCell>{contact.email}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleOpenDialog(contact)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteContact(contact.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ContactDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onSave={handleSaveContact}
        contact={currentContact}
        departments={departments}
      />
    </Paper>
  );
};

export default ContactManagement;
