import React, { useState, useEffect } from 'react';
import { 
  Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  Paper, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField,
  CircularProgress, IconButton, Tooltip, Chip, Snackbar, Alert
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import dayjs from 'dayjs';
import api from '../../utils/apiUtils';

const VacationRequests = () => {
  const [vacationRequests, setVacationRequests] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  useEffect(() => {
    fetchVacationRequests();
  }, []);

  const fetchVacationRequests = async () => {
    try {
      setLoading(true);
      const response = await api.get('/vacations.php', { withCredentials: true });
      
      if (response.data?.success) {
        console.log('Vacation requests:', response.data.data);
        setVacationRequests(response.data.data);
      } else {
        throw new Error('Failed to fetch vacation requests');
      }
    } catch (error) {
      console.error('Error fetching vacation requests:', error);
      setError('Der opstod en fejl ved hentning af ferieanmodninger. Prøv venligst igen senere.');
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = async (requestId, newStatus) => {
    try {
      const response = await api.put(`/vacations.php/${requestId}`, {
        status: newStatus
      }, {
        withCredentials: true
      });

      if (response.data?.success) {
        setVacationRequests(prev => prev.map(request => 
          request.id === requestId ? { ...request, status: newStatus } : request
        ));
        setSnackbar({
          open: true,
          message: `Ferieanmodning ${newStatus === 'Approved' ? 'godkendt' : 'afvist'} succesfuldt`,
          severity: 'success'
        });
        handleCloseDialog();
      } else {
        throw new Error(response.data?.error || 'Failed to update vacation request');
      }
    } catch (error) {
      console.error('Error updating vacation request:', error);
      setSnackbar({
        open: true,
        message: `Fejl ved ${newStatus === 'Approved' ? 'godkendelse' : 'afvisning'} af ferieanmodning`,
        severity: 'error'
      });
    }
  };

  const handleVacationAction = async (requestId, action) => {
    try {
      const response = await api.put(`/vacations.php/${requestId}`, {
        status: action === 'approve' ? 'Approved' : 'Rejected'
      }, {
        withCredentials: true
      });

      if (response.data?.success) {
        await fetchVacationRequests();
        setSnackbar({
          open: true,
          message: `Ferieanmodning ${action === 'approve' ? 'godkendt' : 'afvist'} succesfuldt`,
          severity: 'success'
        });
      }
    } catch (error) {
      console.error('Error handling vacation request:', error);
      setSnackbar({
        open: true,
        message: 'Der opstod en fejl ved behandling af ferieanmodningen',
        severity: 'error'
      });
    }
  };

  const handleDeleteVacationRequest = async (request) => {
    if (window.confirm('Er du sikker på, at du vil slette denne ferieanmodning?')) {
      try {
        const response = await api.post('/vacations.php', {
          action: 'delete',
          id: request.id
        });

        if (response.data?.success) {
          await fetchVacationRequests();
        } else {
          console.error('Failed to delete vacation request:', response.data?.error);
        }
      } catch (error) {
        console.error('Error deleting vacation request:', error);
        console.error('Error details:', error.response?.data);
      }
    }
  };

  const handleOpenDialog = (request) => {
    console.log('Opening dialog with request:', request); // Debug log
    if (!request.contact_id) {
      console.error('Request is missing contact_id:', request);
    }
    setSelectedRequest(request);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedRequest(null);
    setComment('');
  };

  const getVacationTypeDisplay = (type) => {
    switch (type) {
      case 'vacation':
        return 'Ferie';
      case 'vacation_unpaid':
        return 'Ferie uden løn';
      case 'vacation_free':
        return 'Ferie Fri';
      case 'afspadsering':
        return 'Afspadsering';
      default:
        return type;
    }
  };

  const getVacationTypeColor = (type) => {
    switch (type) {
      case 'vacation':
        return 'primary';
      case 'vacation_unpaid':
        return 'warning';
      case 'vacation_free':
        return 'success';
      case 'afspadsering':
        return 'info';
      default:
        return 'default';
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Pending':
        return 'warning'; // Orange for pending
      case 'Approved':
        return 'success'; // Green for approved
      case 'Rejected':
        return 'error';   // Red for rejected
      default:
        return 'default';
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>Ferieanmodninger (Admin Oversigt)</Typography>
      {vacationRequests.length === 0 ? (
        <Typography>Ingen ferieanmodninger fundet.</Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Medarbejder</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Start Dato</TableCell>
                <TableCell>Slut Dato</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Handlinger</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vacationRequests.map((request) => {
                console.log('Rendering request:', request);
                return (
                  <TableRow key={request.id}>
                    <TableCell>
                      {request.contact_name || request.employee_name || 'Ukendt medarbejder'}
                    </TableCell>
                    <TableCell>
                      <Chip 
                        label={getVacationTypeDisplay(request.type)} 
                        color={getVacationTypeColor(request.type)}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>{dayjs(request.startDate).format('DD/MM/YYYY')}</TableCell>
                    <TableCell>{dayjs(request.endDate).format('DD/MM/YYYY')}</TableCell>
                    <TableCell>
                      <Chip 
                        label={
                          request.status === 'Pending' ? 'Afventer' :
                          request.status === 'Approved' ? 'Godkendt' :
                          request.status === 'Rejected' ? 'Afvist' : 
                          request.status
                        }
                        color={getStatusColor(request.status)}
                        size="small"
                        sx={{ 
                          minWidth: 80,
                          '& .MuiChip-label': {
                            fontWeight: 500
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Button onClick={() => handleOpenDialog(request)}>Behandl</Button>
                      <Tooltip title="Slet ferieanmodning">
                        <IconButton onClick={() => handleDeleteVacationRequest(request)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Behandl Ferieanmodning</DialogTitle>
        <DialogContent>
          {selectedRequest ? (
            <>
              <Typography>
                {selectedRequest.employee_name}: {' '}
                {dayjs(selectedRequest.startDate).format('DD/MM/YYYY')} - 
                {dayjs(selectedRequest.endDate).format('DD/MM/YYYY')}
              </Typography>
              <Typography>
                Type: {selectedRequest ? getVacationTypeDisplay(selectedRequest.type) : ''}
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={4}
                margin="normal"
                label="Kommentar"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </>
          ) : (
            <Typography color="error">Fejl: Ingen anmodning valgt</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annuller</Button>
          <Button 
            onClick={() => handleStatusChange(selectedRequest?.id, 'Approved')} 
            color="primary"
            disabled={!selectedRequest}
          >
            Godkend
          </Button>
          <Button 
            onClick={() => handleStatusChange(selectedRequest?.id, 'Rejected')} 
            color="secondary"
            disabled={!selectedRequest}
          >
            Afvis
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleSnackbarClose} 
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default VacationRequests;
