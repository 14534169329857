import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Paper,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { jsPDF } from "jspdf";
import axios from 'axios';
import config from '../config';

const cityMapping = {
  "JFM - Holstebro": "holstebro",
  "JFM - Ringkøbing": "ringkobing",
  "JFM - Vejle": "vejle",
  "JFM - Viborg": "viborg",
  "SAMDI - Herning": "herning",
  "SAMDI - Silkeborg": "silkeborg",
  "SAMDI - Skive": "skive"
};

const cityColors = {
  "holstebro": "Grøn",
  "ringkobing": "Gul",
  "vejle": "Blå",
  "viborg": "Hvid",
  "herning": "Rød",
  "silkeborg": "Hvid",
  "skive": "Hvid"
};

const DistributionDataInputComponent = () => {
  const [paperType, setPaperType] = useState('Kristeligt Dagblad');
  const [bundleSize, setBundleSize] = useState('');
  const [threshold, setThreshold] = useState('');
  const [emailData, setEmailData] = useState('');
  const [cityData, setCityData] = useState({});
  const [results, setResults] = useState([]);
  const [totalBundles, setTotalBundles] = useState(0);
  const [totalPapers, setTotalPapers] = useState(0);

  useEffect(() => {
    fetchCityData();
  }, []);

  const fetchCityData = async () => {
    try {
      const apiUrl = `${config.apiUrl}/citydata.php`;
      console.log('API URL:', apiUrl);
      console.log('Config:', config);
      console.log('Fetching city data...');
      
      try {
        const response = await axios.get(apiUrl, { 
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          },
          timeout: 5000,
          maxRedirects: 5
        });
        
        console.log('City data response:', response);

        if (response.data && response.data.success) {
          const initialCityData = {};
          Object.keys(cityMapping).forEach(fullCityName => {
            const cityKey = cityMapping[fullCityName];
            initialCityData[cityKey] = {
              main: '',
              depot: '',
              info: fullCityName,
              color: cityColors[cityKey]
            };
          });

          const mergedData = { ...initialCityData, ...response.data.data };
          console.log('Merged city data:', mergedData);
          setCityData(mergedData);
        } else {
          throw new Error('Invalid response format');
        }
      } catch (networkError) {
        console.error('Network error:', networkError);
        throw networkError;
      }
    } catch (error) {
      console.error('Error fetching city data:', error);
      const defaultData = {};
      Object.keys(cityMapping).forEach(fullCityName => {
        const cityKey = cityMapping[fullCityName];
        defaultData[cityKey] = {
          main: '',
          depot: '',
          info: fullCityName,
          color: cityColors[cityKey]
        };
      });
      console.log('Using default city data after error:', defaultData);
      setCityData(defaultData);
    }
  };

  const processEmailData = () => {
    const dataLines = emailData.split('\n').map(line => line.trim()).filter(line => line);
    const newCityData = { ...cityData };

    dataLines.forEach(line => {
      for (const city in cityMapping) {
        if (line.startsWith(city)) {
          const cityKey = cityMapping[city];
          const mainNum = parseInt(line.replace(city, '').trim());
          const depotLine = dataLines[dataLines.indexOf(line) + 1];
          const depotNum = parseInt(depotLine.replace(/[^0-9]/g, '').trim());
          
          if (!isNaN(mainNum) || !isNaN(depotNum)) {
            newCityData[cityKey] = { 
              ...newCityData[cityKey],
              main: isNaN(mainNum) ? '' : mainNum,
              depot: isNaN(depotNum) ? '' : depotNum
            };
          }
        }
      }
    });

    setCityData(newCityData);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const bundleSizeNum = parseInt(bundleSize);
    const thresholdNum = parseInt(threshold);
    
    if (bundleSizeNum === 0) {
      alert("Husk at indtaste bundt størrelse");
      return;
    }

    let newResults = [];
    let newTotalBundleCount = 0;
    let newGrandTotalPapers = 0;

    Object.keys(cityMapping).forEach(fullCityName => {
      const cityKey = cityMapping[fullCityName];
      const data = cityData[cityKey] || { main: 0, depot: 0 };
      
      const mainNum = parseInt(data.main) || 0;
      const depotNum = parseInt(data.depot) || 0;
      const totalPapers = mainNum + depotNum;

      let bundles = Math.floor(totalPapers / bundleSizeNum);
      let remainingPapers = totalPapers % bundleSizeNum;

      if (remainingPapers + bundleSizeNum <= thresholdNum && 
          remainingPapers !== 0 && 
          remainingPapers + bundleSizeNum !== bundleSizeNum) {
        remainingPapers += bundleSizeNum;
        bundles -= 1;
      }

      newGrandTotalPapers += totalPapers;
      newTotalBundleCount += bundles;

      if (totalPapers > 0) {
        newResults.push({ 
          city: cityKey,
          fullCityName,
          totalPapers,
          bundles,
          remainingPapers
        });
      }
    });

    setResults(newResults);
    setTotalBundles(newTotalBundleCount);
    setTotalPapers(newGrandTotalPapers);
  };

  const generatePDF = (city, totalPapers, bundles, remainingPapers, color) => {
    const doc = new jsPDF();
    const paperAbbreviation = paperType === "Kristeligt Dagblad" ? "KRD" : "INF";
    const cityInfo = cityData[city.toLowerCase()] || { info: "", additionalData: "" };

    const getCurrentDateFormatted = () => {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      return tomorrow.toLocaleDateString("da-DK");
    };

    const drawMultilineText = (text, x, y, fontSize) => {
      doc.setFontSize(fontSize);
      const lines = text.split('\\n');
      let currentY = y;
      const lineHeight = fontSize * 0.5; // Adjust line spacing here
      
      lines.forEach(line => {
        doc.text(line.trim(), x, currentY, "center");
        currentY += lineHeight;
      });
      
      return currentY; // Return the Y position after the last line
    };

    const addPageContent = (pageNumber, totalPages, isRemaining = false) => {
      const currentDate = getCurrentDateFormatted();

      const paperTypeText = paperType || "Unknown Paper";
      const cityText = city ? city.toUpperCase() : "UNKNOWN CITY";
      const cityInfoText = cityInfo.info || "";
      const additionalDataText = cityInfo.additionalData || "";
      const totalPapersText = totalPapers ? totalPapers.toString() : "0";
      const pageNumberText = `${pageNumber} of ${totalPages}`;
      const remainingPapersText = remainingPapers ? remainingPapers.toString() : "0";

      doc.setFontSize(54);
      doc.text(paperTypeText, 105, 40, "center");
      
      doc.setFontSize(32);
      doc.text(cityText, 105, 65, "center");

      let nextY = drawMultilineText(cityInfoText, 105, 85, 32);
      
      nextY = drawMultilineText(additionalDataText, 105, nextY + 10, 32);

      doc.setFontSize(32);
      doc.text(`Antal aviser: ${totalPapersText}`, 105, 150, "center");
      doc.text(`Antal Pakker: ${pageNumberText}`, 200, 245, "right");
      doc.text(`Dato: ${currentDate}`, 105, 270, "center");

      if (isRemaining) {
        doc.setFontSize(45);
        doc.text(`Løse: ${remainingPapersText}`, 105, 190, "center");
      }
    };

    const totalPages = (bundles || 0) + (remainingPapers > 0 ? 1 : 0);

    for (let i = 1; i <= (bundles || 0); i++) {
      if (i > 1) doc.addPage();
      addPageContent(i, totalPages);
    }

    if (remainingPapers > 0) {
      if (bundles > 0) doc.addPage();
      addPageContent((bundles || 0) + 1, totalPages, true);
    }

    const filename = `${paperAbbreviation}-${city || 'unknown'}-${color || 'none'}.pdf`;
    doc.save(filename);
  };

  const preventWheelChange = (e) => {
    e.target.blur();
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        KRD Samdi og Bundnet Input Form
      </Typography>

      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Vælg avis</InputLabel>
                <Select
                  value={paperType}
                  onChange={(e) => setPaperType(e.target.value)}
                  label="Vælg avis"
                >
                  <MenuItem value="Kristeligt Dagblad">Kristeligt Dagblad</MenuItem>
                  <MenuItem value="Information">Information</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Bundt størrelse"
                type="number"
                value={bundleSize}
                onChange={(e) => setBundleSize(e.target.value)}
                onWheel={preventWheelChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Grænse for antal løse aviser"
                type="number"
                value={threshold}
                onChange={(e) => setThreshold(e.target.value)}
                onWheel={preventWheelChange}
              />
            </Grid>
          </Grid>

          <Accordion sx={{ mt: 3 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Kopier data fra email KRD</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Indsæt data fra email"
                value={emailData}
                onChange={(e) => setEmailData(e.target.value)}
              />
              <Button onClick={processEmailData} variant="contained" sx={{ mt: 2 }}>
                Process Email Data
              </Button>
            </AccordionDetails>
          </Accordion>

          {Object.entries(cityMapping).map(([fullCityName, cityKey]) => (
            <Paper key={cityKey} elevation={2} sx={{ p: 2, mt: 2 }}>
              <Typography variant="h6">{fullCityName}</Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Main Number"
                    type="number"
                    value={cityData[cityKey]?.main || ''}
                    onChange={(e) => setCityData({
                      ...cityData,
                      [cityKey]: { ...cityData[cityKey], main: e.target.value }
                    })}
                    onWheel={preventWheelChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Depotaviser Number"
                    type="number"
                    value={cityData[cityKey]?.depot || ''}
                    onChange={(e) => setCityData({
                      ...cityData,
                      [cityKey]: { ...cityData[cityKey], depot: e.target.value }
                    })}
                    onWheel={preventWheelChange}
                  />
                </Grid>
              </Grid>
            </Paper>
          ))}

          <Button type="submit" variant="contained" color="primary" sx={{ mt: 3 }}>
            Submit
          </Button>
        </form>
      </Paper>

      {results.length > 0 && (
        <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
          <Typography variant="h5" gutterBottom>Results</Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>City</TableCell>
                  <TableCell>Total Aviser</TableCell>
                  <TableCell>Bundter</TableCell>
                  <TableCell>Løse</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.map((result) => (
                  <TableRow key={result.city}>
                    <TableCell>{result.city.toUpperCase()}</TableCell>
                    <TableCell>{result.totalPapers}</TableCell>
                    <TableCell>{result.bundles}</TableCell>
                    <TableCell>{result.remainingPapers}</TableCell>
                    <TableCell>
                      <Button onClick={() => generatePDF(result.city, result.totalPapers, result.bundles, result.remainingPapers, cityColors[result.city])}>
                        Download Report
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box mt={2}>
            <Typography><strong>Antal Hele Bundter: {totalBundles}</strong></Typography>
            <Typography><strong>Antal Aviser: {totalPapers}</strong></Typography>
          </Box>
        </Paper>
      )}
    </Container>
  );
};

export default DistributionDataInputComponent;