import React, { createContext, useState, useContext, useEffect } from 'react';
import api from '../utils/apiUtils';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const verifyAuth = async () => {
            try {
                const debugResponse = await api.get('/auth/debug.php');
                console.log('Debug info:', debugResponse.data);

                const response = await api.get('/auth/verify.php');
                console.log('Auth verification response:', response.data);
                
                if (response.data?.success) {
                    const userData = {
                        ...response.data.user,
                        isAdmin: Boolean(response.data.user.isAdmin)
                    };
                    console.log('Setting user data:', userData);
                    setUser(userData);
                }
            } catch (error) {
                console.error('Auth verification failed:', error);
                setUser(null);
            } finally {
                setLoading(false);
            }
        };

        verifyAuth();
    }, []);

    const login = async (username, password) => {
        try {
            const response = await api.post('/auth/login.php', { username, password });
            console.log('Login response:', response.data); // Debug log
            
            if (response.data?.success) {
                const userData = {
                    ...response.data.user,
                    isAdmin: Boolean(response.data.user.isAdmin)
                };
                setUser(userData);
                return true;
            }
            return false;
        } catch (error) {
            console.error('Login error:', error);
            throw error;
        }
    };

    const logout = async () => {
        try {
            await api.post('/auth/logout.php');
            setUser(null);
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    if (loading) {
        return <div>Loading...</div>; // Or your loading component
    }

    return (
        <AuthContext.Provider value={{ user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
}; 