import React from 'react';
import { 
    Routes, 
    Route, 
    Navigate,
    createRoutesFromElements,
    createBrowserRouter,
    RouterProvider,
    HydrateFallback
} from 'react-router-dom';
import { ColorModeProvider } from './contexts/ColorModeContext';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { NotificationsProvider } from './contexts/NotificationsContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/da';
import Dashboard from './components/Dashboard';
import LoginPage from './components/LoginPage';

// Loading component
const LoadingFallback = () => (
    <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh' 
    }}>
        Loading...
    </div>
);

// PrivateRoute component
const PrivateRoute = ({ children }) => {
    const { user } = useAuth();
    return user ? children : <Navigate to="/login" replace />;
};

// Create router with all future flags
const router = createBrowserRouter(
    createRoutesFromElements(
        <Route>
            <Route path="/login" element={<LoginPage />} />
            <Route
                path="/*"
                element={
                    <PrivateRoute>
                        <Dashboard />
                    </PrivateRoute>
                }
                HydrateFallback={LoadingFallback}
            />
            <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
    ),
    {
        future: {
            v7_startTransition: true,
            v7_relativeSplatPath: true,
            v7_fetcherPersist: true,
            v7_normalizeFormMethod: true,
            v7_partialHydration: true,
            v7_skipActionErrorRevalidation: true
        },
        basename: process.env.PUBLIC_URL
    }
);

function App() {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="da">
            <ColorModeProvider>
                <AuthProvider>
                    <NotificationsProvider>
                        <RouterProvider router={router} />
                    </NotificationsProvider>
                </AuthProvider>
            </ColorModeProvider>
        </LocalizationProvider>
    );
}

export default App;
