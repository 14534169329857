import React, { useState, useEffect } from 'react';
import { Paper, Typography, List, ListItemButton, ListItemIcon, ListItemText, Collapse, Box } from '@mui/material';
import {
  People as PeopleIcon,
  Group as TeamIcon,
  EventNote as VacationIcon,
  CalendarToday as ShiftIcon,
  BeachAccess as SummerVacationIcon,
  Build as MachineIcon,
  ViewList as PalletIcon,
  ContactPhone as ContactIcon,
  Business as DepartmentIcon,
  ExpandLess,
  ExpandMore,
  Inventory as InventoryIcon
} from '@mui/icons-material';
import { useLocation } from 'react-router-dom';

import UserManagement from './management/UserManagement';
import TeamManagement from './management/TeamManagement';
import ShiftPlanning from './management/ShiftPlanning';
import VacationRequests from './management/VacationRequests';
import SummerVacationPlanning from './management/SummerVacationPlanning';
import MachineManagement from './management/MachineManagement';
import PalletAccountingManagement from './management/PalletAccountingManagement';
import ContactManagement from './management/ContactManagement';
import DepartmentManagement from './management/DepartmentManagement';
import InventoryManagement from './management/InventoryManagement';
import api from '../utils/apiUtils';

const AdminComponent = () => {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(() => {
    return location.state?.activeTab === 'vacation' ? { planning: true } : {};
  });

  const [activeTab, setActiveTab] = useState(() => {
    if (location.state?.activeTab === 3) {
        return 'vacation';
    } else if (location.state?.activeTab === 'inventory') {
        return 'inventory';
    }
    return 'users';
  });

  const menuItems = [
    { icon: PeopleIcon, label: "Brugerstyring", value: 'users' },
    { icon: TeamIcon, label: "Holdstyring", value: 'teams' },
    { 
      icon: VacationIcon, 
      label: "Ferie og Vagtplanlægning", 
      value: 'planning',
      subItems: [
        { label: "Ferieanmodninger", value: 'vacation' },
        { label: "Vagtplanlægning", value: 'shifts' },
        { label: "Sommer Ferieplanlægning", value: 'summerVacation' },
      ]
    },
    { icon: MachineIcon, label: "Maskinstyring", value: 'machines' },
    { icon: InventoryIcon, label: "Reservedele & Inventar", value: 'inventory' },
    { icon: PalletIcon, label: "Palleregnskab", value: 'pallets' },
    { icon: ContactIcon, label: "Kontaktstyring", value: 'contacts' },
    { icon: DepartmentIcon, label: "Afdelingsstyring", value: 'departments' },
  ];

  const handleMenuClick = (item) => {
    if (item.subItems) {
      setMenuOpen(prev => ({ ...prev, [item.value]: !prev[item.value] }));
    } else {
      setActiveTab(item.value);
    }
  };

  useEffect(() => {
    if (location.state?.activeTab === 3) {
      setActiveTab('vacation');
      setMenuOpen(prev => ({ ...prev, planning: true }));
    }
  }, [location.state]);

  const renderContent = () => {
    switch (activeTab) {
      case 'users':
        return <UserManagement />;
      case 'teams':
        return <TeamManagement />;
      case 'vacation':
        return <VacationRequests />;
      case 'shifts':
        return <ShiftPlanning />;
      case 'summerVacation':
        return <SummerVacationPlanning />;
      case 'machines':
        return <MachineManagement />;
      case 'inventory':
        return <InventoryManagement />;
      case 'pallets':
        return <PalletAccountingManagement />;
      case 'contacts':
        return <ContactManagement />;
      case 'departments':
        return <DepartmentManagement />;
      default:
        return <Typography>Vælg en funktion fra menuen</Typography>;
    }
  };

  return (
    <Paper sx={{ display: 'flex', minHeight: '100vh' }}>
      <Box sx={{ width: 240, borderRight: 1, borderColor: 'divider' }}>
        <List component="nav">
          {menuItems.map((item) => (
            <React.Fragment key={item.value}>
              <ListItemButton onClick={() => handleMenuClick(item)}>
                <ListItemIcon>
                  <item.icon />
                </ListItemIcon>
                <ListItemText primary={item.label} />
                {item.subItems && (menuOpen[item.value] ? <ExpandLess /> : <ExpandMore />)}
              </ListItemButton>
              {item.subItems && (
                <Collapse in={menuOpen[item.value]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.subItems.map((subItem) => (
                      <ListItemButton
                        key={subItem.value}
                        sx={{ pl: 4 }}
                        onClick={() => setActiveTab(subItem.value)}
                      >
                        <ListItemText primary={subItem.label} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Typography variant="h5" gutterBottom>Administrationspanel</Typography>
        {renderContent()}
      </Box>
    </Paper>
  );
};

export default AdminComponent;
