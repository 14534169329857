import React, { useState, useEffect, useMemo } from 'react';
import api from '../../utils/apiUtils';
import {
  Paper,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Box,
  CircularProgress,
  Alert
} from '@mui/material';
import {
  Search as SearchIcon,
} from '@mui/icons-material';

const ContactOverviewComponent = () => {
  const [contacts, setContacts] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterDepartment, setFilterDepartment] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchContacts();
    fetchDepartments();
  }, []);

  const fetchDepartments = async () => {
    try {
      console.log('Fetching departments...');
      const response = await api.get('/departments');
      console.log('Departments response:', response);
      
      if (response.data?.success) {
        // Filter only visible departments
        const visibleDepartments = response.data.data.filter(
          dept => dept.isVisible === 1
        );
        setDepartments(visibleDepartments);
      }
    } catch (error) {
      console.error('Error fetching departments:', error);
      console.error('Error details:', error.response?.data);
    }
  };

  const fetchContacts = async () => {
    try {
      setLoading(true);
      setError(null);
      console.log('Fetching contacts...');
      const response = await api.get('/contacts');
      console.log('Contacts response:', response);
      
      if (response.data?.success) {
        setContacts(response.data.data);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Error fetching contacts:', error);
      setError(error.message || 'Error fetching contacts');
      setContacts([]);
    } finally {
      setLoading(false);
    }
  };

  const filteredContacts = useMemo(() => {
    return contacts.filter(contact =>
      (contact.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
       contact.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
       contact.phone?.includes(searchTerm)) &&
      (filterDepartment === '' || contact.department === filterDepartment)
    );
  }, [contacts, searchTerm, filterDepartment]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ mb: 2 }}>
        {error}
      </Alert>
    );
  }

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h5" gutterBottom>
        Telefonbog / Kontaktoversigt
      </Typography>

      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <TextField
          label="Søg"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            endAdornment: <SearchIcon />
          }}
        />

        <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
          <InputLabel>Afdeling</InputLabel>
          <Select
            value={filterDepartment}
            onChange={(e) => setFilterDepartment(e.target.value)}
            label="Afdeling"
          >
            <MenuItem value="">Alle</MenuItem>
            {departments.map(dept => (
              <MenuItem key={dept.id} value={dept.name}>{dept.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Navn</TableCell>
              <TableCell>Rolle</TableCell>
              <TableCell>Afdeling</TableCell>
              <TableCell>Telefon</TableCell>
              <TableCell>Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredContacts
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((contact) => (
                <TableRow key={contact.id}>
                  <TableCell>{contact.name}</TableCell>
                  <TableCell>
                    <Chip label={contact.role} color="primary" variant="outlined" />
                  </TableCell>
                  <TableCell>{contact.department}</TableCell>
                  <TableCell>{contact.phone}</TableCell>
                  <TableCell>{contact.email}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredContacts.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default ContactOverviewComponent; 