import React, { useState, useEffect } from 'react';
import {
  Paper, Typography, Button, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogContent,
  DialogActions, TextField, Select, MenuItem, FormControl, InputLabel,
  Box, CircularProgress, Alert, Grid, Tooltip, FormControlLabel, Checkbox
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import api from '../../utils/apiUtils';

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const [formData, setFormData] = useState({
    name: '',
    username: '',
    email: '',
    password: '',
    role: '',
    department: '',
    isAdmin: false
  });

  const roles = ['Leder', 'Operatør', 'Trykker', 'Service', 'Tekniker'];

  useEffect(() => {
    fetchUsers();
    fetchDepartments();
  }, []);

  const fetchDepartments = async () => {
    try {
      const response = await api.get('/departments.php');
      if (response.data?.success) {
        setDepartments(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching departments:', error);
    }
  };

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await api.get('/users.php');
      if (response.data?.success) {
        setUsers(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Der opstod en fejl ved hentning af brugere');
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDialog = (user = null) => {
    if (user) {
      setCurrentUser(user);
      setFormData({
        name: user.name || '',
        username: user.username || '',
        email: user.email || '',
        password: '',
        role: user.role || '',
        department: user.department || '',
        isAdmin: Boolean(user.isAdmin)
      });
    } else {
      setCurrentUser(null);
      setFormData({
        name: '',
        username: '',
        email: '',
        password: '',
        role: '',
        department: '',
        isAdmin: false
      });
    }
    setOpenDialog(true);
  };

  const handleSubmit = async () => {
    try {
      console.log('Submitting user data:', formData);
      const method = currentUser ? 'put' : 'post';
      const url = currentUser ? `/users.php/${currentUser.id}` : '/users.php';

      const response = await api[method](url, formData);

      if (response.data?.success) {
        await fetchUsers();
        setOpenDialog(false);
      }
    } catch (error) {
      console.error('Error saving user:', error);
      setError('Der opstod en fejl ved gemning af bruger');
    }
  };

  const handleDeleteUser = async (id) => {
    if (window.confirm('Er du sikker på, at du vil slette denne bruger?')) {
      try {
        await api.delete(`/users.php/${id}`);
        await fetchUsers();
      } catch (error) {
        console.error('Error deleting user:', error);
        setError('Der opstod en fejl ved sletning af bruger');
      }
    }
  };

  const renderUserTable = () => (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Navn</TableCell>
            <TableCell>Brugernavn</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Rolle</TableCell>
            <TableCell>Afdeling</TableCell>
            <TableCell>Admin</TableCell>
            <TableCell>Handlinger</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.id}>
              <TableCell>{user.name}</TableCell>
              <TableCell>{user.username}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{user.role}</TableCell>
              <TableCell>{user.department}</TableCell>
              <TableCell>{user.isAdmin ? 'Ja' : 'Nej'}</TableCell>
              <TableCell>
                <Tooltip title="Rediger bruger">
                  <IconButton onClick={() => handleOpenDialog(user)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Slet bruger">
                  <IconButton onClick={() => handleDeleteUser(user.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderDialog = () => (
    <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="md" fullWidth>
      <DialogTitle>{currentUser ? 'Rediger Bruger' : 'Tilføj Ny Bruger'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Navn"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              margin="normal"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Brugernavn"
              value={formData.username}
              onChange={(e) => setFormData({ ...formData, username: e.target.value })}
              margin="normal"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={currentUser ? "Nyt kodeord (tom = uændret)" : "Kodeord"}
              type="password"
              value={formData.password}
              onChange={(e) => setFormData({ ...formData, password: e.target.value })}
              margin="normal"
              required={!currentUser}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email"
              type="email"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              margin="normal"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Rolle</InputLabel>
              <Select
                value={formData.role}
                onChange={(e) => setFormData({ ...formData, role: e.target.value })}
                label="Rolle"
                required
              >
                {roles.map((role) => (
                  <MenuItem key={role} value={role}>{role}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Afdeling</InputLabel>
              <Select
                value={formData.department}
                onChange={(e) => {
                  const newDepartment = e.target.value;
                  setFormData({
                    ...formData,
                    department: newDepartment,
                    isAdmin: newDepartment === 'Ledelse' ? true : formData.isAdmin
                  });
                }}
                label="Afdeling"
                required
              >
                {departments.map((dept) => (
                  <MenuItem key={dept.id} value={dept.name}>
                    {dept.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.isAdmin}
                  onChange={(e) => setFormData({ ...formData, isAdmin: e.target.checked })}
                  disabled={formData.department === 'Ledelse'}
                />
              }
              label="Administrator rettigheder"
            />
            {formData.department === 'Ledelse' && (
              <Typography variant="caption" color="textSecondary" sx={{ display: 'block', mt: 1 }}>
                Brugere i Ledelse-afdelingen får automatisk admin-rettigheder
              </Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDialog(false)}>Annuller</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          {currentUser ? 'Opdater' : 'Tilføj'}
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Paper sx={{ p: 2 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">Brugerstyring</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => handleOpenDialog()}
        >
          Tilføj Bruger
        </Button>
      </Box>

      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : (
        renderUserTable()
      )}

      {renderDialog()}
    </Paper>
  );
};

export default UserManagement;
