import React, { useState, useEffect } from 'react';
import {
  Paper, Typography, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Box, CircularProgress, Alert,
  FormControlLabel, Switch, FormGroup
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import api from '../../utils/apiUtils';

const DepartmentManagement = () => {
  const [departments, setDepartments] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentDepartment, setCurrentDepartment] = useState({ 
    name: '', 
    isVisible: true,
    isVisibleInShiftPlanning: true
  });
  const [departmentName, setDepartmentName] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchDepartments();
  }, []);

  const fetchDepartments = async () => {
    try {
      setLoading(true);
      const response = await api.get('/departments.php');
      if (response.data?.success) {
        setDepartments(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching departments:', error);
      setError('Der opstod en fejl ved hentning af afdelinger');
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDialog = (department = null) => {
    setCurrentDepartment(department ? 
      { ...department } : 
      { name: '', isVisible: true, isVisibleInShiftPlanning: true }
    );
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentDepartment(null);
    setDepartmentName('');
  };

  const handleSaveDepartment = async (department) => {
    try {
      const departmentData = {
        name: department.name,
        isVisibleInShiftPlanning: department.isVisibleInShiftPlanning ? 1 : 0
      };

      if (department.id) {
        departmentData.id = department.id;
        await api.put(`/departments.php/${department.id}`, departmentData);
      } else {
        await api.post('/departments.php', departmentData);
      }

      await fetchDepartments();
      handleCloseDialog();
    } catch (error) {
      console.error('Error saving department:', error);
      setError('Der opstod en fejl ved gemning af afdeling');
    }
  };

  const handleSubmit = () => {
    const departmentToSave = {
      name: currentDepartment.name,
      isVisibleInShiftPlanning: currentDepartment.isVisibleInShiftPlanning || false
    };

    if (currentDepartment.id) {
      departmentToSave.id = currentDepartment.id;
    }

    handleSaveDepartment(departmentToSave);
  };

  const handleDeleteDepartment = async (id) => {
    if (window.confirm('Er du sikker på, at du vil slette denne afdeling?')) {
      try {
        await api.delete(`/departments.php/${id}`);
        fetchDepartments();
      } catch (error) {
        console.error('Error deleting department:', error);
        setError('Der opstod en fejl ved sletning af afdeling');
      }
    }
  };

  const handleVisibilityToggle = async (departmentId, field, currentValue) => {
    try {
      console.log('Toggling visibility:', { departmentId, field, currentValue });
      
      const updateData = {
        id: departmentId
      };

      if (field === 'isVisibleInShiftPlanning') {
        updateData.isVisibleInShiftPlanning = currentValue ? 0 : 1;
      } else if (field === 'isVisible') {
        updateData.isVisible = currentValue ? 0 : 1;
      }

      console.log('Sending update:', updateData);
      
      await api.put(`/departments.php/${departmentId}`, updateData);
      await fetchDepartments();
    } catch (error) {
      console.error('Error updating department visibility:', error);
      setError('Der opstod en fejl ved opdatering af afdelingens synlighed');
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ mb: 2 }}>
        {error}
      </Alert>
    );
  }

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>Afdelingsstyring</Typography>
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => handleOpenDialog()}
        sx={{ mb: 2 }}
      >
        Tilføj Afdeling
      </Button>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Afdelingsnavn</TableCell>
              <TableCell>Synlig i vedligeholdelse</TableCell>
              <TableCell>Synlig i vagtplan</TableCell>
              <TableCell>Handlinger</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(departments) && departments.map((department) => (
              <TableRow key={department.id}>
                <TableCell>{department.name}</TableCell>
                <TableCell>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={Boolean(department.isVisible)}
                        onChange={() => handleVisibilityToggle(
                          department.id,
                          'isVisible',
                          department.isVisible
                        )}
                      />
                    }
                    label=""
                  />
                </TableCell>
                <TableCell>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={Boolean(department.isVisibleInShiftPlanning)}
                        onChange={() => handleVisibilityToggle(
                          department.id,
                          'isVisibleInShiftPlanning',
                          department.isVisibleInShiftPlanning
                        )}
                      />
                    }
                    label=""
                  />
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleOpenDialog(department)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteDepartment(department.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {currentDepartment?.id ? 'Rediger Afdeling' : 'Tilføj Ny Afdeling'}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Afdelingsnavn"
            type="text"
            fullWidth
            value={currentDepartment?.name || ''}
            onChange={(e) => setCurrentDepartment(prev => ({ ...prev, name: e.target.value }))}
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={Boolean(currentDepartment?.isVisible)}
                  onChange={(e) => setCurrentDepartment(prev => ({ 
                    ...prev, 
                    isVisible: e.target.checked 
                  }))}
                  color="primary"
                />
              }
              label="Synlig i vedligeholdelse"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={Boolean(currentDepartment?.isVisibleInShiftPlanning)}
                  onChange={(e) => setCurrentDepartment(prev => ({ 
                    ...prev, 
                    isVisibleInShiftPlanning: e.target.checked 
                  }))}
                  color="primary"
                />
              }
              label="Synlig i vagtplan"
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annuller</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Gem
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default DepartmentManagement;
