import React, { useState, useEffect } from 'react';
import {
  Paper, Typography, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  IconButton, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem,
  Box, CircularProgress, Alert, Tooltip, Chip, Grid, Tab, Tabs
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, GetApp as DownloadIcon } from '@mui/icons-material';
import api from '../../utils/apiUtils';

// Stats Card Component
const StatsCard = ({ title, value, color = "primary" }) => (
  <Paper sx={{ p: 2, textAlign: 'center' }}>
    <Typography variant="subtitle2" color="textSecondary">
      {title}
    </Typography>
    <Typography variant="h4" color={color}>
      {value}
    </Typography>
  </Paper>
);

const InventoryManagement = () => {
  const [inventory, setInventory] = useState([]);
  const [machines, setMachines] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);

  const [formData, setFormData] = useState({
    name: '',
    machineIds: [],
    partNumber: '',
    quantity: 0,
    minQuantity: 0,
    location: '',
    description: '',
    supplier: '',
    lastOrdered: ''
  });

  useEffect(() => {
    fetchInventory();
    fetchMachines();
  }, []);

  const fetchInventory = async () => {
    try {
      setLoading(true);
      const response = await api.get('/inventory', { withCredentials: true });
      if (response.data?.success) {
        console.log('Received inventory data:', response.data.data);
        setInventory(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching inventory:', error);
      setError('Der opstod en fejl ved hentning af inventar');
    } finally {
      setLoading(false);
    }
  };

  const fetchMachines = async () => {
    try {
      const response = await api.get('/machines', { withCredentials: true });
      if (response.data?.success) {
        setMachines(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching machines:', error);
    }
  };

  const handleOpenDialog = (item = null) => {
    if (item) {
      console.log('Opening dialog with item:', item);
      setCurrentItem(item);
      setFormData({
        name: item.name || '',
        machineIds: item.machines?.map(m => m.id.toString()) || [],
        partNumber: item.partNumber || '',
        quantity: item.quantity || 0,
        minQuantity: item.minQuantity || 0,
        location: item.location || '',
        description: item.description || '',
        supplier: item.supplier || '',
        lastOrdered: item.lastOrdered || ''
      });
    } else {
      setCurrentItem(null);
      setFormData({
        name: '',
        machineIds: [],
        partNumber: '',
        quantity: 0,
        minQuantity: 0,
        location: '',
        description: '',
        supplier: '',
        lastOrdered: ''
      });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentItem(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log('Input change:', { name, value });
    
    if (name === 'machineIds') {
      console.log('Updating machine IDs:', value);
      setFormData(prev => ({
        ...prev,
        [name]: Array.isArray(value) ? value.map(String) : []
      }));
    } else if (name === 'lastOrdered') {
      setFormData(prev => ({
        ...prev,
        [name]: value || ''
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleSubmit = async () => {
    try {
      const method = currentItem ? 'put' : 'post';
      const url = currentItem 
        ? `/inventory/${currentItem.id}`
        : `/inventory`;

      await api({
        method,
        url,
        data: formData,
        withCredentials: true
      });

      fetchInventory();
      handleCloseDialog();
    } catch (error) {
      console.error('Error saving inventory item:', error);
      setError('Der opstod en fejl ved gemning af varen');
    }
  };

  const handleDeleteItem = async (id) => {
    if (window.confirm('Er du sikker på, at du vil slette denne vare?')) {
      try {
        await api.delete(`/inventory/${id}`, { withCredentials: true });
        fetchInventory();
      } catch (error) {
        console.error('Error deleting inventory item:', error);
        setError('Der opstod en fejl ved sletning af varen');
      }
    }
  };

  const getLowStockItems = () => {
    return inventory.filter(item => item.quantity <= item.minQuantity);
  };

  const generateInventoryReport = async () => {
    try {
      const response = await api.get('/inventory.php', {
        params: { report: true },
        responseType: 'blob'
      });
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'inventory-report.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error generating report:', error);
      setError('Der opstod en fejl ved generering af rapport');
    }
  };

  const renderInventoryList = () => (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">Reservedele & Inventar</Typography>
        <Box display="flex" gap={2}>
          <Button
            variant="contained"
            startIcon={<DownloadIcon />}
            onClick={generateInventoryReport}
          >
            Generer Rapport
          </Button>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => handleOpenDialog()}
          >
            Tilføj Vare
          </Button>
        </Box>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Navn</TableCell>
              <TableCell>Maskiner</TableCell>
              <TableCell>Varenummer</TableCell>
              <TableCell align="right">Antal</TableCell>
              <TableCell align="right">Min. Antal</TableCell>
              <TableCell>Placering</TableCell>
              <TableCell>Leverandør</TableCell>
              <TableCell>Handlinger</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inventory.map((item) => (
              <TableRow 
                key={item.id}
                sx={{
                  backgroundColor: item.quantity <= item.minQuantity ? '#fff3e0' : 'inherit'
                }}
              >
                <TableCell>{item.name}</TableCell>
                <TableCell>
                  {Array.isArray(item.machines) && item.machines.length > 0 
                    ? item.machines.map(m => m.name).join(', ') 
                    : '-'}
                </TableCell>
                <TableCell>{item.partNumber}</TableCell>
                <TableCell align="right">{item.quantity}</TableCell>
                <TableCell align="right">{item.minQuantity}</TableCell>
                <TableCell>{item.location}</TableCell>
                <TableCell>{item.supplier}</TableCell>
                <TableCell>
                  <Tooltip title="Rediger">
                    <IconButton onClick={() => handleOpenDialog(item)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Slet">
                    <IconButton onClick={() => handleDeleteItem(item.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

  const renderDashboard = () => (
    <>
      <Typography variant="h6" gutterBottom>Inventar Oversigt</Typography>
      
      <Grid container spacing={3} mb={4}>
        <Grid item xs={12} sm={6} md={4}>
          <StatsCard
            title="Totale varenumre"
            value={inventory.length}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatsCard
            title="Varer med lav beholdning"
            value={getLowStockItems().length}
            color="warning"
          />
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom>Varer med lav beholdning</Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Navn</TableCell>
              <TableCell>Maskine</TableCell>
              <TableCell align="right">Antal</TableCell>
              <TableCell align="right">Min. Antal</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getLowStockItems().map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.name}</TableCell>
                <TableCell>
                  {item.machines ? item.machines.map(m => m.name).join(', ') : '-'}
                </TableCell>
                <TableCell align="right">{item.quantity}</TableCell>
                <TableCell align="right">{item.minQuantity}</TableCell>
                <TableCell>
                  <Chip 
                    label={item.quantity === 0 ? "Ikke på lager" : "Lav beholdning"} 
                    color={item.quantity === 0 ? "error" : "warning"}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Paper sx={{ p: 2 }}>
      <Tabs 
        value={selectedTab} 
        onChange={(e, newValue) => setSelectedTab(newValue)}
        sx={{ mb: 3 }}
      >
        <Tab label="Oversigt" />
        <Tab label="Vareliste" />
      </Tabs>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {selectedTab === 0 ? renderDashboard() : renderInventoryList()}

      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>
          {currentItem ? 'Rediger Vare' : 'Tilføj Ny Vare'}
        </DialogTitle>
        <DialogContent>
          <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2} sx={{ mt: 2 }}>
            <TextField
              label="Navn"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              fullWidth
              required
            />
            <FormControl fullWidth>
              <InputLabel>Maskiner</InputLabel>
              <Select
                multiple
                name="machineIds"
                value={formData.machineIds || []}
                onChange={handleInputChange}
                label="Maskiner"
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => {
                      const machine = machines.find(m => m.id.toString() === value);
                      return machine ? (
                        <Chip
                          key={value}
                          label={machine.name}
                          onDelete={() => {
                            setFormData(prev => ({
                              ...prev,
                              machineIds: prev.machineIds.filter(id => id !== value)
                            }));
                          }}
                        />
                      ) : null;
                    })}
                  </Box>
                )}
              >
                {machines.map((machine) => (
                  <MenuItem key={machine.id} value={machine.id.toString()}>
                    {machine.name} ({machine.afdeling})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Varenummer"
              name="partNumber"
              value={formData.partNumber}
              onChange={handleInputChange}
              fullWidth
            />
            <TextField
              label="Antal"
              name="quantity"
              type="number"
              value={formData.quantity}
              onChange={handleInputChange}
              fullWidth
              required
            />
            <TextField
              label="Minimum Antal"
              name="minQuantity"
              type="number"
              value={formData.minQuantity}
              onChange={handleInputChange}
              fullWidth
            />
            <TextField
              label="Placering"
              name="location"
              value={formData.location}
              onChange={handleInputChange}
              fullWidth
            />
            <TextField
              label="Leverandør"
              name="supplier"
              value={formData.supplier}
              onChange={handleInputChange}
              fullWidth
            />
            <TextField
              label="Sidst bestilt"
              name="lastOrdered"
              type="date"
              value={formData.lastOrdered || ''}
              onChange={handleInputChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
              inputProps={{
                min: "2000-01-01",
                max: "2100-12-31"
              }}
            />
            <TextField
              label="Beskrivelse"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              fullWidth
              multiline
              rows={4}
              sx={{ gridColumn: '1 / -1' }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annuller</Button>
          <Button onClick={handleSubmit} variant="contained">
            {currentItem ? 'Opdater' : 'Tilføj'}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default InventoryManagement; 