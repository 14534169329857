import React, { useState, useEffect } from 'react';
import {
  Paper, Typography, Button, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogContent,
  DialogActions, TextField, Select, MenuItem, FormControl, InputLabel,
  Box, CircularProgress, Alert, Card, CardContent, Grid, Tooltip,
  Chip
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, PersonRemove as RemovePersonIcon } from '@mui/icons-material';
import api from '../../utils/apiUtils';

const weekDays = ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'];

const defaultSchedule = weekDays.map(() => ({ meetingTime: '', endTime: '' }));

const predefinedColors = [
  '#3f51b5', // Søndag - Tirsdag team color
  '#4caf50', // Onsdag - Fredag team color
  '#795548', // Daghold team color
  '#f44336', // Additional colors
  '#2196f3',
  '#ff9800'
];

const TeamManagement = () => {
  const [teams, setTeams] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentTeam, setCurrentTeam] = useState(null);

  const [formData, setFormData] = useState({
    name: '',
    shiftType: 'day',
    color: '#3f51b5',
    members: [],
    schedule: defaultSchedule
  });

  useEffect(() => {
    fetchTeams();
    fetchUsers();
  }, []);

  const fetchTeams = async () => {
    try {
      const response = await api.get('/teams.php');
      console.log('Teams response:', response);
      if (response.data?.success) {
        setTeams(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching teams:', error);
      setError('Der opstod en fejl ved hentning af teams');
    } finally {
      setLoading(false);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await api.get('/contacts.php');
      console.log('Contacts response:', response);
      if (response.data?.success) {
        const schedulableContacts = response.data.data.filter(contact => contact.can_be_scheduled);
        setUsers(schedulableContacts);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleOpenDialog = (team = null) => {
    if (team) {
      console.log('Opening dialog with team:', team);
      setCurrentTeam(team);
      setFormData({
        name: team.name,
        shiftType: team.shiftType,
        color: team.color,
        members: team.members?.map(m => parseInt(m.id)) || [],
        schedule: team.schedule || defaultSchedule
      });
    } else {
      setCurrentTeam(null);
      setFormData({
        name: '',
        shiftType: 'day',
        color: '#3f51b5',
        members: [],
        schedule: defaultSchedule
      });
    }
    setOpenDialog(true);
  };

  const handleScheduleChange = (dayIndex, field, value) => {
    setFormData(prev => {
      const newSchedule = [...prev.schedule];
      newSchedule[dayIndex] = {
        ...newSchedule[dayIndex],
        [field]: value
      };
      return { ...prev, schedule: newSchedule };
    });
  };

  const handleSubmit = async () => {
    try {
      console.log('Submitting team data:', formData);
      const method = currentTeam ? 'put' : 'post';
      const url = currentTeam ? `/teams.php/${currentTeam.id}` : '/teams.php';

      const dataToSend = {
        ...formData,
        members: formData.members.map(memberId => parseInt(memberId)),
        schedule: formData.schedule.map(day => ({
          meetingTime: day.meetingTime || '',
          endTime: day.endTime || ''
        }))
      };

      console.log('Sending data:', dataToSend);

      const response = await api[method](url, dataToSend);

      if (response.data?.success) {
        await fetchTeams();
        setOpenDialog(false);
      } else {
        throw new Error(response.data?.error || 'Failed to save team');
      }
    } catch (error) {
      console.error('Error saving team:', error);
      setError('Der opstod en fejl ved gemning af team');
    }
  };

  const handleDeleteTeam = async (id) => {
    if (window.confirm('Er du sikker på, at du vil slette dette team?')) {
      try {
        await api.delete(`/teams.php/${id}`);
        await fetchTeams();
      } catch (error) {
        console.error('Error deleting team:', error);
        setError('Der opstod en fejl ved sletning af team');
      }
    }
  };

  const handleRemoveMember = async (teamId, memberId) => {
    try {
      if (window.confirm('Er du sikker på, at du vil fjerne dette medlem fra teamet?')) {
        const team = teams.find(t => t.id === teamId);
        if (!team) return;

        const updatedMembers = team.members.filter(m => m.id !== memberId);

        await api.put(`/teams.php/${teamId}`, {
          ...team,
          members: updatedMembers.map(m => m.id),
          schedule: team.schedule,
          shiftType: team.shiftType,
          color: team.color
        });

        await fetchTeams();
      }
    } catch (error) {
      console.error('Error removing team member:', error);
      setError('Der opstod en fejl ved fjernelse af teammedlem');
    }
  };

  const renderTeamTable = () => (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Navn</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Medlemmer</TableCell>
            <TableCell>Mødetider</TableCell>
            <TableCell>Handlinger</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {teams.map((team) => (
            <TableRow key={team.id} sx={{ borderLeft: `4px solid ${team.color}` }}>
              <TableCell>{team.name}</TableCell>
              <TableCell>{team.shiftType === 'day' ? 'Daghold' : 'Nathold'}</TableCell>
              <TableCell>
                {team.members?.map(member => (
                  <Chip
                    key={member.id}
                    label={member.name}
                    onDelete={() => handleRemoveMember(team.id, member.id)}
                    deleteIcon={
                      <Tooltip title="Fjern fra team">
                        <RemovePersonIcon />
                      </Tooltip>
                    }
                    sx={{ m: 0.5 }}
                  />
                ))}
              </TableCell>
              <TableCell>
                <Table size="small">
                  <TableBody>
                    {team.schedule.map((day, index) => (
                      <TableRow key={index}>
                        <TableCell>{weekDays[index]}</TableCell>
                        <TableCell sx={{ 
                          color: isTimeEmpty(day.meetingTime) ? 'text.disabled' : 'text.primary' 
                        }}>
                          {day.meetingTime || '-'}
                        </TableCell>
                        <TableCell sx={{ 
                          color: isTimeEmpty(day.endTime) ? 'text.disabled' : 'text.primary' 
                        }}>
                          {day.endTime || '-'}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableCell>
              <TableCell>
                <Tooltip title="Rediger team">
                  <IconButton onClick={() => handleOpenDialog(team)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Slet team">
                  <IconButton onClick={() => handleDeleteTeam(team.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const isTimeEmpty = (time) => !time || time === '';

  const renderDialog = () => (
    <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="md" fullWidth>
      <DialogTitle>{currentTeam ? 'Rediger Team' : 'Tilføj Nyt Team'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Team navn"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Type</InputLabel>
              <Select
                value={formData.shiftType}
                onChange={(e) => setFormData({ ...formData, shiftType: e.target.value })}
                label="Type"
              >
                <MenuItem value="day">Daghold</MenuItem>
                <MenuItem value="night">Nathold</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Farve</InputLabel>
              <Select
                value={formData.color}
                onChange={(e) => setFormData({ ...formData, color: e.target.value })}
                label="Farve"
              >
                {predefinedColors.map((color) => (
                  <MenuItem key={color} value={color}>
                    <Box sx={{ 
                      width: 20, 
                      height: 20, 
                      backgroundColor: color, 
                      marginRight: 1,
                      border: '1px solid rgba(0,0,0,0.1)'
                    }} />
                    {color}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Medlemmer</InputLabel>
              <Select
                multiple
                value={formData.members}
                onChange={(e) => setFormData({ ...formData, members: e.target.value })}
                label="Medlemmer"
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => {
                      const user = users.find(u => u.id === value);
                      return (
                        <Chip
                          key={value}
                          label={user?.name}
                          onDelete={() => {
                            setFormData(prev => ({
                              ...prev,
                              members: prev.members.filter(id => id !== value)
                            }));
                          }}
                        />
                      );
                    })}
                  </Box>
                )}
              >
                {users.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    {user.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>Mødetider</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Dag</TableCell>
                  <TableCell>Mødetid</TableCell>
                  <TableCell>Sluttid</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formData.schedule.map((day, index) => (
                  <TableRow key={index}>
                    <TableCell>{weekDays[index]}</TableCell>
                    <TableCell>
                      <TextField
                        type="time"
                        value={day.meetingTime}
                        onChange={(e) => handleScheduleChange(index, 'meetingTime', e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        sx={{
                          '& input': {
                            color: isTimeEmpty(day.meetingTime) ? 'text.disabled' : 'text.primary'
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="time"
                        value={day.endTime}
                        onChange={(e) => handleScheduleChange(index, 'endTime', e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        sx={{
                          '& input': {
                            color: isTimeEmpty(day.endTime) ? 'text.disabled' : 'text.primary'
                          }
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDialog(false)}>Annuller</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          {currentTeam ? 'Opdater' : 'Tilføj'}
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Paper sx={{ p: 2 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">Team Administration</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => handleOpenDialog()}
        >
          Tilføj Team
        </Button>
      </Box>

      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : (
        renderTeamTable()
      )}

      {renderDialog()}
    </Paper>
  );
};

export default TeamManagement;
