import React, { useState, useEffect } from 'react';
import {
  Grid, Paper, Typography, Box, CircularProgress,
  List, ListItem, ListItemText, Divider, Chip,
  Button, IconButton, FormControl, Select, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, Link } from 'react-router-dom';
import { Settings as SettingsIcon, PlayArrow as StartIcon, Stop as StopIcon, CheckCircle as CompleteIcon, Inventory as InventoryIcon } from '@mui/icons-material';
import axios from 'axios';
import { format } from 'date-fns';
import { da } from 'date-fns/locale';
import config from '../config';
import { useAuth } from '../contexts/AuthContext';
import DashboardSettings from './DashboardSettings';

const StyledCard = styled(Paper)(({ theme, type }) => ({
  height: '100%',
  padding: theme.spacing(3),
  backgroundColor: type === 'today' 
    ? theme.palette.mode === 'dark' 
      ? 'rgba(25, 118, 210, 0.15)'   // Darker blue for dark mode
      : 'rgba(25, 118, 210, 0.12)'   // Darker blue for light mode
    : type === 'recent'
    ? theme.palette.mode === 'dark'
      ? 'rgba(76, 175, 80, 0.15)'    // Darker green for dark mode
      : 'rgba(76, 175, 80, 0.12)'    // Darker green for light mode
    : type === 'vacation'
    ? theme.palette.mode === 'dark'
      ? 'rgba(156, 39, 176, 0.15)'   // Darker purple for dark mode
      : 'rgba(156, 39, 176, 0.12)'   // Darker purple for light mode
    : type === 'inventory'
    ? theme.palette.mode === 'dark'
      ? 'rgba(255, 152, 0, 0.15)'  // Orange for inventory
      : 'rgba(255, 152, 0, 0.12)'
    : theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  '& .MuiTypography-h6': {
    marginBottom: theme.spacing(2)
  },
  border: `1px solid ${
    type === 'today' 
      ? theme.palette.primary.main 
      : type === 'recent'
      ? theme.palette.success.main
      : type === 'vacation'
      ? theme.palette.secondary.main
      : type === 'inventory'
      ? theme.palette.warning.main
      : theme.palette.divider
  }`,
  borderColor: theme.palette.mode === 'dark'
    ? 'rgba(255, 255, 255, 0.1)'
    : 'rgba(0, 0, 0, 0.1)',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: type === 'today' 
      ? theme.palette.mode === 'dark' 
        ? 'rgba(25, 118, 210, 0.2)'   // Even darker on hover
        : 'rgba(25, 118, 210, 0.15)'
      : type === 'recent'
      ? theme.palette.mode === 'dark'
        ? 'rgba(76, 175, 80, 0.2)'
        : 'rgba(76, 175, 80, 0.15)'
      : type === 'vacation'
      ? theme.palette.mode === 'dark'
        ? 'rgba(156, 39, 176, 0.2)'
        : 'rgba(156, 39, 176, 0.15)'
      : type === 'inventory'
      ? theme.palette.mode === 'dark'
        ? 'rgba(255, 152, 0, 0.2)'
        : 'rgba(255, 152, 0, 0.15)'
      : theme.palette.background.default,
  }
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  '&:hover': {
    boxShadow: theme.shadows[2],
    backgroundColor: theme.palette.background.paper
  }
}));

const DashboardHome = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [vacations, setVacations] = useState([]);
  const { user } = useAuth();
  const isAdmin = Boolean(user?.isAdmin);
  const [settings, setSettings] = useState({
    showTasks: true,
    showVacations: false,
    showInventory: true,
    taskTypes: [],
    departments: []
  });
  const [openSettings, setOpenSettings] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState('both');
  const [completionDialog, setCompletionDialog] = useState(false);
  const [completionComment, setCompletionComment] = useState('');
  const [taskToComplete, setTaskToComplete] = useState(null);
  const [inventory, setInventory] = useState([]);

  useEffect(() => {
    fetchData();
    fetchSettings();
  }, [isAdmin, user.id]);

  const fetchSettings = async () => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/dashboard-settings.php?userId=${user.id}`,
        { withCredentials: true }
      );
      if (response.data?.success) {
        setSettings(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching dashboard settings:', error);
    }
  };

  const fetchData = async () => {
    try {
      const [maintenanceResponse, vacationsResponse, inventoryResponse] = await Promise.all([
        axios.get(`${config.apiUrl}/maintenance.php`),
        isAdmin ? axios.get(`${config.apiUrl}/vacations.php`) : Promise.resolve({ data: { success: true, data: [] } }),
        axios.get(`${config.apiUrl}/inventory.php`)
      ]);
      
      if (maintenanceResponse.data?.success) {
        setTasks(maintenanceResponse.data.data);
      }
      
      if (isAdmin && vacationsResponse?.data?.success) {
        setVacations(vacationsResponse.data.data);
      }
      
      if (inventoryResponse.data?.success) {
        setInventory(inventoryResponse.data.data);
      }
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    } finally {
      setLoading(false);
    }
  };

  // Filter today's tasks with date check
  const todaysTasks = tasks.filter(task => {
    // Only include tasks marked as today's tasks
    if (!task.is_today_task) return false;

    // Check if task is not completed and from a previous date
    const taskDate = new Date(task.created_at).setHours(0, 0, 0, 0);
    const today = new Date().setHours(0, 0, 0, 0);
    const isOldTask = taskDate < today;
    const isNotCompleted = task.status !== 'Afsluttet';

    // If task is old and not completed, it should be automatically moved to today
    if (isOldTask && isNotCompleted) {
      // You might want to update the task's date in the database here
      // For now, we'll just show it in today's tasks
      return true;
    }

    // For tasks created today, show them normally
    if (taskDate === today) {
      return true;
    }

    // Apply other filters
    if (!settings) return true;
    
    if (!settings.taskTypes || !Array.isArray(settings.taskTypes) || settings.taskTypes.length === 0) {
      return true;
    }

    if (!settings.departments || !Array.isArray(settings.departments) || settings.departments.length === 0) {
      return true;
    }

    const matchesType = settings.taskTypes.includes(task.taskType);
    const matchesDepartment = settings.departments.includes(task.afdeling);
    
    return matchesType || matchesDepartment;
  });

  // Add visual indicator for overdue tasks
  const getTaskStyle = (task) => {
    // Check if task has a forfaldsdato and it's overdue
    const dueDate = task.forfaldsdato ? new Date(task.forfaldsdato).setHours(0, 0, 0, 0) : null;
    const today = new Date().setHours(0, 0, 0, 0);
    const isOverdue = dueDate && dueDate < today && task.status !== 'Afsluttet';

    return isOverdue ? { 
      borderLeft: '4px solid #f44336',  // Red border for overdue tasks
      backgroundColor: 'rgba(244, 67, 54, 0.08)'  // Light red background
    } : {};
  };

  // Filter recent tasks (without today's task filter)
  const recentTasks = tasks.filter(task => {
    if (!settings) return true;
    
    if (!settings.taskTypes || !Array.isArray(settings.taskTypes) || settings.taskTypes.length === 0) {
      return true;
    }

    if (!settings.departments || !Array.isArray(settings.departments) || settings.departments.length === 0) {
      return true;
    }

    const matchesType = settings.taskTypes.includes(task.taskType);
    const matchesDepartment = settings.departments.includes(task.afdeling);
    
    return matchesType || matchesDepartment;
  });

  const getStatusColor = (status) => {
    switch (status) {
      case 'Afventer': return 'warning';
      case 'I gang': return 'info';
      case 'Afsluttet': return 'success';
      case 'Pending': return 'warning';
      case 'Approved': return 'success';
      case 'Rejected': return 'error';
      default: return 'default';
    }
  };

  const handleTaskClick = () => {
    navigate('/vedligeholdelse');
  };

  const handleVacationClick = () => {
    navigate('/admin', { state: { activeTab: 3 } });
  };

  // Common button style
  const commonButtonStyle = {
    textTransform: 'none',
    fontWeight: 500,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none'
    }
  };

  const handleStatusChange = async (taskId, newStatus) => {
    try {
      if (newStatus === 'Afsluttet') {
        // Open completion dialog instead of changing status directly
        setTaskToComplete(taskId);
        setCompletionDialog(true);
        return;
      }

      const response = await axios.put(
        `${config.apiUrl}/maintenance.php/${taskId}`,
        { status: newStatus },
        { withCredentials: true }
      );

      if (response.data?.success) {
        setTasks(prevTasks => prevTasks.map(task => 
          task.id === taskId ? { ...task, status: newStatus } : task
        ));
      }
    } catch (error) {
      console.error('Error updating task status:', error);
    }
  };

  const handleTaskCompletion = async () => {
    if (!completionComment.trim()) {
      return; // Don't allow empty comments
    }

    try {
      const response = await axios.put(
        `${config.apiUrl}/maintenance.php/${taskToComplete}`,
        { 
          status: 'Afsluttet',
          completionNote: completionComment 
        },
        { withCredentials: true }
      );

      if (response.data?.success) {
        setTasks(prevTasks => prevTasks.map(task => 
          task.id === taskToComplete ? { 
            ...task, 
            status: 'Afsluttet',
            completionNote: completionComment 
          } : task
        ));
        setCompletionDialog(false);
        setCompletionComment('');
        setTaskToComplete(null);
      }
    } catch (error) {
      console.error('Error completing task:', error);
    }
  };

  const StatusActions = ({ task }) => {
    switch (task.status) {
      case 'Afventer':
        return (
          <Tooltip title="Start opgave">
            <IconButton
              onClick={() => handleStatusChange(task.id, 'I gang')}
              color="primary"
              size="small"
            >
              <StartIcon />
            </IconButton>
          </Tooltip>
        );
      case 'I gang':
        return (
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Tooltip title="Sæt på pause">
              <IconButton
                onClick={() => handleStatusChange(task.id, 'Afventer')}
                color="warning"
                size="small"
              >
                <StopIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Afslut opgave">
              <IconButton
                onClick={() => handleStatusChange(task.id, 'Afsluttet')}
                color="success"
                size="small"
              >
                <CompleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        );
      case 'Afsluttet':
        return (
          <Tooltip title="Genåbn opgave">
            <IconButton
              onClick={() => handleStatusChange(task.id, 'Afventer')}
              color="primary"
              size="small"
            >
              <StartIcon />
            </IconButton>
          </Tooltip>
        );
      default:
        return null;
    }
  };

  const getLowStockItems = () => {
    return inventory.filter(item => item.quantity <= item.minQuantity);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">Dashboard</Typography>
        <IconButton onClick={() => setOpenSettings(true)}>
          <SettingsIcon />
        </IconButton>
      </Box>

      <Grid container spacing={3}>
        {/* Today's Tasks Section */}
        {(!settings || settings.showTasks) && (
          <Grid item xs={12}>
            <StyledCard elevation={0} type="today">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6">Dagens Opgaver</Typography>
                <Button 
                  variant="contained"
                  onClick={handleTaskClick}
                  sx={commonButtonStyle}
                >
                  Se alle opgaver
                </Button>
              </Box>
              <List sx={{ mt: 2 }}>
                {todaysTasks.length > 0 ? (
                  todaysTasks
                    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                    .slice(0, 5)
                    .map((task) => (
                      <StyledListItem 
                        key={task.id}
                        sx={{ 
                          ...getTaskStyle(task),
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        <Box sx={{ width: '100%', mr: 2 }}>
                          <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                            <Box>
                              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                                {task.title}
                                {getTaskStyle(task).borderLeft && (
                                  <Typography 
                                    component="span" 
                                    color="error" 
                                    sx={{ ml: 1, fontSize: '0.8rem' }}
                                  >
                                    (Overskredet forfaldsdato)
                                  </Typography>
                                )}
                              </Typography>
                              <Typography variant="body2" color="textSecondary" sx={{ mt: 0.5 }}>
                                {format(new Date(task.created_at), 'dd/MM/yyyy HH:mm', { locale: da })}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                {task.afdeling} - {task.maskine}
                              </Typography>
                              {task.team && (
                                <Typography variant="body2" color="textSecondary">
                                  Hold: {task.team === 'day' ? 'Daghold' : 
                                        task.team === 'night' ? 'Nathold' : 'Begge hold'}
                                </Typography>
                              )}
                            </Box>
                            <Box display="flex" alignItems="center" gap={1}>
                              <StatusActions task={task} />
                              <Chip
                                label={task.status}
                                color={getStatusColor(task.status)}
                                size="small"
                                sx={{ 
                                  fontWeight: 500,
                                  borderRadius: 1
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </StyledListItem>
                    ))
                ) : (
                  <Box sx={{ p: 2, textAlign: 'center' }}>
                    <Typography color="textSecondary">
                      Ingen opgaver for i dag
                    </Typography>
                  </Box>
                )}
              </List>
            </StyledCard>
          </Grid>
        )}

        {/* Recent Tasks Section */}
        {(!settings || settings.showTasks) && (
          <Grid item xs={12} md={isAdmin ? 6 : 12}>
            <StyledCard elevation={0} type="recent">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6">Seneste Opgaver</Typography>
                <Button 
                  variant="contained"
                  onClick={handleTaskClick}
                  sx={commonButtonStyle}
                >
                  Se alle opgaver
                </Button>
              </Box>
              <List sx={{ mt: 2 }}>
                {recentTasks.length > 0 ? (
                  recentTasks
                    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                    .slice(0, 5)
                    .map((task) => (
                      <StyledListItem key={task.id}>
                        <Box sx={{ width: '100%' }}>
                          <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                            <Box>
                              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                                {task.title}
                              </Typography>
                              <Typography variant="body2" color="textSecondary" sx={{ mt: 0.5 }}>
                                {format(new Date(task.created_at), 'dd/MM/yyyy HH:mm', { locale: da })}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                {task.afdeling} - {task.maskine}
                              </Typography>
                            </Box>
                            <Chip
                              label={task.status}
                              color={getStatusColor(task.status)}
                              size="small"
                              sx={{ 
                                fontWeight: 500,
                                borderRadius: 1
                              }}
                            />
                          </Box>
                        </Box>
                      </StyledListItem>
                    ))
                ) : (
                  <Box sx={{ p: 2, textAlign: 'center' }}>
                    <Typography color="textSecondary">
                      Ingen opgaver
                    </Typography>
                  </Box>
                )}
              </List>
            </StyledCard>
          </Grid>
        )}

        {/* Vacation Section */}
        {Boolean(isAdmin && settings?.showVacations) && (
          <Grid item xs={12} md={6}>
            <StyledCard elevation={0} type="vacation">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6">Seneste Ferieanmodninger</Typography>
                <Button 
                  variant="contained"
                  onClick={handleVacationClick}
                  sx={commonButtonStyle}
                >
                  Gå til Ferieanmodninger
                </Button>
              </Box>
              <List sx={{ mt: 2 }}>
                {vacations
                  .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                  .slice(0, 5)
                  .map((vacation) => (
                    <StyledListItem key={vacation.id}>
                      <Box sx={{ width: '100%' }}>
                        <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                          <Box>
                            <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                              {vacation.employeeName}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" sx={{ mt: 0.5 }}>
                              {format(new Date(vacation.startDate), 'dd/MM/yyyy')} - {format(new Date(vacation.endDate), 'dd/MM/yyyy')}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              Type: {vacation.type === 'vacation' ? 'Ferie' : 
                                    vacation.type === 'vacation_unpaid' ? 'Ferie uden løn' : 
                                    vacation.type === 'vacation_free' ? 'Ferie Fri' :
                                    vacation.type === 'afspadsering' ? 'Afspadsering' : 
                                    vacation.type}
                            </Typography>
                          </Box>
                          <Chip
                            label={vacation.status}
                            color={getStatusColor(vacation.status)}
                            size="small"
                            sx={{ 
                              fontWeight: 500,
                              borderRadius: 1
                            }}
                          />
                        </Box>
                      </Box>
                    </StyledListItem>
                  ))}
              </List>
            </StyledCard>
          </Grid>
        )}

        {/* Add Inventory Section here */}
        <Grid item xs={12} md={6}>
          <StyledCard elevation={0} type="inventory">
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" alignItems="center" gap={1}>
                <InventoryIcon color="warning" />
                <Typography variant="h6">Inventar Status</Typography>
              </Box>
              <Button
                variant="contained"
                color="warning"
                size="small"
                component={Link}
                to="/admin"
                state={{ activeTab: 'inventory' }}
                sx={commonButtonStyle}
              >
                Se alle
              </Button>
            </Box>

            <List sx={{ mt: 2 }}>
              {getLowStockItems()
                .slice(0, 5)
                .map((item) => (
                  <StyledListItem key={item.id}>
                    <Box sx={{ width: '100%' }}>
                      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                        <Box>
                          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                            {item.name}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Antal: {item.quantity} / Min: {item.minQuantity}
                          </Typography>
                        </Box>
                        <Chip
                          label={item.quantity === 0 ? "Ikke på lager" : "Lav beholdning"}
                          color={item.quantity === 0 ? "error" : "warning"}
                          size="small"
                        />
                      </Box>
                    </Box>
                  </StyledListItem>
                ))}
              {getLowStockItems().length === 0 && (
                <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'center', py: 2 }}>
                  Ingen varer med lav beholdning
                </Typography>
              )}
            </List>
          </StyledCard>
        </Grid>
      </Grid>

      <DashboardSettings
        open={openSettings}
        onClose={() => setOpenSettings(false)}
        userId={user.id}
        onSettingsChange={fetchSettings}
      />

      {/* Add Completion Dialog */}
      <Dialog 
        open={completionDialog} 
        onClose={() => {
          setCompletionDialog(false);
          setCompletionComment('');
          setTaskToComplete(null);
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Afslut opgave</DialogTitle>
        <DialogContent sx={{ minHeight: '300px' }}>
          <TextField
            autoFocus
            margin="dense"
            label="Hvad er der blevet lavet?"
            fullWidth
            multiline
            rows={8}
            value={completionComment}
            onChange={(e) => setCompletionComment(e.target.value)}
            error={completionComment.trim() === ''}
            helperText={completionComment.trim() === '' ? 'Kommentar er påkrævet' : ''}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => {
              setCompletionDialog(false);
              setCompletionComment('');
              setTaskToComplete(null);
            }}
            sx={commonButtonStyle}
          >
            Annuller
          </Button>
          <Button 
            onClick={handleTaskCompletion}
            variant="contained"
            disabled={!completionComment.trim()}
            sx={commonButtonStyle}
          >
            Afslut opgave
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DashboardHome; 