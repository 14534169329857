import React, { useState, useEffect, useMemo } from 'react';
import {
  Box, Typography, Grid, TextField, MenuItem, Checkbox, FormControlLabel, 
  Card, CardContent, CircularProgress,
  Dialog, DialogTitle, DialogContent, DialogActions, Button,
  FormControl, InputLabel, Select, Alert, Snackbar,
  Tabs, Tab, Paper, IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/da';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isoWeek from 'dayjs/plugin/isoWeek';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import updateLocale from 'dayjs/plugin/updateLocale';
import axios from 'axios';
import config from '../config';
import { useAuth } from '../contexts/AuthContext';
import api from '../utils/apiUtils';
import DeleteIcon from '@mui/icons-material/Delete';

// Configure dayjs
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);
dayjs.extend(isSameOrBefore);
dayjs.extend(updateLocale);
dayjs.locale('da');

// Update locale
dayjs.updateLocale('da', {
  weekStart: 0
});

// Styled Components
const WeekDayHeader = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  color: theme.palette.text.primary,
  fontWeight: 'bold',
}));

const ShiftCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'shiftType' && prop !== 'isIrregular' && prop !== 'bgcolor' && prop !== 'hasAbsence' && prop !== 'absenceType'
})(({ theme, shiftType, isIrregular, bgcolor, hasAbsence, absenceType }) => ({
  margin: '2px 0',
  backgroundColor: hasAbsence ? getAbsenceColor(absenceType) : (bgcolor || '#f5f5f5'),
  opacity: hasAbsence ? 0.9 : 1,
  transition: 'background-color 0.2s ease',
  '& .MuiCardContent-root': {
    padding: '8px',
    '&:last-child': {
      paddingBottom: '8px'
    }
  },
  '&:hover': {
    backgroundColor: hasAbsence ? getAbsenceColor(absenceType) : (bgcolor || '#e0e0e0'),
    opacity: 1
  }
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  minHeight: 48,
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  '& .MuiTabs-indicator': {
    display: 'none'
  }
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 500,
  fontSize: '0.9rem',
  minHeight: 48,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  borderRight: `1px solid ${theme.palette.divider}`,
  '&.Mui-selected': {
    backgroundColor: theme.palette.action.selected,
    color: theme.palette.text.primary,
    fontWeight: 600
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover
  },
  '&:last-child': {
    borderRight: 'none'
  }
}));

// Update the VacationDialog component
const VacationDialog = ({ open, onClose, onSubmit }) => {
  const [formData, setFormData] = useState({
    employeeId: '',
    startDate: null,
    endDate: null,
    type: '',
    comment: ''
  });

  const [contacts, setContacts] = useState([]);

  // Fetch contacts when dialog opens
  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await api.get('/contacts.php');
        if (response.data?.success) {
          const schedulableContacts = response.data.data.filter(
            contact => contact.can_be_scheduled === 1
          );
          setContacts(schedulableContacts);
        }
      } catch (error) {
        console.error('Error fetching contacts:', error);
      }
    };

    if (open) {
      fetchContacts();
      setFormData({
        employeeId: '',
        startDate: null,
        endDate: null,
        type: '',
        comment: ''
      });
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Anmod om fravær</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
          <FormControl fullWidth>
            <InputLabel>Medarbejder</InputLabel>
            <Select
              value={formData.employeeId}
              onChange={(e) => setFormData({ ...formData, employeeId: e.target.value })}
              label="Medarbejder"
            >
              {contacts.map((contact) => (
                <MenuItem key={contact.id} value={contact.id}>
                  {contact.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start dato"
              value={formData.startDate}
              onChange={(newValue) => setFormData({ ...formData, startDate: newValue })}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
            <DatePicker
              label="Slut dato"
              value={formData.endDate}
              onChange={(newValue) => setFormData({ ...formData, endDate: newValue })}
              minDate={formData.startDate}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>

          <FormControl fullWidth>
            <InputLabel>Type</InputLabel>
            <Select
              value={formData.type}
              onChange={(e) => setFormData({ ...formData, type: e.target.value })}
              label="Type"
            >
              <MenuItem value="vacation">Ferie</MenuItem>
              <MenuItem value="vacation_unpaid">Ferie uden løn</MenuItem>
              <MenuItem value="vacation_free">Ferie Fri</MenuItem>
              <MenuItem value="afspadsering">Afspadsering</MenuItem>
            </Select>
          </FormControl>

          <TextField
            label="Bemærkning"
            multiline
            rows={4}
            value={formData.comment}
            onChange={(e) => setFormData({ ...formData, comment: e.target.value })}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annuller</Button>
        <Button 
          onClick={() => onSubmit(formData)} 
          variant="contained" 
          disabled={!formData.employeeId || !formData.startDate || !formData.endDate || !formData.type}
        >
          Gem
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Helper Functions
const formatTime = (time) => {
  if (!time) return '';
  return time.substring(0, 5); // Take only HH:mm part
};

const getVacationTypeDisplay = (type) => {
  switch (type) {
    case 'sick':
      return 'Syg';
    case 'barn_syg':
      return 'Barn syg';
    case 'vacation':
      return 'Ferie';
    case 'vacation_unpaid':
      return 'Ferie uden løn';
    case 'vacation_free':
      return 'Ferie Fri';
    case 'afspadsering':
      return 'Afspadsering';
    case 'indisponibel':
      return 'Indisponibel';
    case 'fri_helligdag':
      return 'Fri helligdag';
    default:
      return type;
  }
};

const getAbsenceColor = (type) => {
  switch (type) {
    case 'sick':
      return '#ffcdd2'; // Light red for sick
    case 'barn_syg':
      return '#fff9c4'; // Light yellow for sick child
    case 'vacation':
      return '#c8e6c9'; // Light green for vacation
    case 'vacation_unpaid':
      return '#b2dfdb'; // Light teal for unpaid vacation
    case 'vacation_free':
      return '#bbdefb'; // Light blue for free vacation
    case 'afspadsering':
      return '#e1bee7'; // Light purple for time off
    case 'indisponibel':
      return '#cfd8dc'; // Light grey for unavailable
    case 'fri_helligdag':
      return '#ffccbc'; // Light orange for holiday
    default:
      return '#f5f5f5'; // Default grey
  }
};

// Add this function near your other helper functions
const generateTeamShifts = (teams, week, year, isRange, startWeek, endWeek) => {
  const shifts = [];
  const startDate = isRange 
    ? dayjs().year(year).week(startWeek).startOf('week')
    : dayjs().year(year).week(week).startOf('week');
  const endDate = isRange
    ? dayjs().year(year).week(endWeek).endOf('week')
    : dayjs().year(year).week(week).endOf('week');

  teams.forEach(team => {
    if (!team.members || !team.schedule) return;

    team.members.forEach(member => {
      let currentDate = startDate.clone();
      while (currentDate.isSameOrBefore(endDate)) {
        const dayOfWeek = currentDate.day();
        const daySchedule = team.schedule[dayOfWeek];

        if (daySchedule && daySchedule.meetingTime && daySchedule.endTime) {
          shifts.push({
            id: `team_${team.id}_${member.id}_${currentDate.format('YYYY-MM-DD')}`,
            contact_id: member.id,
            contact_name: member.name,
            teamId: team.id,
            team_name: team.name,
            team_color: team.color,
            shiftType: team.shiftType,
            date: currentDate.format('YYYY-MM-DD'),
            startTime: daySchedule.meetingTime,
            endTime: daySchedule.endTime,
            isTeamShift: true
          });
        }
        currentDate = currentDate.add(1, 'day');
      }
    });
  });

  return shifts;
};

// The VacationDialog, ShiftPlanningComponent, etc. remain the same

const ShiftPlanningComponent = () => {
  // State
  const [selectedWeek, setSelectedWeek] = useState(dayjs().week());
  const [selectedYear, setSelectedYear] = useState(dayjs().year());
  const [shifts, setShifts] = useState([]);
  const [teams, setTeams] = useState([]);
  const [users, setUsers] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [absences, setAbsences] = useState([]);
  const [selectedStartWeek, setSelectedStartWeek] = useState(dayjs().week());
  const [selectedEndWeek, setSelectedEndWeek] = useState(dayjs().week());
  const [isRangeMode, setIsRangeMode] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState('all');
  const [openShiftDialog, setOpenShiftDialog] = useState(false);
  const [openAbsenceDialog, setOpenAbsenceDialog] = useState(false);
  const [currentShift, setCurrentShift] = useState(null);
  const [currentAbsence, setCurrentAbsence] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const { user } = useAuth();

  // Initialize availableYears
  const currentYear = dayjs().year();
  const [availableYears, setAvailableYears] = useState([
    currentYear - 1, currentYear, currentYear + 1, currentYear + 2, currentYear + 3
  ]);

  // Add state for tracking if contacts are loaded
  const [contactsLoaded, setContactsLoaded] = useState(false);

  // Helper functions
  const getWeekDates = (week, year) => {
    const firstDayOfYear = dayjs().year(year).startOf('year');
    const firstWeek = firstDayOfYear.add(3, 'day').week();
    const weeksToAdd = week - firstWeek;
    const weekStart = firstDayOfYear.add(weeksToAdd, 'week').startOf('week');
    
    return Array(7).fill().map((_, i) => weekStart.add(i, 'day'));
  };

  const isUserAbsent = (userId, date) => {
    return absences.find(absence => 
      absence.userId === userId &&
      dayjs(date).isBetween(dayjs(absence.startDate), dayjs(absence.endDate), null, '[]')
    );
  };

  // API calls
  const fetchShifts = async () => {
    try {
      const startWeek = isRangeMode ? selectedStartWeek : selectedWeek;
      const endWeek = isRangeMode ? selectedEndWeek : selectedWeek;
      
      const startDate = dayjs().year(selectedYear).week(startWeek).startOf('week').format('YYYY-MM-DD');
      const endDate = dayjs().year(selectedYear).week(endWeek).endOf('week').format('YYYY-MM-DD');
      
      console.log('Fetching shifts for range:', {
        startWeek,
        endWeek,
        startDate,
        endDate
      });

      // First fetch teams to get their schedules
      const teamsResponse = await api.get('/teams.php');
      if (teamsResponse.data?.success) {
        const teamsData = teamsResponse.data.data;
        setTeams(teamsData);

        // Then fetch manual shifts
        const shiftsResponse = await api.get('/shifts.php', {
          params: { 
            startDate,
            endDate
          }
        });

        // Get manual shifts
        const manualShifts = shiftsResponse.data?.success ? shiftsResponse.data.data : [];
        
        // Generate team shifts for the entire date range
        const generatedShifts = generateTeamShifts(
          teamsData, 
          startWeek,
          selectedYear, 
          isRangeMode,
          startWeek,
          endWeek
        );

        // Combine all shifts
        const allShifts = [...manualShifts, ...generatedShifts];
        
        console.log('All shifts:', allShifts);
        setShifts(allShifts);
      }
    } catch (error) {
      console.error('Error fetching shifts:', error);
      setError('Der opstod en fejl ved hentning af vagter');
    }
  };

  // Update the fetchUsers function
  const fetchUsers = async () => {
    try {
      const response = await api.get('/contacts.php');
      if (response.data?.success) {
        const schedulableContacts = response.data.data.filter(contact => contact.can_be_scheduled === 1);
        console.log('Loaded schedulable contacts:', schedulableContacts);
        setContacts(schedulableContacts);
        setContactsLoaded(true);
      }
    } catch (error) {
      console.error('Error fetching contacts:', error);
    }
  };

  const fetchTeams = async () => {
    try {
        const response = await api.get('/teams.php');
        if (response.data?.success) {
            setTeams(response.data.data);
        }
    } catch (error) {
        console.error('Error fetching teams:', error);
    }
  };

  const fetchDepartments = async () => {
    try {
      const response = await api.get('/departments.php');
      if (response.data?.success) {
        console.log('Fetched departments:', response.data.data);  // Debug log
        // Only set visible departments
        const visibleDepartments = response.data.data.filter(dept => dept.isVisibleInShiftPlanning === 1);
        setDepartments(visibleDepartments);
      }
    } catch (error) {
      console.error('Error fetching departments:', error);
      setError('Error fetching departments');
    }
  };

  // Update the initial data loading useEffect
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        // Load contacts first
        const contactsResponse = await api.get('/contacts.php');
        let schedulableContacts = [];
        if (contactsResponse.data?.success) {
          schedulableContacts = contactsResponse.data.data.filter(
            contact => contact.can_be_scheduled === 1
          );
          setContacts(schedulableContacts);
        }

        // Then load teams and departments
        const [teamsResponse, departmentsResponse] = await Promise.all([
          api.get('/teams.php'),
          api.get('/departments.php')
        ]);

        if (teamsResponse.data?.success) {
          setTeams(teamsResponse.data.data);
        }

        if (departmentsResponse.data?.success) {
          const visibleDepartments = departmentsResponse.data.data.filter(
            dept => dept.isVisibleInShiftPlanning === 1
          );
          setDepartments(visibleDepartments);
        }

        // Finally load shifts and absences
        const startWeek = isRangeMode ? selectedStartWeek : selectedWeek;
        const endWeek = isRangeMode ? selectedEndWeek : selectedWeek;
        
        const startDate = dayjs().year(selectedYear).week(startWeek).startOf('week').format('YYYY-MM-DD');
        const endDate = dayjs().year(selectedYear).week(endWeek).endOf('week').format('YYYY-MM-DD');

        // Load absences with the loaded contacts
        const [absencesResponse, vacationAbsences] = await Promise.all([
          api.get('/absences.php', {
            params: {
              startDate,
              endDate
            }
          }),
          fetchVacationRequests()
        ]);

        if (absencesResponse.data?.success) {
          const regularAbsences = absencesResponse.data.data.map(absence => {
            const contact = schedulableContacts.find(c => c.id === parseInt(absence.contact_id));
            console.log('Processing absence:', { 
              absence_id: absence.contact_id, 
              contact_found: contact ? contact.name : 'not found',
              all_contacts: schedulableContacts.map(c => ({ id: c.id, name: c.name }))
            });
            return {
              ...absence,
              contact_name: contact?.name,
              startDate: dayjs(absence.startDate).format('YYYY-MM-DD'),
              endDate: dayjs(absence.endDate).format('YYYY-MM-DD')
            };
          });

          const processedVacationAbsences = vacationAbsences.map(absence => {
            const contact = schedulableContacts.find(c => c.id === parseInt(absence.contact_id));
            console.log('Processing vacation:', { 
              absence_id: absence.contact_id, 
              contact_found: contact ? contact.name : 'not found',
              all_contacts: schedulableContacts.map(c => ({ id: c.id, name: c.name }))
            });
            return {
              ...absence,
              contact_name: contact?.name,
              startDate: dayjs(absence.startDate).format('YYYY-MM-DD'),
              endDate: dayjs(absence.endDate).format('YYYY-MM-DD')
            };
          });

          setAbsences([...regularAbsences, ...processedVacationAbsences]);
        }

        // Load shifts
        await fetchShifts();
      } catch (error) {
        console.error('Error loading data:', error);
        setError('Der opstod en fejl ved indlæsning af data');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [selectedWeek, selectedYear, selectedStartWeek, selectedEndWeek, isRangeMode, selectedDepartment]);

  // Render functions
  const renderShiftPlanning = () => {
    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">{error}</Typography>;

    const weekRange = isRangeMode 
      ? Array.from(
          { length: selectedEndWeek - selectedStartWeek + 1 }, 
          (_, i) => parseInt(selectedStartWeek) + i
        )
      : [selectedWeek];

    // Filter shifts based on selected department
    const filteredShifts = shifts.filter(shift => {
      const contact = contacts.find(c => c.id === parseInt(shift.contact_id));
      return selectedDepartment === 'all' || contact?.department === selectedDepartment;
    });

    // Filter absences based on selected department
    const filteredAbsences = absences.filter(absence => {
      const contact = contacts.find(c => c.id === parseInt(absence.contact_id));
      return selectedDepartment === 'all' || contact?.department === selectedDepartment;
    });

    console.log('Render data:', {
      weekRange,
      filteredShifts: filteredShifts.length,
      filteredAbsences: filteredAbsences.length,
      allAbsences: absences.length
    });

    return (
      <>
        {weekRange.map((week) => (
          <Box key={`week-${week}`} mb={4}>
            <Typography variant="h6" gutterBottom>
              Uge {week} ({dayjs().year(selectedYear).week(week).startOf('week').format('DD/MM')} 
              - {dayjs().year(selectedYear).week(week).endOf('week').format('DD/MM/YYYY')})
            </Typography>
            <Grid container spacing={1}>
              {getWeekDates(week, selectedYear).map((date, index) => {
                const dateString = date.format('YYYY-MM-DD');
                
                // Get absences for this date
                const dateAbsences = filteredAbsences.filter(absence => {
                  const currentDate = dayjs(dateString);
                  const absenceStart = dayjs(absence.startDate);
                  const absenceEnd = dayjs(absence.endDate);
                  
                  // Use isBetween instead of isSameOrAfter/isSameOrBefore
                  return currentDate.isBetween(absenceStart, absenceEnd, 'day', '[]');
                });

                // Get shifts for this date, excluding absent people
                const absentContactIds = dateAbsences.map(a => parseInt(a.contact_id));
                const dateShifts = filteredShifts
                  .filter(s => s.date === dateString)
                  .filter(s => !absentContactIds.includes(parseInt(s.contact_id)));

                console.log('Date absences:', {
                  date: dateString,
                  absences: dateAbsences.length,
                  absentContactIds
                });

                return (
                  <Grid item xs key={dateString}>
                    <WeekDayHeader variant="subtitle1">
                      {['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'][date.day()]}
                      <br />
                      {date.format('DD/MM')}
                    </WeekDayHeader>
                    
                    {/* Regular shifts (excluding absent people) */}
                    {dateShifts.map(shift => (
                      <Card 
                        key={`shift-${shift.id}`}
                        sx={{ 
                          mb: 0.5,
                          backgroundColor: shift.team_color || '#f5f5f5',
                          '&:hover': {
                            backgroundColor: shift.team_color || '#e0e0e0',
                          },
                          '& .MuiCardContent-root': {
                            padding: '4px 8px',
                            '&:last-child': {
                              paddingBottom: '4px'
                            }
                          }
                        }}
                      >
                        <CardContent>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box>
                              <Typography variant="body2" sx={{ 
                                fontWeight: 500,
                                fontSize: '0.875rem'
                              }}>
                                {shift.contact_name}
                              </Typography>
                              {shift.team_name && (
                                <Typography variant="caption" color="textSecondary" sx={{ 
                                  fontSize: '0.75rem'
                                }}>
                                  {shift.team_name}
                                </Typography>
                              )}
                            </Box>
                            <Typography variant="caption" sx={{ fontSize: '0.75rem' }}>
                              {formatTime(shift.startTime)} - {formatTime(shift.endTime)}
                            </Typography>
                          </Box>
                        </CardContent>
                      </Card>
                    ))}

                    {/* Divider if there are both shifts and absences */}
                    {dateShifts.length > 0 && dateAbsences.length > 0 && (
                      <Box sx={{ my: 2, borderTop: '1px dashed #ccc' }} />
                    )}

                    {/* Absences section */}
                    {dateAbsences.length > 0 && (
                      <Box sx={{ mt: 1 }}>
                        {dateAbsences.map(absence => (
                          <Card 
                            key={`absence-${absence.id}`}
                            sx={{ 
                              mb: 0.5,
                              backgroundColor: getAbsenceColor(absence.type),
                              opacity: 0.9,
                              '&:hover': {
                                opacity: 1
                              },
                              '& .MuiCardContent-root': {
                                padding: '4px 8px',
                                '&:last-child': {
                                  paddingBottom: '4px'
                                }
                              }
                            }}
                          >
                            <CardContent>
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Box>
                                  <Typography variant="body2" sx={{ 
                                    fontWeight: 500,
                                    fontSize: '0.875rem'
                                  }}>
                                    {absence.contact_name || 'Unknown'}
                                  </Typography>
                                  <Typography variant="caption" color="error">
                                    {getVacationTypeDisplay(absence.type)}
                                  </Typography>
                                </Box>
                              </Box>
                            </CardContent>
                          </Card>
                        ))}
                      </Box>
                    )}
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        ))}
      </>
    );
  };

  // Add these handler functions inside the ShiftPlanningComponent before the return statement

  
  



  // Add to ShiftPlanningComponent state
  const [openVacationDialog, setOpenVacationDialog] = useState(false);

  // Update the handleVacationSubmit function
  const handleVacationSubmit = async (formData) => {
    try {
      const formattedData = {
        action: 'create',
        contact_id: parseInt(formData.employeeId),
        startDate: dayjs(formData.startDate).format('YYYY-MM-DD'),
        endDate: dayjs(formData.endDate).format('YYYY-MM-DD'),
        type: formData.type,
        comment: formData.comment || '',
        status: 'Pending'
      };

      console.log('Sending vacation request:', formattedData);
      
      if (!formattedData.contact_id || !formattedData.startDate || !formattedData.endDate) {
        throw new Error('Alle påkrævede felter skal udfyldes');
      }

      const response = await api.post('/vacations.php', formattedData);
      
      if (response.data?.success) {
        setSuccessMessage('Ferieanmodning sendt til godkendelse');
        setOpenVacationDialog(false);
        // Refresh absences
        const startDate = dayjs().year(selectedYear).week(selectedWeek).startOf('week').format('YYYY-MM-DD');
        const endDate = dayjs().year(selectedYear).week(selectedWeek).endOf('week').format('YYYY-MM-DD');
        await fetchAbsences(startDate, endDate);
      }
    } catch (error) {
      console.error('Error submitting vacation request:', error);
      const errorMessage = error.response?.data?.error || error.message || 'Der opstod en fejl ved oprettelse af ferieanmodning';
      console.error('Server error:', errorMessage);
      setErrorMessage(errorMessage);
    }
  };

  // Update the fetchVacationRequests function
  const fetchVacationRequests = async () => {
    try {
      console.log('Fetching vacation requests...');
      const response = await api.get('/vacations.php');
      console.log('Vacation response:', response);
      
      if (response.data?.success) {
        const approvedVacations = response.data.data.filter(v => v.status === 'Approved');
        // Convert approved vacations to absences format
        const vacationAbsences = approvedVacations.map(vacation => ({
          contact_id: vacation.contact_id,
          startDate: vacation.startDate,
          endDate: vacation.endDate,
          type: vacation.type,
          id: `vacation-${vacation.id}`
        }));
        return vacationAbsences;
      }
      return [];
    } catch (error) {
      console.error('Error fetching vacation requests:', error);
      console.error('Error details:', error.response?.data);
      return [];
    }
  };

  // Filter absences based on department
  const filteredAbsences = useMemo(() => {
    return absences.filter(absence => {
      const contact = contacts.find(c => c.id === parseInt(absence.contact_id));
      return selectedDepartment === 'all' || contact?.department === selectedDepartment;
    });
  }, [absences, contacts, selectedDepartment]);

  // Update fetchAbsences function
  const fetchAbsences = async () => {
    try {
      // First ensure we have contacts
      let currentContacts = contacts;
      if (contacts.length === 0) {
        const contactsResponse = await api.get('/contacts.php');
        if (contactsResponse.data?.success) {
          currentContacts = contactsResponse.data.data.filter(
            contact => contact.can_be_scheduled === 1
          );
          setContacts(currentContacts);
        }
      }

      console.log('Current contacts for absences:', currentContacts);

      const startWeek = isRangeMode ? selectedStartWeek : selectedWeek;
      const endWeek = isRangeMode ? selectedEndWeek : selectedWeek;
      
      const startDate = dayjs().year(selectedYear).week(startWeek).startOf('week');
      const endDate = dayjs().year(selectedYear).week(endWeek).endOf('week');

      const [absencesResponse, vacationAbsences] = await Promise.all([
        api.get('/absences.php', {
          params: {
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD')
          }
        }),
        fetchVacationRequests()
      ]);

      if (absencesResponse.data?.success) {
        console.log('Raw absences response:', absencesResponse.data);
        const regularAbsences = absencesResponse.data.data.map(absence => {
          console.log('Processing absence:', absence);
          return {
            ...absence,
            contact_name: absence.contact_name || 'Unknown',
            startDate: dayjs(absence.startDate).format('YYYY-MM-DD'),
            endDate: dayjs(absence.endDate).format('YYYY-MM-DD')
          };
        });

        // Process vacation absences
        const processedVacationAbsences = vacationAbsences.map(absence => {
          const contact = currentContacts.find(c => c.id === parseInt(absence.contact_id));
          console.log('Processing vacation:', { 
            absence_id: absence.contact_id, 
            contact_found: contact ? contact.name : 'not found' 
          });
          return {
            ...absence,
            contact_name: contact?.name,
            startDate: dayjs(absence.startDate).format('YYYY-MM-DD'),
            endDate: dayjs(absence.endDate).format('YYYY-MM-DD')
          };
        });

        const combinedAbsences = [...regularAbsences, ...processedVacationAbsences];
        console.log('Combined absences with names:', combinedAbsences);
        setAbsences(combinedAbsences);
      }
    } catch (error) {
      console.error('Error fetching absences:', error);
    }
  };

  // Helper function to fetch current contacts
  const fetchCurrentContacts = async () => {
    const response = await api.get('/contacts.php');
    if (response.data?.success) {
      const schedulableContacts = response.data.data.filter(
        contact => contact.can_be_scheduled === 1
      );
      setContacts(schedulableContacts);
      return schedulableContacts;
    }
    return [];
  };

  // Add useEffect to refetch absences when department changes
  useEffect(() => {
    fetchAbsences();
  }, [selectedDepartment]);

  // Update the handlers for week changes
  const handleWeekChange = (e) => {
    const value = parseInt(e.target.value);
    if (value >= 1 && value <= 53) {
      if (isRangeMode) {
        setSelectedStartWeek(value);
        if (value > selectedEndWeek) {
          setSelectedEndWeek(value);
        }
      } else {
        setSelectedWeek(value);
      }
    }
  };

  const handleEndWeekChange = (e) => {
    const value = parseInt(e.target.value);
    if (value >= parseInt(selectedStartWeek) && value <= 53) {
      setSelectedEndWeek(value);
    }
  };

  // Common button style object to use across all components
  const commonButtonStyle = {
    textTransform: 'none',
    fontWeight: 500,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none'
    }
  };

  // Update the dialog opening handler
  const handleOpenVacationDialog = async () => {
    if (contacts.length === 0) {
      await fetchUsers();
    }
    setOpenVacationDialog(true);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">Vagtplan</Typography>
        <Button
          variant="contained"
          onClick={handleOpenVacationDialog}
          sx={commonButtonStyle}
        >
          Anmod om ferie
        </Button>
      </Box>

      <VacationDialog
        open={openVacationDialog}
        onClose={() => setOpenVacationDialog(false)}
        onSubmit={handleVacationSubmit}
      />

      {/* Week selection controls */}
      <Box display="flex" alignItems="center" gap={2} mb={3}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isRangeMode}
              onChange={(e) => {
                setIsRangeMode(e.target.checked);
                if (e.target.checked) {
                  setSelectedStartWeek(selectedWeek);
                  setSelectedEndWeek(selectedWeek);
                }
              }}
            />
          }
          label="Vis flere uger"
        />

        {isRangeMode ? (
          <>
            <TextField
              label="Start uge"
              type="number"
              value={selectedStartWeek}
              onChange={(e) => setSelectedStartWeek(parseInt(e.target.value))}
              sx={{ width: 100 }}
              inputProps={{ min: 1, max: 53 }}
            />
            <TextField
              label="Slut uge"
              type="number"
              value={selectedEndWeek}
              onChange={handleEndWeekChange}
              sx={{ width: 100 }}
              inputProps={{ min: selectedStartWeek, max: 53 }}
            />
          </>
        ) : (
          <TextField
            label="Uge"
            type="number"
            value={selectedWeek}
            onChange={handleWeekChange}
            sx={{ width: 100 }}
            inputProps={{ min: 1, max: 53 }}
          />
        )}

        <TextField
          select
          label="År"
          value={selectedYear}
          onChange={(e) => setSelectedYear(e.target.value)}
          sx={{ width: 100 }}
        >
          {availableYears.map((year) => (
            <MenuItem key={year} value={year}>{year}</MenuItem>
          ))}
        </TextField>
      </Box>

      <Paper 
        elevation={0} 
        sx={{ 
          mb: 2,
          borderRadius: 1,
          backgroundColor: 'background.paper'
        }}
      >
        <StyledTabs
          value={selectedDepartment}
          onChange={(e, newValue) => setSelectedDepartment(newValue)}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          <StyledTab 
            label="Alle afdelinger" 
            value="all"
          />
          {departments
            .filter(dept => dept.isVisibleInShiftPlanning === 1)  // Only show departments visible in shift planning
            .map((dept) => (
              <StyledTab 
                key={dept.id}
                label={dept.name}
                value={dept.name}
              />
            ))}
        </StyledTabs>
      </Paper>

      {/* Main content */}
      {renderShiftPlanning()}

      {/* Snackbars */}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage(null)}
      >
        <Alert onClose={() => setSuccessMessage(null)} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage(null)}
      >
        <Alert onClose={() => setErrorMessage(null)} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ShiftPlanningComponent;
