import React, { useState, useEffect, useMemo } from 'react';
import {
  Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Box, CircularProgress, IconButton, Tooltip
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import api from '../../utils/apiUtils';
import dayjs from 'dayjs';

const initialPalletTypes = [
  { id: 1, name: 'EUR-palle' },
  { id: 2, name: 'Halvpalle' },
];

const PalletAccountingManagement = () => {
  const [palletTypes, setPalletTypes] = useState(initialPalletTypes);
  const [palletEntries, setPalletEntries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [entryToDelete, setEntryToDelete] = useState(null);
  const [totals, setTotals] = useState({});
  const [companyBalances, setCompanyBalances] = useState({ 'DAO/BK': 0, 'FK': 0 });

  useEffect(() => {
    fetchPalletEntries();
  }, []);

  const fetchPalletEntries = async () => {
    setLoading(true);
    try {
      const response = await api.get('/pallets.php');
      console.log('Pallet response:', response.data);
      if (response.data?.success) {
        const entries = response.data.data.entries || [];
        console.log('Setting pallet entries:', entries);
        setPalletEntries(entries);
        
        setPalletTypes(response.data.data.types || initialPalletTypes);
        setTotals(response.data.data.totals || {});
        setCompanyBalances(response.data.data.companyBalances || { 'DAO/BK': 0, 'FK': 0 });
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Error fetching pallet entries:', error);
      setError('Der opstod en fejl ved hentning af palledata. Prøv venligst igen senere.');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteEntry = async () => {
    try {
      if (!entryToDelete?.id) {
        console.log('Entry to delete:', entryToDelete);
        setError('Kunne ikke finde ID på den valgte post');
        return;
      }

      console.log('Attempting to delete entry with ID:', entryToDelete.id);

      const response = await api.delete(`/pallets.php/${entryToDelete.id}`);
      console.log('Delete response:', response);

      if (response.data?.success) {
        await fetchPalletEntries();
        setDeleteDialogOpen(false);
        setEntryToDelete(null);
      } else {
        throw new Error(response.data?.error || 'Kunne ikke slette posten');
      }
    } catch (error) {
      console.error('Error deleting pallet entry:', error);
      setError('Der opstod en fejl ved sletning af palledata. Prøv venligst igen.');
    }
  };

  const openDeleteDialog = (entry) => {
    console.log('Opening delete dialog with entry:', entry);
    if (!entry?.id) {
      setError('Kunne ikke finde ID på den valgte post');
      return;
    }
    setEntryToDelete(entry);
    setDeleteDialogOpen(true);
  };

  const calculateBalance = (palletTypeId) => {
    if (!Array.isArray(palletEntries)) return 0;
    
    return palletEntries
        .filter(entry => entry && entry.palletTypeId === palletTypeId)
        .reduce((balance, entry) => 
            balance + (parseInt(entry.incoming) || 0) - (parseInt(entry.outgoing) || 0), 0);
  };

  const calculateOwedPallets = useMemo(() => {
    const owedPallets = {
      'DAO/BK': 0,
      'FK': 0
    };

    if (!Array.isArray(palletEntries)) {
      console.log('palletEntries is not an array:', palletEntries);
      return owedPallets;
    }

    palletEntries.forEach(entry => {
      if (entry && (entry.company === 'DAO/BK' || entry.company === 'FK')) {
        owedPallets[entry.company] -= (parseInt(entry.incoming) || 0) - (parseInt(entry.outgoing) || 0);
      }
    });

    console.log('Calculated owed pallets:', owedPallets);
    return owedPallets;
  }, [palletEntries]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h5" gutterBottom>
        Palleregnskab Administration
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Palletype</TableCell>
              <TableCell align="right">Indgående (Modtaget)</TableCell>
              <TableCell align="right">Udgående (Sendt)</TableCell>
              <TableCell align="right">Balance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {palletTypes.map((palletType) => (
              <TableRow key={palletType.id}>
                <TableCell component="th" scope="row">
                  {palletType.name}
                </TableCell>
                <TableCell align="right">
                  {palletEntries.filter(entry => entry.palletTypeId === palletType.id)
                    .reduce((sum, entry) => sum + entry.incoming, 0)}
                </TableCell>
                <TableCell align="right">
                  {palletEntries.filter(entry => entry.palletTypeId === palletType.id)
                    .reduce((sum, entry) => sum + entry.outgoing, 0)}
                </TableCell>
                <TableCell align="right">
                  {calculateBalance(palletType.id)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
        Pallesaldo med DAO/BK og FK
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Firma</TableCell>
              <TableCell align="right">EUR-paller Saldo</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(calculateOwedPallets).map(([company, balance]) => (
              <TableRow key={company}>
                <TableCell>{company}</TableCell>
                <TableCell align="right">{balance}</TableCell>
                <TableCell>
                  {balance > 0 ? (
                    <Typography color="success.main">{company} skylder os {balance} paller</Typography>
                  ) : balance < 0 ? (
                    <Typography color="error.main">Vi skylder {company} {Math.abs(balance)} paller</Typography>
                  ) : (
                    <Typography color="info.main">Ingen udestående</Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
        Seneste indtastninger
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Dato</TableCell>
              <TableCell>Palletype</TableCell>
              <TableCell align="right">Indgående (Modtaget)</TableCell>
              <TableCell align="right">Udgående (Sendt)</TableCell>
              <TableCell>Firma</TableCell>
              <TableCell>Note</TableCell>
              <TableCell>Handlinger</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {palletEntries.sort((a, b) => new Date(b.date) - new Date(a.date)).map((entry) => (
              <TableRow key={entry.id}>
                <TableCell>{entry.date}</TableCell>
                <TableCell>{palletTypes.find(pt => pt.id === entry.palletTypeId)?.name}</TableCell>
                <TableCell align="right">{entry.incoming}</TableCell>
                <TableCell align="right">{entry.outgoing}</TableCell>
                <TableCell>{entry.company}</TableCell>
                <TableCell>{entry.note}</TableCell>
                <TableCell>
                  <IconButton 
                    onClick={() => openDeleteDialog(entry)}
                    disabled={!entry?.id}
                  >
                    <Tooltip title={entry?.id ? "Slet indtastning" : "Kan ikke slette - mangler ID"}>
                      <DeleteIcon />
                    </Tooltip>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Bekræft sletning</DialogTitle>
        <DialogContent>
          <Typography>Er du sikker på, at du vil slette denne indtastning?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Annuller</Button>
          <Button onClick={handleDeleteEntry} color="error">Slet</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default PalletAccountingManagement;
