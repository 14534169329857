import React, { useState, useEffect } from 'react';
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import axios from 'axios';
import config from '../../config';

const SummerVacationPlanning = () => {
  const [users, setUsers] = useState([]);
  const [vacationRequests, setVacationRequests] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUsers();
    fetchVacationRequests();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/users`, { withCredentials: true });
      if (response.data && response.data.success && Array.isArray(response.data.data)) {
        setUsers(response.data.data);
      } else {
        console.error('Invalid users data:', response.data);
        setUsers([]);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      setUsers([]);
    }
  };

  const fetchVacationRequests = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/vacations`, { withCredentials: true });
      if (response.data && response.data.success && Array.isArray(response.data.data)) {
        setVacationRequests(response.data.data);
      } else {
        setVacationRequests([]);
      }
    } catch (error) {
      console.error('Error fetching vacation requests:', error);
      setVacationRequests([]);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (!selectedUser || !startDate || !endDate) {
      alert('Please fill in all fields');
      return;
    }

    try {
      await axios.post(`${config.apiUrl}/vacations`, {
        userId: selectedUser,
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
        type: 'summer'
      }, { withCredentials: true });

      fetchVacationRequests();
      handleClose();
    } catch (error) {
      console.error('Error submitting vacation request:', error);
      alert('Error submitting vacation request');
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
    setSelectedUser('');
    setStartDate(null);
    setEndDate(null);
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Paper sx={{ p: 2 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">Sommerferie Planlægning</Typography>
        <Button variant="contained" onClick={() => setOpenDialog(true)}>
          Tilføj Ferie
        </Button>
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Medarbejder</TableCell>
              <TableCell>Start Dato</TableCell>
              <TableCell>Slut Dato</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vacationRequests.map((request) => (
              <TableRow key={request.id}>
                <TableCell>
                  {users.find(user => user.id === request.userId)?.name || 'Unknown'}
                </TableCell>
                <TableCell>{dayjs(request.startDate).format('DD/MM/YYYY')}</TableCell>
                <TableCell>{dayjs(request.endDate).format('DD/MM/YYYY')}</TableCell>
                <TableCell>{request.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={handleClose}>
        <DialogTitle>Tilføj Sommerferie</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel>Medarbejder</InputLabel>
            <Select
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.target.value)}
              label="Medarbejder"
            >
              {Array.isArray(users) && users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Dato"
              value={startDate}
              onChange={setStartDate}
              renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
            />
            <DatePicker
              label="Slut Dato"
              value={endDate}
              onChange={setEndDate}
              renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuller</Button>
          <Button onClick={handleSubmit} variant="contained">
            Gem
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default SummerVacationPlanning;
